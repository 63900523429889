/* eslint-disable jsx-a11y/anchor-is-valid */
import {useFormik} from 'formik'
import React, {useEffect, useRef, useState} from 'react'

import Select from 'react-select'
import _, {values} from 'lodash'

import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {locations, sdgs, sectors} from '../../../shared/Constants'
import {parseJsonString} from '../../../shared/Helpers'
import {getCompanies} from '../../../../../api'
import {Link, useParams} from 'react-router-dom'
const CompaniesList = () => {
  const [companies, setCompanies] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [isFiltering, setFiltering] = useState(false)
  const [filteredCompanies, setFilteredCompanies] = useState([])

  const [selectedSdg, setSelectedSdg] = useState<any>([])
  const [selectedLocation, setSelectedLocation] = useState<any>([])
  const [selectedSector, setSelectedSector] = useState<any>([])

  const {group_identifier} = useParams<any>()
  const prefix = `/company/${group_identifier}`

  useEffect(() => {
    getCompanies()
      .then((response) => {
        const {data = []} = response.data || {}
        setCompanies(data)
        setFilteredCompanies(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const initialValues: any = {txtSearch: ''}

  const formik = useFormik({
    initialValues,
    onSubmit: (values: any) => {},
  })

  const searchCompanies = (values: any) => {
    var list = filteredCompanies.filter(
      (company: any) =>
        company.company_name.toLowerCase().includes(values.txtSearch.toLowerCase()) ||
        company.company_sector.value.toLowerCase().includes(values.txtSearch.toLowerCase()) ||
        company.company_location.value.toLowerCase().includes(values.txtSearch.toLowerCase()) ||
        company.company_email.toLowerCase().includes(values.txtSearch.toLowerCase())
    )

    return list
  }

  const handleSdgChange = (value: any) => {
    setSelectedSdg(value)
  }

  const handleLocationChange = (value: any) => {
    setSelectedLocation(value)
  }

  const handleSectorChange = (value: any) => {
    setSelectedSector(value)
  }

  const filterCompanies = () => {
    setFiltering(true)

    let filteredList: any = [...companies]

    if (selectedSdg.length > 0) {
      filteredList = filteredList.filter((company: any) => {
        const needle = selectedSdg.map((item: any) => item.value)
        const stack = company.sdgs.map((item: any) => `${item.sdg.id}`)
        return stack.some((i: any) => needle.includes(i))
      })
    }

    if (selectedLocation.length > 0) {
      filteredList = filteredList.filter((company: any) => {
        const needle = selectedLocation.map((item: any) => item.value)
        const stack = [`${company.company_location.id}`]
        return stack.some((i: any) => needle.includes(i))
      })
    }

    if (selectedSector.length > 0) {
      filteredList = filteredList.filter((company: any) => {
        const needle = selectedSector.map((item: any) => item.value)
        const stack = [`${company.company_sector.id}`]
        return stack.some((i: any) => needle.includes(i))
      })
    }

    setTimeout(() => {
      setFiltering(false)
      setFilteredCompanies(filteredList)
    }, 700)
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate id='search_form' className='form'>
      {showFilter && (
        <div className='card card-custom mb-15'>
          <div className='card-header'>
            <h3 className='card-title'>Filter</h3>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-light'
                onClick={() => {
                  setShowFilter(false)
                }}
              >
                Close
              </button>
            </div>
          </div>
          <div className='card-body px-10 py-0 '>
            <div className='row p-10'>
              <div className='col-8'>
                <div className='mb-5'>
                  <strong>Development Goals</strong>
                  <Select isMulti options={sdgs} className='mt-2' onChange={handleSdgChange} />
                </div>

                <div className='mb-5'>
                  <strong>Sector</strong>
                  <Select
                    isMulti
                    options={sectors}
                    className='mt-2'
                    onChange={handleSectorChange}
                  />
                </div>

                <div className='mb-5'>
                  <strong>Location</strong>
                  <Select
                    isMulti
                    options={locations}
                    className='mt-2'
                    onChange={handleLocationChange}
                  />
                </div>
                <button className='btn btn-primary' onClick={filterCompanies}>
                  {!isFiltering && 'Filter'}
                  {isFiltering && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Filtering...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='card'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div
            className='d-flex h-40px align-items-center'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <input
              {...formik.getFieldProps('txtSearch')}
              type='text'
              className='me-5 form-control form-control-solid'
              placeholder='Search here'
            />
            <a
              href='#'
              className='h-100 p-3 align-items-center w-150px btn btn-sm btn-light btn-active-primary fw-bolder'
              onClick={() => {
                setShowFilter(true)
              }}
            >
              <KTSVG
                path='/media/icons/duotone/Text/Filter.svg'
                className='svg-icon-5 svg-icon-gray-500 '
              />
              Filter
            </a>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-150px'>Company Name</th>
                  <th className='min-w-140px'>Sector</th>
                  <th className='min-w-120px'>Location</th>
                  <th className='min-w-100px'>Development Goals</th>
                  <th className='min-w-100px'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {searchCompanies(formik.values).map((company: any) => (
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={`/company/${company.group_identifier}`}
                            className='text-dark fw-bolder text-hover-primary fs-6 mb-1'
                          >
                            {company.company_name}
                          </Link>
                          <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {company.company_email}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {company.company_sector.value}
                      </span>
                    </td>
                    <td className='text-start'>
                      <span className='text-muted fw-bold d-block fs-7'>
                        {company.company_location.value}, Egypt
                      </span>
                    </td>
                    <td>
                      {(parseJsonString(company.sdgs) || []).map((sdg: any) => (
                        <>
                          <img
                            alt='Logo'
                            src={toAbsoluteUrl(`/media/images/${sdg}.png`)}
                            className='h-40px w-auto me-1'
                          />
                        </>
                      ))}
                    </td>
                    <td>
                      <Link to={`/company/${company.group_identifier}`} className='fs-8 fw-bold'>
                        View Details
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </form>
  )
}

export default CompaniesList
