import clsx from 'clsx'
import {useContext} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
// import {PageTitle} from '../../../../../_metronic/layout/core'
import {AuthContext} from '../../../../context/AuthContext'
import {CompanyContext} from '../../../../context/CompanyContext'
import {DashboardContext} from '../../../../context/DashboardContext'
import {sdgs} from '../../../shared/Constants'
import {maybePluralize} from '../../../shared/Helpers'

export const CompanyImpactFocus = () => {
  const {impactList = []} = useContext(DashboardContext)

  const location = useLocation<any>()

  // const {company = {}} = location.state || {}
  const {company = {}} = useContext(CompanyContext)

  const {user, isAdmin} = useContext(AuthContext)
  const {id, group_identifier, portfolio_id} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  return (
    <>
      <div className='card card-custom p-0 pt-2'>
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-200 align-middle gs-10 mb-0 pb-0'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Name</th>
                <th className='min-w-100px'>Development Goals</th>
                <th className='min-w-150px'>Completed Info</th>
                <th className='min-w-150px '>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {impactList.map((impact: any) => (
                <tr key={impact.group_identifier}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <Link
                          to={{
                            pathname: `/company/${group_identifier}/impact-focus/${impact.group_identifier}`,
                            state: {impact},
                          }}
                          className='text-dark fw-bolder text-hover-primary fs-6 mb-1'
                        >
                          {impact.name}
                        </Link>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {maybePluralize(impact?.goals?.length, 'Goal')},{' '}
                          {maybePluralize(impact?.metrics?.length, 'Metric')}
                        </span>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2'>
                      {impact.sdgs.map((sdg: any, index: any) => (
                        <Link
                          to={{
                            pathname: `${prefix}/dashboard/sdg/${sdg}`,
                          }}
                          className='symbol symbol-circle symbol-20px'
                          key={`sgds-${impact.group_identifier}-${index}`}
                        >
                          <img
                            alt='Logo'
                            src={toAbsoluteUrl(`/media/images/sdgs/${sdg}.png`)}
                            className='h-40px w-auto'
                            title={`${sdgs.find((s: any) => s.value == sdg)?.label}`}
                          />
                        </Link>
                      ))}
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-bold'>{`${impact.completed}%`}</span>
                    </div>
                    <div className='progress h-6px w-100 '>
                      <div
                        className={clsx('progress-bar', {
                          'bg-success': impact.completed >= 100,
                          'bg-danger': impact.completed < 50,
                          'bg-warning': impact.completed >= 50 && impact.completed < 100,
                        })}
                        role='progressbar'
                        style={{width: `${impact.completed}%`}}
                      ></div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex justify-content-start flex-shrink-0'>
                      <Link
                        to={{
                          pathname: `/company/${group_identifier}/impact-focus/${impact.group_identifier}`,
                          state: {impact},
                        }}
                        className='text-color-primary'
                      >
                        View Details
                        {/* <KTSVG
                        path='/media/icons/duotone/General/Settings-1.svg'
                        className='svg-icon-3'
                      /> */}
                      </Link>
                      {/* <Link
                      to={{pathname: `${impact.link}/edit`, state: {impact}}}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotone/Communication/Write.svg'
                        className='svg-icon-3'
                      />
                    </Link> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>
    </>
  )
}
