import {createContext, useState} from 'react'

export const AuthContext = createContext<any>({})

export const AuthProvider = ({children}: any) => {
  const userString = localStorage.getItem('user')
  const access_token = localStorage.getItem('access_token')
  const user = JSON.parse(userString as string)
  const instances = localStorage.getItem('instances')
  

  const isAdmin = user.type !== 'Investor' && user.type !== 'Investee'
  const isInvestor = user.type === 'Investor'
  const isInvestee = user.type === 'Investee'

  return (
    <AuthContext.Provider value={{user, access_token, instances, isAdmin, isInvestor, isInvestee}}>
      {children}
    </AuthContext.Provider>
  )
}
