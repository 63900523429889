import {useState} from 'react'

const ReadMoreDiv = ({children, height = 100}: any) => {
  const [showFullText, setShowFullText] = useState(false)
  return (
    <>
      <div style={{height: showFullText ? 'auto' : height, overflow: 'hidden'}}>{children}</div>
      <button
        type='button'
        onClick={() => setShowFullText(!showFullText)}
        className='btn btn-link fw-bold fs-8  p-0 text-white'
      >
        {showFullText ? '<Less' : '>More'}
      </button>
    </>
  )
}

export default ReadMoreDiv
