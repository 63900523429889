import React, {useState, useEffect} from 'react'
import {useParams, Redirect} from 'react-router-dom'
import {activateUser} from '../../../../api'
import LoadingSpinner from '../../shared/LoadingSpinner'

const AccountActivation: React.FC = () => {
  const [doRedirect, setRedirect] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const {token} = useParams<any>()

  const alert = 'Account has been activated successfully. Please proceed to login.'

  useEffect(() => {
    activateUser(token).finally(() => {
      setLoading(false)
      setRedirect(true)
    })
  }, [])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {doRedirect && <Redirect to={{pathname: '/auth/login', state: {alert}}} />}
    </>
  )
}

export default AccountActivation
