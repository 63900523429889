import clsx from 'clsx'
import {useContext, useEffect, useState} from 'react'
import {Link, useParams, useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
import PieChart from '../../shared/PieChart'
import {sdgs} from '../../shared/Constants'
import CurrentMetric from '../../shared/CurrentMetric'
import {getMetricPercentageCompleted, getRiskColor, maybePluralize} from '../../shared/Helpers'

const LocationFundingDetails = () => {
  const {category} = useParams<any>() || {}

  const {impactList} = useContext(DashboardContext)
  const {company = {}} = useContext(CompanyContext)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  const dataMap = impactList.reduce((acc: any, impact: any) => {
    if (impact.location.includes(category)) {
      acc[impact.name] = {
        impact,
        value: (acc[impact.name]?.value || 0) + impact.funding,
      }
    }

    return acc
  }, {})

  const data = Object.keys(dataMap)
    .reduce(
      (acc: any, type: any) => [
        ...acc,
        {
          ...dataMap[type].impact,
          category: type,
          value: dataMap[type].value,
          link: `${prefix}/impact-focus/${dataMap[type].impact.group_identifier}`,
        },
      ],
      []
    )
    .filter(({value}: any) => value)

  return (
    <>
      <PageTitle>{`Location - ${category}`}</PageTitle>

      <div className='card card-custom p-10'>
        <h2>Location by Impact Focus</h2>
        <p className='fw-bold text-gray-600'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className='d-flex  bg-white'>
          <PieChart data={data} />
        </div>
      </div>

      <div className=' card card-custom p-10 '>
        <h2>Location ({`${category}`})</h2>
        <p className='fw-bold text-gray-600'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>

        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-200 align-middle gs-10 mb-0 pb-0'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Name</th>
                <th className='min-w-150px'>Funding</th>
                <th className='min-w-100px'>Development Goals</th>
                <th className='min-w-100px '>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data.map((impact: any) => (
                <tr key={impact.group_identifier}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <Link
                          to={{
                            pathname: `${prefix}/impact-focus/${impact.group_identifier}`,
                            state: {impact},
                          }}
                          className='text-dark fw-bolder text-hover-primary fs-6 mb-1'
                        >
                          {impact.name}
                        </Link>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {maybePluralize(impact?.goals?.length, 'Goal')},{' '}
                          {maybePluralize(impact?.metrics?.length, 'Metric')}
                        </span>
                      </div>
                    </div>
                  </td>

                  <td className='fw-bold text-gray-600 '>{impact.funding}</td>

                  <td>
                    <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2'>
                      {impact?.sdgs?.map((sdg: any, index: any) => (
                        <Link
                          to={{
                            pathname: `${prefix}/dashboard/sdg/${sdg}`,
                          }}
                          className='symbol symbol-circle symbol-20px'
                          key={`sgds-${impact.group_identifier}-${index}`}
                        >
                          <img
                            alt='Logo'
                            src={toAbsoluteUrl(`/media/images/sdgs/${sdg}.png`)}
                            className='h-40px w-auto'
                            title={`${sdgs.find((s: any) => s.value == sdg)?.label}`}
                          />
                        </Link>
                      ))}
                    </div>
                  </td>

                  <td>
                    <div className='d-flex justify-content-start flex-shrink-0'>
                      <Link
                        to={{
                          pathname: `${prefix}/impact-focus/${impact.group_identifier}`,
                          state: {impact},
                        }}
                        className='text-color-primary'
                      >
                        View Details
                        {/* <KTSVG
                        path='/media/icons/duotone/General/Settings-1.svg'
                        className='svg-icon-3'
                      /> */}
                      </Link>
                      {/* <Link
                      to={{pathname: `${impact.link}/edit`, state: {impact}}}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotone/Communication/Write.svg'
                        className='svg-icon-3'
                      />
                    </Link> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>
    </>
  )
}

export default LocationFundingDetails
