import {useEffect, useRef} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import _ from 'lodash'
import {useHistory} from 'react-router'
import {alphaHex} from './Helpers'

const PieChart = ({
  data = [],
  useDataColor = false,
  useAlpha = false,
  height = 400,
  className,
  labelTextType = 'aligned',
  labelFontSize,
}: any) => {
  const history = useHistory()

  const CHART_ID = _.uniqueId('chart-')

  const chartRef = useRef<any>(null)

  useEffect(() => {
    /* Chart code */
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new(CHART_ID)

    let defaultTheme = am5.Theme.new(root)

    if (useDataColor) {
      defaultTheme.rule('ColorSet').set(
        'colors',
        data.map((item: any, index: any) => {
          const alphaValue = alphaHex()[100 - Math.floor(index * (100 / (data.length + 2)))]
          return am5.Color.fromString(`${item.color}`)
        })
      )
    }

    if (!useDataColor) {
      defaultTheme
        .rule('ColorSet')
        .set('colors', [
          am5.color('#7C3697'),
          am5.color('#F15523'),
          am5.color('#3D5EAC'),
          am5.color('#62B146'),
          am5.color('#D0DD37'),
          am5.color('#F9BD19'),
          am5.color('#F99B1E'),
          am5.color('#1496CE'),
          am5.color('#EF3224'),
          am5.color('#F3EC3A'),
          am5.color('#A71E48'),

          am5.color('#463191'),

          am5.color('#e6194b'),
          am5.color('#3cb44b'),
          am5.color('#ffe119'),
          am5.color('#4363d8'),
          am5.color('#f58231'),
          am5.color('#911eb4'),
          am5.color('#46f0f0'),
          am5.color('#f032e6'),
          am5.color('#bcf60c'),
          am5.color('#fabebe'),
          am5.color('#008080'),
          am5.color('#e6beff'),
          am5.color('#9a6324'),
          am5.color('#fffac8'),
          am5.color('#800000'),
          am5.color('#aaffc3'),
          am5.color('#808000'),
          am5.color('#ffd8b1'),
          am5.color('#000075'),
          am5.color('#808080'),
          am5.color('#ffffff'),
          am5.color('#000000'),
        ])
    }

    // myTheme.rule('Label').set('fontSize', '0.8em')
    // myTheme.rule('Label').set('ellipsis', '...')

    // myTheme.rule('Label').set('oversizedBehavior', 'truncate')
    // myTheme.rule('Label').set('rotation', 10)

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root), defaultTheme])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: am5.percent(50),
      })
    )

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        alignLabels: false,
      })
    )

    series.labels.template.setAll({
      textType: labelTextType,
      oversizedBehavior: 'truncate',
      fontSize: labelFontSize,
      cursorOverStyle: 'pointer',
    })

    series.slices.template.setAll({
      cursorOverStyle: 'pointer',
    })

    series.slices.template.events.on('click', function (ev: any) {
      const {dataContext = {}} = ev.target.dataItem

      const {link} = data.find(
        (d: any) => d.category === dataContext.category && d.value === dataContext.value
      )

      if (link) history.push(link)
    })

    series.labels.template.events.on('click', function (ev: any) {
      const {dataContext = {}} = ev.target.dataItem

      const {link} = data.find(
        (d: any) => d.category === dataContext.category && d.value === dataContext.value
      )

      if (link) history.push(link)
    })

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(data)

    // var legend = chart.children.push(
    //   am5.Legend.new(root, {
    //     centerX: am5.percent(50),
    //     x: am5.percent(50),
    //     marginTop: 15,
    //     marginBottom: 15,
    //   })
    // )

    // legend.data.setAll(series.dataItems)

    // if (useDataColor)
    // series.colors.list = data.map((item: any, index: any) => {
    //       const alphaValue = alphaHex()[100 - Math.floor(index * (100 / (data.length + 2)))]
    //       return am4core.color(`${item.color}${useAlpha ? alphaValue : ''}`)
    //     })

    series.appear(1000, 100)
  }, [])

  // useEffect(() => {
  //   if (chartRef.current) {
  //     chartRef.current.data = data
  //   }
  // }, [data])

  return (
    <div
      id={CHART_ID}
      className={className}
      style={{
        width: '100%',
        height: `${height}px`,
      }}
    />
  )
}

export default PieChart
