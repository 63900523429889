import {useContext} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {CompanyContext} from '../../../context/CompanyContext'

const IMPScale = ({category = '', value = 0, startScaleLabel = '', endScaleLabel = ''}) => {
  const history = useHistory()
  const {company = {}} = useContext(CompanyContext)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  return (
    <div className='d-flex mb-10  imp-scale'>
      <div className='fw-bolder w-100px me-5 pt-6'>
        <Link to={`${prefix}/dashboard/imp/${category}`} className='text-gray-800'>
          {category}
        </Link>
      </div>
      <div className='flex-grow-1'>
        <div className='d-flex justify-content-between pb-4'>
          <span className='text-gray-700 fw-bold fs-9'>{startScaleLabel}</span>
          <span className='text-gray-700 fw-bold fs-9'>{endScaleLabel}</span>
        </div>
        <div className='d-flex'>
          <div className='arrow-start arrow'>
            <KTSVG
              path='/media/icons/duotone/Navigation/Angle-left.svg'
              className='svg-icon-2x svg-icon-dark position-absolute'
            />
          </div>
          <div className='line flex-grow-1 d-flex justify-content-between'>
            <div className='marker marker-1'></div>
            <div className='marker marker-2'></div>
            <div className='marker marker-3'></div>
            <div className='marker marker-4'></div>
            <div className='marker marker-5'></div>
            <div
              className='cursor-pointer position-absolute pointer'
              style={{marginLeft: `${value}%`}}
              title={`${value}%`}
              onClick={() => history.push(`${prefix}/dashboard/imp/${category}`)}
            ></div>
          </div>
          <div className='arrow-end arrow'>
            <KTSVG
              path='/media/icons/duotone/Navigation/Angle-right.svg'
              className='svg-icon-2x svg-icon-dark position-absolute'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default IMPScale
