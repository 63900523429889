import {createContext, useEffect, useState} from 'react'
import {getSectionQuestions} from '../../api'
import {initialSections} from '../modules/shared/Constants'
import {Error} from '../modules/shared/Error'
import LoadingSpinner from '../modules/shared/LoadingSpinner'
import {Goals, metricTags} from '../modules/shared/QuestionTags'

export const QuestionContext = createContext<any>({})

export const QuestionProvider = ({children}: any) => {
  const [sections, setSections] = useState(initialSections)
  const [sectionsLoading, setLoading] = useState(true)
  const [hasErrors, setError] = useState(false)
  const [allQuestions, setAllQuestions] = useState([])

  useEffect(() => {
    Promise.all(sections.map(({tag}: any) => getSectionQuestions(tag)))
      .then((responses) => {
        const arr = responses.reduce(
          (acc: any, response: any, index) => {
            acc[0][index].questions = response.data.data
            acc[1] = [...acc[1], ...response.data.data]
            return acc
          },
          [sections, []]
        )
        setSections(arr[0])
        setAllQuestions(arr[1])
      })
      .catch((error) => {
        setError(true)
        console.log(error)
      })
      .finally(() => setLoading(false))
  }, [])

  const getMetricQuestions = () => allQuestions.filter(({tag}: any) => metricTags.includes(tag))
  const getGoalsQuestion = () => allQuestions.find(({question}: any) => question === Goals) || {}

  return (
    <QuestionContext.Provider
      value={{
        sections,
        sectionsLoading,
        allQuestions,
        metricQuestion: getMetricQuestions(),
        goalsQuestion: getGoalsQuestion(),
      }}
    >
      {sectionsLoading && <LoadingSpinner />}

      {!sectionsLoading && hasErrors && <Error />}
      {!sectionsLoading && !hasErrors && children}
    </QuestionContext.Provider>
  )
}
