import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {CompanyProvider} from '../context/CompanyContext'
import {DashboardProvider} from '../context/DashboardContext'
import {QuestionProvider} from '../context/QuestionContext'
import CompaniesPage from '../modules/admin/companies/CompaniesPage'

export function AdminRoutes() {
  const AdminPage = lazy(() => import('../modules/admin/AdminPage'))

  return (
    <QuestionProvider>
      <CompanyProvider>
        <DashboardProvider>
          <Suspense fallback={<FallbackView />}>
            <Switch>
              <Route path='/admin' component={AdminPage} />
              <Route path='/company' component={CompaniesPage} />
              <Redirect from='/auth' to='/' />
              <Redirect from='/' to='/admin' />
              <Redirect to='error/404' />
            </Switch>
          </Suspense>
        </DashboardProvider>
      </CompanyProvider>
    </QuestionProvider>
  )
}
