import {useEffect, useRef} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import _ from 'lodash'

am4core.useTheme(am4themes_animated)

const LineChart = ({data = [], target, baseline, outcome}: any) => {
  const chartRef = useRef<any>(null)

  data = data.sort((a: any, b: any) => data.indexOf(b) - data.indexOf(a))

  const CHART_ID = _.uniqueId('chart-')

  useEffect(() => {
    if (!chartRef.current) {
      chartRef.current = am4core.create(CHART_ID, am4charts.XYChart)

      // chartRef.current.paddingRight = 20

      // Create axes
      let dateAxis = chartRef.current.xAxes.push(new am4charts.CategoryAxis())
      dateAxis.dataFields.category = 'date'
      dateAxis.renderer.grid.template.disabled = true
      // dateAxis.renderer.labels.template.disabled = true

      // dateAxis.min = 1343347200000
      // dateAxis.max = 1359504000000
      // dateAxis.strictMinMax = true

      let valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis())

      // valueAxis.renderer.grid.template.disabled = true
      // valueAxis.renderer.labels.template.disabled = true

      // valueAxis.min = 0

      // valueAxis.strictMinMax = true

      // Create series
      let series = chartRef.current.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = 'value'
      series.dataFields.categoryX = 'date'

      series.strokeWidth = 1
      series.fillOpacity = 0.2
      series.tooltipText = 'Current = {categoryX}: [bold]{valueY}[/]'

      let series2 = chartRef.current.series.push(new am4charts.LineSeries())
      series2.dataFields.valueY = 'default'
      series2.dataFields.categoryX = 'date'
      series2.strokeWidth = 1
      series2.fillOpacity = 0.2
      series2.tooltipText = 'Outcome without intervention = {categoryX}: [bold]{valueY}[/]'

      let bullet = series.bullets.push(new am4charts.CircleBullet())
      let bullet2 = series2.bullets.push(new am4charts.CircleBullet())

      // Add vertical scrollbar
      // chartRef.current.scrollbarY = new am4core.Scrollbar()
      // chartRef.current.scrollbarY.marginLeft = 0

      // Add cursor
      chartRef.current.cursor = new am4charts.XYCursor()
      // chartRef.current.cursor.behavior = 'zoomY'
      // chartRef.current.cursor.lineX.disabled = true
      // chartRef.current.cursor.snapToSeries = series
      // chartRef.current.cursor.snapToSeries = series2

      // // Disable axis lines
      // chartRef.current.cursor.lineX.disabled = true
      // chartRef.current.cursor.lineY.disabled = true

      // // Disable axis tooltips
      // xAxis.cursorTooltipEnabled = false
      // yAxis.cursorTooltipEnabled = false

      // // Disable zoom
      // chartRef.current.cursor.behavior = 'none'

      const [minY, maxY] = data.reduce(
        (accumulator: any, current: any) => {
          return [
            Math.min(current.value, current.default, accumulator[0]),
            Math.max(current.value, current.default, accumulator[1]),
          ]
        },
        [0, 0]
      )

      if (target) {
        valueAxis.max = maxY > target ? maxY : target + 20
        // Add a guide
        let range = valueAxis.axisRanges.create()
        range.value = target
        range.grid.stroke = am4core.color('#E11484')
        range.grid.strokeWidth = 2
        range.grid.strokeOpacity = 1
        range.grid.strokeDasharray = '5,2'
        range.label.inside = true
        range.label.text = 'Target'
        range.label.fontSize = 11
        range.label.fill = range.grid.stroke
        range.label.verticalCenter = 'bottom'
      }

      if (baseline) {
        // Add a guide
        valueAxis.min = minY <= baseline ? minY : baseline
        let range = valueAxis.axisRanges.create()
        range.value = baseline
        range.grid.stroke = am4core.color('#E11484')
        range.grid.strokeWidth = 2
        range.grid.strokeOpacity = 1
        range.grid.strokeDasharray = '5,2'
        range.label.inside = true
        range.label.text = 'Baseline'
        range.label.fontSize = 11
        range.label.fill = range.grid.stroke
        range.label.verticalCenter = 'bottom'
      }
    }

    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [])

  // Load data into chart
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = data
    }
  }, [data])

  // Handle component unmounting, dispose chart
  useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [])

  return (
    <div
      id={CHART_ID}
      style={{
        width: '100%',
        height: '250px',
        margin: '0px',
        padding: '0px',
      }}
    />
  )
}

export default LineChart
