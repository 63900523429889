import {useContext} from 'react'
import {useParams} from 'react-router-dom'
import {DashboardContext} from '../../../context/DashboardContext'
import BarChart from '../../shared/BarChart'
import SaveToImageButton from '../../shared/SaveToImageButton'

const Stakeholders = () => {
  const {impactList} = useContext(DashboardContext)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  const getDirectStakeholdersData = () => {
    const data = impactList.reduce((acc: any, impact: any) => {
      impact.stakeholders.direct.forEach((d: any) => {
        acc[d.name] = (acc[d.name] || 0) + d.value
      })
      return acc
    }, {})

    return Object.keys(data).reduce(
      (acc: any, channel: any) => [
        ...acc,
        {
          category: channel,
          value: data[channel],
          link: `${prefix}/dashboard/stakeholders/${channel}`,
        },
      ],
      []
    )
  }

  const getInDirectStakeholdersData = () => {
    const data = impactList.reduce((acc: any, impact: any) => {
      impact.stakeholders.inDirect.forEach((d: any) => {
        acc[d.name] = (acc[d.name] || 0) + d.value
      })
      return acc
    }, {})

    return Object.keys(data).reduce(
      (acc: any, channel: any) => [...acc, {category: channel, value: data[channel]}],
      []
    )
  }

  return (
    <div className='card card-custom mb-7' id='stakeholders-chart'>
      <SaveToImageButton node='stakeholders-chart' />
      <div className='card-body'>
        <h3>Stakeholders (Direct vs InDirect)</h3>
        <p className='fs-7 text-gray-600 mb-10'>
          This graph informs your enterprise and investors which stakeholders are to experience the
          outcome of your impact focus and their corresponding targets based on your IMM
          framework(s).
        </p>
        <BarChart data={getDirectStakeholdersData()} />
        <BarChart data={getInDirectStakeholdersData()} />
      </div>
    </div>
  )
}

export default Stakeholders
