/* eslint-disable react/jsx-no-target-blank */
import React, {useContext} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {AuthContext} from '../../../../app/context/AuthContext'

export function AsideMenuMain() {
  const intl = useIntl()

  const {user, isAdmin} = useContext(AuthContext)

  return (
    <>
      <AsideMenuItem
        to='/'
        icon='/media/icons/duotone/Home/Home.svg'
        title='Home' //{intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Dashboard' //{intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Company</span>
        </div>
      </div>
      <AsideMenuItem
        to='/company'
        title='Company'
        fontIcon='bi-archive'
        icon='/media/icons/duotone/Home/Building.svg'
      />

      <>
        <AsideMenuItem
          to='/impact-focus'
          title='Impact Mgt'
          icon='/media/icons/duotone/Design/Layers.svg'
          fontIcon='bi-person'
        />

        {/* <AsideMenuItem
            to='/investors'
            title='My Investors'
            icon='/media/icons/duotone/Design/Layers.svg'
            fontIcon='bi-person'
          /> */}
      </>

      <AsideMenuItem
        to='/members'
        title='Members'
        icon='/media/icons/duotone/General/User.svg'
        fontIcon='bi-person'
      />

      {user.type === 'Investee' && (
        <AsideMenuItem
          to='/fund-managers'
          title='Fund Managers'
          icon='/media/icons/duotone/Shopping/Wallet.svg'
          fontIcon='bi-person'
        />
      )}

      {user.type === 'Investor' && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Portfolio</span>
            </div>
          </div>

          <AsideMenuItem
            to='/portfolio/dashboard'
            title='Dashboard'
            icon='/media/icons/duotone/Design/PenAndRuller.svg'
            fontIcon='bi-person'
          />

          <AsideMenuItem
            to='/portfolio'
            title='Portfolio Manager'
            icon='/media/icons/duotone/Design/Layers.svg'
            fontIcon='bi-person'
          />
        </>
      )}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Others</span>
        </div>
      </div>

      <AsideMenuItem
        to='/directory'
        title='Directory'
        icon='/media/icons/duotone/General/Duplicate.svg'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/reports'
        title='Reports'
        icon='/media/icons/duotone/General/Duplicate.svg'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/resources'
        title='Resources'
        icon='/media/icons/duotone/General/Duplicate.svg'
        fontIcon='bi-layers'
      />

      {/* <AsideMenuItem
        to='/resources'
        title='Resources'
        icon='/media/icons/duotone/Home/Library.svg'
        fontIcon='bi-chat-left'
      /> */}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Account</span>
        </div>
      </div>
      <AsideMenuItem
        to='#'
        title='Settings'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotone/Shopping/Settings.svg'
      />
    </>
  )
}
