import React, {useContext, useEffect, useState} from 'react'
import {Route, Switch, useHistory, useParams} from 'react-router-dom'
import {useToasts} from 'react-toast-notifications'
import {
  acceptPortfolioInvitation,
  declinePortfolioInvitation,
  getPortfoliosByCompany,
} from '../../../../api'
import {PageTitle} from '../../../../_metronic/layout/core'
import {CompanyContext} from '../../../context/CompanyContext'
import LoadingSpinner from '../../shared/LoadingSpinner'

const PortfolioAssessment: React.FC = () => {
  const [isAccepting, setAccepting] = useState(false)
  const [isDeclining, setDeclining] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const {company} = useContext(CompanyContext)

  const history = useHistory()

  const {addToast} = useToasts()

  const [data, setData] = useState<any>({})

  const {portfolio_id} = useParams<any>()

  useEffect(() => {
    reloadData()
  }, [])

  const reloadData = () => {
    setLoading(true)
    getPortfoliosByCompany(company.group_identifier)
      .then(({data = []}) => {
        setData(data.find(({portfolio_identifier}: any) => portfolio_identifier === portfolio_id))
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const acceptInvitation = () => {
    setAccepting(true)

    acceptPortfolioInvitation(company.group_identifier, data.portfolio_identifier)
      .then((response) => {
        addToast('Invitation accepted successfully', {
          appearance: 'success',
          autoDismiss: true,
        })

        reloadData()
      })
      .catch((error) => {
        addToast('Something went wrong. Please try again later.', {
          appearance: 'warning',
          autoDismiss: true,
        })
      })
      .finally(() => setAccepting(false))
  }

  const declineInvitation = () => {
    setDeclining(true)

    declinePortfolioInvitation(company.group_identifier, data.portfolio_identifier)
      .then((response) => {
        addToast('Invitation declined successfully', {
          appearance: 'success',
          autoDismiss: true,
        })

        history.push('/fund-managers')
      })
      .catch((error) => {
        addToast('Something went wrong. Please try again later.', {
          appearance: 'warning',
          autoDismiss: true,
        })
      })
      .finally(() => setDeclining(false))
  }

  return (
    <>
      <PageTitle>Portfolio Assessment Page</PageTitle>

      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          {data.status === 'Pending' && (
            <div className='card card-custom shadow mb-10 bg-info'>
              <div className='card-body p-10 '>
                <h2 className='text-white'>Respond To Invitation</h2>
                <p className='fs-7 text-gray-100 fw-bold '>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit
                  amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
                <div className=''>
                  <button className='btn btn-success me-5' onClick={acceptInvitation}>
                    {isAccepting ? (
                      <>
                        Accepting...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </>
                    ) : (
                      'Accept Invitation'
                    )}
                  </button>
                  <button className='btn text-white btn-link me-5 fs-6' onClick={declineInvitation}>
                    {isDeclining ? (
                      <>
                        Declining...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </>
                    ) : (
                      'Decline Invitation'
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className='card card-custom shadow mb-15'>
            <div className='card-header'>
              <h3 className='card-title'>Company Details</h3>
              <div className='card-toolbar'></div>
            </div>
            <div className='card-body px-10 py-0 '>
              <div className='fs-6 p-5' style={{borderBottom: '1px solid rgb(239, 242, 245)'}}>
                <div className='fs-9 text-gray-500 fw-bold mb-2'>Company Name</div>
                <div className='text-dark fw-bolder fs-6'>{data?.company_info?.company_name}</div>
              </div>
              <div className='fs-6 p-5' style={{borderBottom: '1px solid rgb(239, 242, 245)'}}>
                <div className='fs-9 text-gray-500 fw-bold mb-2'>
                  What type of investor is your organization?
                </div>
                <div className='text-dark fw-bolder fs-6'>Launch phase</div>
              </div>
              <div className='fs-6 p-5' style={{borderBottom: '1px solid rgb(239, 242, 245)'}}>
                <div className='fs-9 text-gray-500 fw-bold mb-2'>Website</div>
                <div className='text-dark fw-bolder fs-6'>
                  {data?.company_info?.company_website}
                </div>
              </div>
              <div className='fs-6 p-5' style={{borderBottom: '1px solid rgb(239, 242, 245)'}}>
                <div className='fs-9 text-gray-500 fw-bold mb-2'>
                  Indicate the date of establishment
                </div>
                <div className='text-dark fw-bolder fs-6'>17/11/2021</div>
              </div>
              <div className='fs-6 p-5' style={{borderBottom: '1px solid rgb(239, 242, 245)'}}>
                <div className='fs-9 text-gray-500 fw-bold mb-2'>
                  What is your sector of business ?
                </div>
                <div className='text-dark fw-bolder fs-6'>
                  {data?.company_info?.company_sector?.value}
                </div>
              </div>
              <div className='fs-6 p-5' style={{borderBottom: '1px solid rgb(239, 242, 245)'}}>
                <div className='fs-9 text-gray-500 fw-bold mb-2'>Location</div>
                <div className='text-dark fw-bolder fs-6'>
                  {data?.company_info?.company_location?.value}
                </div>
              </div>
              <div className='fs-6 p-5' style={{borderBottom: '1px solid rgb(239, 242, 245)'}}>
                <div className='fs-9 text-gray-500 fw-bold mb-2'>Company Description</div>
                <div className='text-dark fw-bolder fs-6'>
                  {data?.company_info?.company_description}
                </div>
              </div>
              <div className='fs-6 p-5' style={{borderBottom: '1px solid rgb(239, 242, 245)'}}>
                <div className='fs-9 text-gray-500 fw-bold mb-2'>Company Email</div>
                <div className='text-dark fw-bolder fs-6'>{data?.company_info?.company_email}</div>
              </div>
              <div className='fs-6 p-5' style={{borderBottom: '1px solid rgb(239, 242, 245)'}}>
                <div className='fs-9 text-gray-500 fw-bold mb-2'>Phone Number</div>
                <div className='text-dark fw-bolder fs-6'>
                  {data?.company_info?.company_phone_number}
                </div>
              </div>
            </div>
          </div>

          <div className='card card-custom shadow mb-15'>
            <div className='card-header'>
              <h3 className='card-title'>Portfolio</h3>
              <div className='card-toolbar'></div>
            </div>
            <div className='card-body px-10 py-0 '>
              {data.portfolio.map((p: any) => (
                <div className='fs-6 p-5' style={{borderBottom: '1px solid rgb(239, 242, 245)'}}>
                  <div className='fs-9 text-gray-500 fw-bold mb-2'>{p.question}</div>
                  <div className='text-dark fw-bolder fs-6'>{p?.answer?.value}</div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default PortfolioAssessment
