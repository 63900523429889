import {useContext, useEffect, useState} from 'react'
import {Link, useParams, useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
import PieChart from '../../shared/PieChart'
import {sdgs} from '../../shared/Constants'
import CurrentMetric from '../../shared/CurrentMetric'
import {getMetricPercentageCompleted} from '../../shared/Helpers'
import {getCompanyIMP, getSectorBenchmark} from '../../../../api'
import IMPScale from './IMPScale'
import IMPScaleFull from './IMPScaleFull'

const IMPReportDetails = () => {
  const {category} = useParams<any>() || {}

  const {impactList} = useContext(DashboardContext)

  const {company = {}} = useContext(CompanyContext)
  const [isLoading, setLoading] = useState(true)
  const [impData, setImpData] = useState<any>({})
  const [benchmark, setBenchmark] = useState<any>(0)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  useEffect(() => {
    getCompanyIMP(company.group_identifier)
      .then(({data = []}) => {
        setImpData(data.find((d: any) => d.category === category))
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    getSectorBenchmark(company.group_identifier)
      .then(({data = []}) => {
        setBenchmark(data.benchmark)
      })
      .catch((error) => console.log(error))
  }, [])

  const getMetricTag = (category: any) => {
    if (category === 'What') return ['what-metrics']
    if (category === 'Who') return ['who-metrics']
    if (category === 'How Much' || category === 'Contribution')
      return ['what-metrics', 'how-much-metrics']
    else return []
  }
  const data = impactList.reduce((acc: any, impact: any) => {
    impact.metrics.forEach((metric: any) => {
      getMetricTag(category).forEach((tag: any) => {
        if (metric.tags.includes(tag)) {
          acc.push({impact, metric})
        }
      })
    })
    return acc
  }, [])

  return (
    <>
      <PageTitle>{`IMP Report - ${category}`}</PageTitle>

      {category === 'Contribution' && (
        <div className='row mb-10'>
          <div className='col-6'>
            <div className='card card-custom p-10'>
              <h2>Your Contribution</h2>
              <p>
                Below is the percentage of contribution on the Impact Management Assessment Scale as
                against the industry benchmark on the right.
              </p>
              <div className='text-center' style={{fontSize: 70}}>
                {impData?.value}
              </div>
            </div>
          </div>

          <div className='col-6'>
            <div className='card card-custom p-10'>
              <h2>Industry Benchmark</h2>
              <p>
                Below is the average percentage of the Sector/Industry Benchmark on the IMP Scale as
                against the your contribution on the left.
              </p>
              <div className='text-center' style={{fontSize: 70}}>
                {benchmark}
              </div>
            </div>
          </div>
        </div>
      )}

      {!isLoading && (
        <div className='card card-custom p-10'>
          <IMPScaleFull {...impData} />
        </div>
      )}

      {category !== 'Impact Risk' && (
        <div className=' card card-custom p-10 mt-10'>
          <h2>Contributing Metrics</h2>
          <p className='fw-bold text-gray-600'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div className='table-responsive '>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-200 align-middle gs-10 mb-0 pb-0'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-dark'>
                  <th className='pt-3 pb-3 col-5'>Metric Name</th>
                  <th className='pt-3 pb-3 col-2'>Impact Focus</th>
                  <th className='pt-3 pb-3 col-2'>Baseline</th>
                  <th className='pt-3 pb-3 col-1'>Target</th>
                  <th className='pt-3 pb-3 col-2'>Progress</th>
                  <th className='pt-3 pb-3 col-1'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              {data.map((i: any) => (
                <>
                  <tbody>
                    <tr>
                      <td>
                        <div className='d-flex justify-content-start flex-column py-1'>
                          <Link
                            to={{
                              pathname: `${prefix}/impact-focus/${i?.impact?.group_identifier}/metrics/${i?.metric?.value}`,
                            }}
                            className='fw-bold  fs-9'
                          >
                            <span className='text-gray-700 fw-bolder fs-7 mb-1'>
                              {i.metric?.name}
                            </span>
                          </Link>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex justify-content-start flex-column py-1'>
                          <Link
                            to={{
                              pathname: `${prefix}/impact-focus/${i?.impact?.group_identifier}`,
                            }}
                            className='fw-bold  fs-9'
                          >
                            {i.impact?.name}
                          </Link>
                        </div>
                      </td>

                      <td>
                        <span className='text-gray-500 fw-bolder fs-8'>
                          {i.metric?.baseline
                            ? `${i.metric?.baseline.startPosition} ${i.metric?.baseline.unit}`
                            : '-'}
                        </span>
                      </td>

                      <td>
                        <span className='text-gray-500 fw-bolder fs-8'>
                          {i.metric?.baseline
                            ? `${i.metric?.baseline.target} ${i.metric?.baseline.unit}`
                            : '-'}
                        </span>
                      </td>

                      <td>
                        <CurrentMetric
                          target={i?.metric?.baseline?.target || 0}
                          baseline={i?.metric?.baseline?.startPosition || 0}
                          unit={i?.metric?.baseline?.unit}
                          currentPosition={
                            i?.metric?.progress && i.metric?.progress?.length > 0
                              ? i?.metric?.progress[0]?.currentPosition
                              : 0
                          }
                        />
                      </td>

                      <td>
                        {i?.metric?.progress && i.metric?.progress?.length > 0 && (
                          <Link
                            to={{
                              pathname: `${prefix}/impact-focus/${i?.impact?.group_identifier}/metrics/${i?.metric?.value}`,
                            }}
                            className='fw-bold  fs-9'
                          >
                            View History
                          </Link>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </>
              ))}

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        </div>
      )}

      {category === 'Impact Risk' && (
        <div className=' card card-custom p-10 mt-10'>
          <h2>Risk Profiles</h2>
          <p className='fw-bold text-gray-600'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div className='table-responsive '>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-200 align-middle gs-10 mb-0 pb-0'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-dark'>
                  <th className='pt-3 pb-3 col-8'>Risk Type</th>
                  <th className='pt-3 pb-3 col-2'>Risk Level</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              {impactList.map((i: any) => (
                <>
                  <tbody>
                    <tr>
                      <td
                        colSpan={3}
                        className='bg-dark bg-opacity-50 text-white px-9 py-2 fs-8 fw-bolder col-12'
                      >
                        <Link
                          to={{
                            pathname: `${prefix}/impact-focus/${i?.group_identifier}`,
                          }}
                          className='text-white '
                        >
                          {i?.name}
                        </Link>
                      </td>
                    </tr>

                    {i.risks.map((risk: any) => (
                      <tr>
                        <td>
                          <div className='d-flex justify-content-start flex-column py-1'>
                            <span className='text-gray-700 fw-bolder fs-7 mb-1'>{risk?.type}</span>
                          </div>
                        </td>
                        <td>
                          <span className={`text-gray-500 fw-bolder fs-8 ${risk?.level}`}>
                            {risk?.level}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              ))}

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        </div>
      )}
    </>
  )
}

export default IMPReportDetails
