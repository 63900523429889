import {useContext} from 'react'
import {Link, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
import SaveToImageButton from '../../shared/SaveToImageButton'

const SdgPerformance = () => {
  const {sdgPerformance} = useContext(DashboardContext)
  const {company = {}} = useContext(CompanyContext)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  return (
    <>
      <div className='card card-custom mb-13' id='sdg-performance-chart'>
        <SaveToImageButton node='sdg-performance-chart' />
        <div className='card-body'>
          <h3>Performance on SDG's</h3>
          <p className='fs-7 text-gray-600 mb-10'>
            This graph shows your enterprise's aggregated current performance on impact metrics
            relative to its set targets. All impact metrics in your IMM framework(s) are mapped to
            the SDGs using the IRIS Catalog of Metrics. The progress is calculated relative to your
            baseline and target.
          </p>
          {sdgPerformance.map((p: any) => (
            <div className='d-flex mb-5'>
              <div className='p-0'>
                <Link to={`${prefix}/dashboard/sdg/${p.value}`} title={p.label}>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl(`/media/images/sdgs/${p.value}.png`)}
                    className='h-40px w-auto'
                    title={p.label}
                  />
                </Link>
              </div>
              <div className='flex-grow-1 mx-4 '>
                <Link to={`${prefix}/dashboard/sdg/${p.value}`} title={p.label}>
                  <span className='fw-bold fs-7 d-inline-block text-truncate overflow-hidden'>
                    {p.label}
                  </span>
                </Link>
                <div className='progress h-5px w-100 bg-secondary'>
                  <div
                    className='progress-bar '
                    role='progressbar'
                    style={{
                      width: `${p.completed && p.completed != 'NaN' ? p.completed : 0.0}%`,
                      backgroundColor: p.color,
                    }}
                  ></div>
                </div>
              </div>
              <div className='fw-bold w-40px text-start pt-5'>
                {p.completed && p.completed != 'NaN' ? p.completed : '0.0'}%
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default SdgPerformance
