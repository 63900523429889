/* eslint-disable jsx-a11y/anchor-is-valid */
// import {useFormik} from 'formik'
import React, {useContext, useEffect, useRef, useState} from 'react'

import Select from 'react-select'
import _, {values} from 'lodash'

import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
// import {locations, sdgs, sectors} from '../../../shared/Constants'
// import {parseJsonString} from '../../../shared/Helpers'
// import {getCompanies} from '../../../../../api'
import {Link, useParams} from 'react-router-dom'
import {AuthContext} from '../../../../context/AuthContext'

const CompanyReports = () => {
  const {user, isAdmin} = useContext(AuthContext)
  const {id, group_identifier, portfolio_id} = useParams<any>()
  const prefix = user.type === 'Investor' ? `/portfolio/${portfolio_id}` : '/admin'

  return (
    <div className='row  g-xl-8'>
      <div className='col-6'>
        <div className='card'>
          <div className='card-body d-flex align-items-center py-8'>
            <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
              <KTSVG
                path='/media/icons/duotone/Layout/Layout-polygon.svg'
                className='svg-icon-primary position-absolute opacity-15'
                svgClassName='h-80px w-80px'
              />
              <KTSVG
                path='/media/icons/duotone/Home/Book-open.svg'
                className='svg-icon-3x svg-icon-primary position-absolute'
              />
            </div>
            <div className='ms-6  cursor-pointer'>
              <Link to={`/company/${group_identifier}/reports/imp-assessment-report`}>
                <h3>IMP Assessment Report</h3>
                <p className='list-unstyled text-gray-600 fw-bold fs-7 p-0 m-0'>
                  This graph shows your enterprise's aggregated impact based on current performance
                  across the IMP's Five Dimensions of Impact core metrics in your IMM framework(s)
                  and embedded calculations.
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='col-6'>
        <div className='card'>
          <div className='card-body d-flex align-items-center py-8'>
            <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
              <KTSVG
                path='/media/icons/duotone/Layout/Layout-polygon.svg'
                className='svg-icon-primary position-absolute opacity-15'
                svgClassName='h-80px w-80px'
              />
              <KTSVG
                path='/media/icons/duotone/Home/Book-open.svg'
                className='svg-icon-3x svg-icon-primary position-absolute'
              />
            </div>
            <div className='ms-6  cursor-pointer'>
              <Link to={`/company/${group_identifier}/reports/impact-investment-report`}>
                <h3>Impact Investment Report</h3>
                <p className='list-unstyled text-gray-600 fw-bold fs-7 p-0 m-0'>
                  This report highlights the overview and trend analysis of your enterprise's impact
                  investment initiatives.
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='col-6'>
        <div className='card'>
          <div className='card-body d-flex align-items-center py-8'>
            <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
              <KTSVG
                path='/media/icons/duotone/Layout/Layout-polygon.svg'
                className='svg-icon-primary position-absolute opacity-15'
                svgClassName='h-80px w-80px'
              />
              <KTSVG
                path='/media/icons/duotone/Home/Book-open.svg'
                className='svg-icon-3x svg-icon-primary position-absolute'
              />
            </div>
            <div className='ms-6  cursor-pointer'>
              <Link to={`/company/${group_identifier}/reports/sdg-performance-report`}>
                <h3>SDG Performance Report</h3>
                <p className='list-unstyled text-gray-600 fw-bold fs-7 p-0 m-0'>
                  Enterprise aggregated current performance on the SDGs based on impact metrics and
                  relative targets.
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='col-6'>
        <div className='card'>
          <div className='card-body d-flex align-items-center py-8'>
            <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
              <KTSVG
                path='/media/icons/duotone/Layout/Layout-polygon.svg'
                className='svg-icon-primary position-absolute opacity-15'
                svgClassName='h-80px w-80px'
              />
              <KTSVG
                path='/media/icons/duotone/Home/Book-open.svg'
                className='svg-icon-3x svg-icon-primary position-absolute'
              />
            </div>
            <div className='ms-6  cursor-pointer'>
              <Link to={`/company/${group_identifier}/reports/sdg-performance-data`}>
                <h3>SDG Performance Data</h3>
                <p className='list-unstyled text-gray-600 fw-bold fs-7 p-0 m-0'>
                  Enterprise's current data reported on impact metrics relative to its targets and
                  baseline.
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='col-6'>
        <div className='card'>
          <div className='card-body d-flex align-items-center py-8'>
            <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
              <KTSVG
                path='/media/icons/duotone/Layout/Layout-polygon.svg'
                className='svg-icon-primary position-absolute opacity-15'
                svgClassName='h-80px w-80px'
              />
              <KTSVG
                path='/media/icons/duotone/Home/Book-open.svg'
                className='svg-icon-3x svg-icon-primary position-absolute'
              />
            </div>
            <div className='ms-6  cursor-pointer'>
              <Link
                to={`/company/${group_identifier}/reports/impact-measurement-accountability-report`}
              >
                <h3>Accountability Report</h3>
                <p className='list-unstyled text-gray-600 fw-bold fs-7 p-0 m-0'>
                  Enterprise's accountability and transparency in impact analysis and reporting.
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='col-6'>
        <div className='card'>
          <div className='card-body d-flex align-items-center py-8'>
            <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
              <KTSVG
                path='/media/icons/duotone/Layout/Layout-polygon.svg'
                className='svg-icon-primary position-absolute opacity-15'
                svgClassName='h-80px w-80px'
              />
              <KTSVG
                path='/media/icons/duotone/Home/Book-open.svg'
                className='svg-icon-3x svg-icon-primary position-absolute'
              />
            </div>
            <div className='ms-6  cursor-pointer'>
              <Link to={`/company/${group_identifier}/reports/impact-objectives-report`}>
                <h3>Impact Objectives Report</h3>
                <p className='list-unstyled text-gray-600 fw-bold fs-7 p-0 m-0'>
                  Overview of enterprise's impact categories, strategic goals, and IMM Framework(s).
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='col-6'>
        <div className='card'>
          <div className='card-body d-flex align-items-center py-8'>
            <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
              <KTSVG
                path='/media/icons/duotone/Layout/Layout-polygon.svg'
                className='svg-icon-primary position-absolute opacity-15'
                svgClassName='h-80px w-80px'
              />
              <KTSVG
                path='/media/icons/duotone/Home/Book-open.svg'
                className='svg-icon-3x svg-icon-primary position-absolute'
              />
            </div>
            <div className='ms-6  cursor-pointer'>
              <Link to={`/company/${group_identifier}/reports/impact-data-report`}>
                <h3>Impact Data Report (Raw)</h3>
                <p className='list-unstyled text-gray-600 fw-bold fs-7 p-0 m-0'>
                  Enterprise's historical data reported on impact metrics relative to its targets
                  and baseline.
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='col-6'>
        <div className='card'>
          <div className='card-body d-flex align-items-center py-8'>
            <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
              <KTSVG
                path='/media/icons/duotone/Layout/Layout-polygon.svg'
                className='svg-icon-primary position-absolute opacity-15'
                svgClassName='h-80px w-80px'
              />
              <KTSVG
                path='/media/icons/duotone/Home/Book-open.svg'
                className='svg-icon-3x svg-icon-primary position-absolute'
              />
            </div>
            <div className='ms-6  cursor-pointer'>
              <Link to={`/company/${group_identifier}/reports/metrics-performance-history`}>
                <h3>Performance History (Raw)</h3>
                <p className='list-unstyled text-gray-600 fw-bold fs-7 p-0 m-0'>
                  Enterprise's current data reported on impact metrics relative to its targets and
                  baseline.
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>{' '}
      <div className='col-6'>
        <div className='card'>
          <div className='card-body d-flex align-items-center py-8'>
            <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
              <KTSVG
                path='/media/icons/duotone/Layout/Layout-polygon.svg'
                className='svg-icon-primary position-absolute opacity-15'
                svgClassName='h-80px w-80px'
              />
              <KTSVG
                path='/media/icons/duotone/Home/Book-open.svg'
                className='svg-icon-3x svg-icon-primary position-absolute'
              />
            </div>
            <div className='ms-6  cursor-pointer'>
              <Link to={`/company/${group_identifier}/reports/company-data-report`}>
                <h3>Company Data Report (Raw)</h3>
                <p className='list-unstyled text-gray-600 fw-bold fs-7 p-0 m-0'>
                  Enterprise's company profile information.
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyReports
