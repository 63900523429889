import React, {Suspense, lazy, useContext} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {AuthContext} from '../context/AuthContext'
import {CompanyProvider} from '../context/CompanyContext'
import {DashboardProvider} from '../context/DashboardContext'
import {PortfolioProvider} from '../context/PortfolioContext'
import {QuestionProvider} from '../context/QuestionContext'
import FundManagersPage from '../modules/fund-managers/FundManagersPage'
import ResourcesPage from '../modules/resources/ResourcesPage'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {AdminRoutes} from './AdminRoutes'

export function PrivateRoutes() {
  const {user, isAdmin} = useContext(AuthContext)

  const CompanyPage = lazy(() => import('../modules/company/CompanyPage'))
  const ImpactFocusPage = lazy(() => import('../modules/impact-focus/ImpactFocusPage'))
  const DirectoryPage = lazy(() => import('../modules/directory/DirectoryPage'))
  const MembersPage = lazy(() => import('../modules/members/MembersPage'))
  const PortfolioPage = lazy(() => import('../modules/portfolio/PortfolioPage'))
  const DashboardPage = lazy(() => import('../modules/dashboard/DashboardPage'))
  const ReportsPage = lazy(() => import('../modules/reports/ReportsPage'))
  const AdminPage = lazy(() => import('../modules/admin/AdminPage'))

  return (
    <>
      {isAdmin && <AdminRoutes />}
      {!isAdmin && (
        <QuestionProvider>
          <CompanyProvider>
            <DashboardProvider>
              <PortfolioProvider>
                <Suspense fallback={<FallbackView />}>
                  <Switch>
                    <Route path='/dashboard' component={DashboardPage} />
                    <Route path='/company' component={CompanyPage} />
                    <Route path='/impact-focus' component={ImpactFocusPage} />
                    <Route path='/directory' component={DirectoryPage} />
                    <Route path='/members' component={MembersPage} />
                    <Route path='/fund-managers' component={FundManagersPage} />
                    <Route path='/portfolio' component={PortfolioPage} />
                    <Route path='/reports' component={ReportsPage} />
                    <Route path='/resources' component={ResourcesPage} />
                    <Route path='/' component={DashboardWrapper} />
                    <Redirect from='/auth' to='/' />
                    <Redirect to='error/404' />
                  </Switch>
                </Suspense>
              </PortfolioProvider>
            </DashboardProvider>
          </CompanyProvider>
        </QuestionProvider>
      )}
    </>
  )
}
