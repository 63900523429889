import {KTSVG} from '../../../../_metronic/helpers'

const IMPScaleFull = ({
  category = '',
  value = 0,
  startScaleLabel = '',
  endScaleLabel = '',
  description = '',
}) => (
  <div className='row imp-scale mt-7 mb-10'>
    <div className='col-6'>
      <div className='pe-5'>
        <span className='fw-bolder'>{category}: </span>
        {description}
      </div>
    </div>
    <div className='col-6'>
      <div className='ps-5'>
        <div className='d-flex justify-content-between pb-4'>
          <span className='text-gray-700 fw-bold fs-9'>{startScaleLabel}</span>
          <span className='text-gray-700 fw-bold fs-9'>{endScaleLabel}</span>
        </div>
        <div className='d-flex '>
          <div className='arrow-start arrow'>
            <KTSVG
              path='/media/icons/duotone/Navigation/Angle-left.svg'
              className='svg-icon-2x svg-icon-dark position-absolute'
            />
          </div>
          <div className='line flex-grow-1 d-flex justify-content-between'>
            <div className='marker marker-1'></div>
            <div className='marker marker-2'></div>
            <div className='marker marker-3'></div>
            <div className='marker marker-4'></div>
            <div className='marker marker-5'></div>
            <div
              className='pointer position-absolute'
              style={{marginLeft: `${value}%`}}
              title={`${value}%`}
            ></div>
          </div>
          <div className='arrow-end arrow'>
            <KTSVG
              path='/media/icons/duotone/Navigation/Angle-right.svg'
              className='svg-icon-2x svg-icon-dark position-absolute'
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default IMPScaleFull
