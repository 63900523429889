/* eslint-disable react/jsx-no-target-blank */
import React, {useContext} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {AuthContext} from '../../../../app/context/AuthContext'

export function AsideMenuAdmin() {
  return (
    <>
      <AsideMenuItem
        to='/admin'
        icon='/media/icons/duotone/Home/Home.svg'
        title='Home' //{intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/admin/dashboard'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Dashboard' //{intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/admin/demographics'
        icon='/media/icons/duotone/Design/Color-profile.svg'
        title='Demographics' //{intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>View</span>
        </div>
      </div>
      <AsideMenuItem
        to='/company'
        title='Enterprises'
        fontIcon='bi-archive'
        icon='/media/icons/duotone/Shopping/Box1.svg'
      />
      <AsideMenuItem
        to='/admin/investors'
        title='Fund Managers'
        fontIcon='bi-archive'
        icon='/media/icons/duotone/Shopping/Box1.svg'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Setup</span>
        </div>
      </div>
      {/* <AsideMenuItem
        to='/admin/sections'
        title='Sections'
        fontIcon='bi-archive'
        icon='/media/icons/duotone/Shopping/Box1.svg'
      /> */}
      <AsideMenuItem
        to='/admin/questions'
        title='Questions'
        fontIcon='bi-archive'
        icon='/media/icons/duotone/Code/Question-circle.svg'
      />
      <AsideMenuItem
        to='/admin/metrics'
        title='Metrics'
        icon='/media/icons/duotone/Design/Layers.svg'
        fontIcon='bi-person'
      />
      <AsideMenuItem
        to='/admin/locations'
        title='Locations'
        icon='/media/icons/duotone/Design/Layers.svg'
        fontIcon='bi-person'
      />
      <AsideMenuItem
        to='/admin/resources'
        title='Resources'
        icon='/media/icons/duotone/Design/Layers.svg'
        fontIcon='bi-person'
      />
      <AsideMenuItem
        to='/admin/impact-categories'
        title='Impact Category'
        icon='/media/icons/duotone/Design/Layers.svg'
        fontIcon='bi-person'
      />

      <AsideMenuItem
        to='/admin/themes'
        title='Impact Themes'
        icon='/media/icons/duotone/Design/Layers.svg'
        fontIcon='bi-person'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
        </div>
      </div>
      <AsideMenuItem
        to='/admin/users'
        title='Users'
        icon='/media/icons/duotone/General/User.svg'
        fontIcon='bi-person'
      />
      <AsideMenuItem
        to='/admin/roles'
        title='Roles & Privileges'
        icon='/media/icons/duotone/General/User.svg'
        fontIcon='bi-person'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Others</span>
        </div>
      </div>
      {/* <AsideMenuItem
        to='/directory'
        title='Directory'
        icon='/media/icons/duotone/General/Duplicate.svg'
        fontIcon='bi-layers'
      /> */}
      <AsideMenuItem
        to='/admin/reports'
        title='Reports'
        icon='/media/icons/duotone/General/Duplicate.svg'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Account</span>
        </div>
      </div>
      <AsideMenuItem
        to='#'
        title='Settings'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotone/Shopping/Settings.svg'
      />
    </>
  )
}
