import React from 'react'
import {Route, Switch} from 'react-router-dom'
import FundManagersList from './components/FundManagersList'
import PortfolioAssessment from './components/PortfolioAssessment'

const FundManagersPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path='/fund-managers'>
          <FundManagersList />
        </Route>

        <Route exact path='/fund-managers/portfolio/:portfolio_id'>
          <PortfolioAssessment />
        </Route>
      </Switch>
    </>
  )
}

export default FundManagersPage
