import {useEffect, useRef} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import _ from 'lodash'
import {useHistory} from 'react-router'
import {alphaHex} from './Helpers'

const VerticalBarChart = ({
  data = [],
  useDataColor = false,
  useAlpha = false,
  height = 400,
  numberFormat,
  className,
}: any) => {
  const CHART_ID = _.uniqueId('chart-')

  const history = useHistory()

  useEffect(() => {
    /* Chart code */
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new(CHART_ID)

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
      })
    )

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}))
    cursor.lineY.set('visible', false)

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
    })
    xRenderer.labels.template.setAll({
      rotation: 0,
      centerY: am5.p50,
      centerX: am5.p50,
    })

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        // maxDeviation: 0.3,
        categoryField: 'category',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    )

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {}),
        numberFormat,
        panY: false,
      })
    )

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        sequencedInterpolation: true,
        categoryXField: 'category',
        tooltip: am5.Tooltip.new(root, {
          labelText: '{valueY}',
        }),
      })
    )

    series.columns.template.setAll({cornerRadiusTL: 5, cornerRadiusTR: 5})
    series.columns.template.adapters.add('fill', (fill: any, target: any) => {
      return chart.get('colors')!.getIndex(series.columns.indexOf(target))
    })

    series.columns.template.adapters.add('stroke', (stroke: any, target: any) => {
      return chart.get('colors')!.getIndex(series.columns.indexOf(target))
    })

    series.columns.template.events.on('click', function (ev: any) {
      const {dataContext = {}} = ev.target.dataItem

      const {link} = data.find(
        (d: any) => d.category === dataContext.category && d.value === dataContext.value
      )

      if (link) history.push(link)
    })

    xAxis.data.setAll(data)
    series.data.setAll(data)

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000)
    chart.appear(1000, 100)
  }, [])

  return (
    <div
      id={CHART_ID}
      className={className}
      style={{
        width: '100%',
        height: `${height}px`,
      }}
    />
  )
}

export default VerticalBarChart
