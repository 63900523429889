/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {ResetPassword} from './components/ResetPassword'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <Switch>
      <Route path='/auth/login' component={Login} />
      <Route path='/auth/registration' component={Registration} />
      <Route path='/auth/forgot-password' component={ForgotPassword} />
      <Route path='/auth/reset-password/:token' component={ResetPassword} />
      <Redirect from='/auth' exact={true} to='/auth/login' />
      <Redirect to='/auth/login' />
    </Switch>
  )
}
