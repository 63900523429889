import React, {useEffect, useState} from 'react'
// import {Link} from 'react-router-dom'
import {getResources} from '../../../api'
// import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'

const ResourcesPage: React.FC = () => {
  const [resources, setResources] = useState([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    getResources()
      .then((response) => {
        const {data = []} = response.data || {}
        setResources(data)
      })
      .catch((error) => {})
      .finally(() => setLoading(false))
  }, [])

  return (
    <>
      <PageTitle>Resources</PageTitle>

      <div className='row'>
        {resources.map((resource: any, index: any) => (
          <div className='col-4 mb-10'>
            <div className='card card-custom'>
              <div
                className='card-header h-150px'
                style={{
                  backgroundImage: `url('${resource.picture}')`,
                  backgroundSize: 'cover',
                }}
              ></div>
              <div className='card-body'>
                <h2>{resource.title}</h2>
                <div className='h-95px overflow-hidden'>
                  <p className='fw-bold text-gray-600'>{resource.description}</p>
                </div>
              </div>
              <div className='card-footer'>
                {resource.link && (
                  <a href={resource.link} target='_blank' className='me-3'>
                    Visit
                  </a>
                )}
                {resource.file && (
                  <a href={resource.file} target='_blank'>
                    Download
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default ResourcesPage
