import {isArray} from '@amcharts/amcharts4/core'
import moment, {duration} from 'moment'
import {sdgs} from './Constants'
import {metricTags} from './QuestionTags'
import _ from 'lodash'

export const maybePluralize = (count: any, noun: any, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`

export const getBaseline = (arr: any = []) => {
  const [obj] = Array.isArray(arr) ? arr : [arr]

  let baseline = null
  for (const key in obj) {
    if (!Array.isArray(obj[key])) continue
    baseline = {
      target: getBaselineTarget(obj[key]),
      unit: getMeasurementUnit(obj[key]),
      startPosition: getBaselineStartPosition(obj[key]),
      outcomeDescription: getBaselineOutcomeDescription(obj[key]),
      group_identifier: key,
      questions: obj[key],
      // percentageCompleted:
      //   getBaselineTarget(obj[key]) > 0 &&
      //   ((getBaselineStartPosition(obj[key]) * 100) / getBaselineTarget(obj[key])).toFixed(2),
    }
  }

  return baseline
}

export const getMeasurementUnit = (questions: any) => {
  const {answer} =
    questions.find(
      (question: any) =>
        question.question ===
        'What is the unit of measurement for this impact assessment metric? (e.g. Meters, People, Income, Tons, Kilos, Kilometers (Km), Hectares, Licenses, Quintiles etc..)'
    ) || {}
  return answer?.value || ''
}

export const getProgress = (impact: any, arr: any = []) => {
  let progress: any = []

  arr = Array.isArray(arr) ? arr : [arr]

  arr.forEach((obj: any) => {
    for (const key in obj) {
      if (!Array.isArray(obj[key])) continue
      progress.push({
        period: getOutcomePeriod(obj[key]),
        currentPosition: getCurrentPosition(obj[key]),
        defaultPosition: getDefaultPosition(obj[key]),
        channels: getMeasurementChannels(obj[key]),
        outcomeDescription: getProgressOutcomeDescription(obj[key]),
        group_identifier: key,
        questions: obj[key],
        // percentageCompleted:
        //   metric.baseline &&
        //   metric.baseline.target > 0 &&
        //   ((getCurrentPosition(obj[key]) / metric.baseline.target) * 100).toFixed(2),
      })
    }
  })

  progress = progress.map((p: any) => ({
    ...p,
    duration: getDurationByImpactPeriod(impact?.measurementPeriod, p?.period?.label),
  }))

  return progress
    .filter(({period}: any) => period)
    .sort((a: any, b: any) => moment(b.period?.endDate).diff(moment(a.period?.endDate)))
}

export const removeInvalidProgress = (impactList = []) => {
  // console.log('Testing Impact >> ', impactList)

  return impactList.map((impact: any) => ({
    ...impact,
    metrics: impact.metrics.map((metric: any) => ({
      ...metric,
      invalidProgress: metric.progress.filter(({duration}: any) => !duration),
      progress: metric.progress.filter(({duration}: any) => duration),
    })),
  }))
}

export const getDurationByImpactPeriod = (measurementPeriod: any, label: any) => {
  const [startDate, endDate] = (label || '').split(' - ')
  const year = getYearFromDuration(startDate, endDate)
  if (!year || year === '') return null

  if (measurementPeriod === 'Monthly') {
    const month = getMonthFromDuration(startDate, endDate)
    if (!month || month === '') return null
    return `${moment(`${month}`, 'MM').format('MMM')} ${year}`
  }

  if (measurementPeriod === 'Quarterly') {
    const quarter = getQuarterFromDuration(startDate, endDate)
    if (!quarter || quarter === '') return null
    return `Quarter ${quarter}, ${year}`
  }

  if (measurementPeriod === 'Semi-annually') {
    const half = getHalfFromDuration(startDate, endDate)
    if (!half || half === '') return null
    return `${half}, ${year}`
  }

  if (measurementPeriod === 'Annually') {
    return `${year}`
  }

  return null
}

export const getMetricPercentageBySpecificProgress = (metric: any, progress: any) => {
  const baseline =
    metric?.baseline && metric?.baseline?.startPosition && Number(metric?.baseline?.startPosition)
      ? Number(metric?.baseline?.startPosition)
      : 0

  const total =
    metric?.baseline && metric?.baseline?.target && Number(metric?.baseline?.target)
      ? Number(metric?.baseline?.target)
      : 0

  const current = Number(progress.currentPosition) ? Number(progress.currentPosition) : 0

  if (total > 0 && total - baseline !== 0) {
    const completed = ((current - baseline) / (total - baseline)) * 100
    if (completed > 100) return 100
    if (completed < 0) return 0
    return Number(completed.toFixed(1))
  }

  return 0
}

export const getMetricPercentageCompleted = (metric: any) => {
  const baseline =
    metric?.baseline && metric?.baseline?.startPosition && Number(metric?.baseline?.startPosition)
      ? Number(metric?.baseline?.startPosition)
      : 0

  const total =
    metric?.baseline && metric?.baseline?.target && Number(metric?.baseline?.target)
      ? Number(metric?.baseline?.target)
      : 0

  const current =
    metric.progress && metric.progress.length > 0 && Number(metric.progress[0]?.currentPosition)
      ? Number(metric.progress[0]?.currentPosition)
      : 0

  if (total > 0 && total - baseline !== 0) {
    const completed = ((current - baseline) / (total - baseline)) * 100
    if (completed > 100) return 100
    if (completed < 0) return 0
    return Number(completed.toFixed(1))
  }

  return 0

  // return total > 0 ? Number((((current - baseline) / (total - baseline)) * 100).toFixed(1)) : 0
}

export const getAnswerByQuestionTag = (questions: any, tag: any) => {
  const {answer} = questions.find((q: any) => q.tag === tag) || {}
  return isArray(answer) ? answer : answer?.value || ''
}

export const getAnswerByQuestion = (questions: any, question: any, type: any = '') => {
  const {answer} = questions.find((q: any) => q.question === question) || {}

  if (Array.isArray(answer)) return answer
  if (type.toLowerCase() === 'Array') return answer?.value ? [answer] : []

  return answer?.value || ''
}

export const getMeasurementChannels = (questions: any) => {
  const {answer} =
    questions.find(
      (question: any) =>
        question.question ===
        'How did you assess the impact contribution of your operations relative to what would have prevailed anyway (without your investment to align to the goal)?'
    ) || {}

  return parseJsonString(answer?.value) || []
}

export const getOutcomePeriod = (questions: any) => {
  const {
    answer: {value = ''},
  } = questions.find((question: any) => question.question === 'Period') || {}

  const [startDate, endDate] = (value || '').split(' - ')
  if (!startDate && !endDate) return undefined

  return {
    label: value,
    startDate: moment(startDate, 'DD/MM/YYYY').format(),
    endDate: moment(endDate, 'DD/MM/YYYY').format(),
  }
}

export const getCompanyPortfolioFund = (questions: any, index: any) => {
  const {answer} =
    questions.find(
      (q: any) => q.question === 'How much funding do you want to allocate to each company?'
    ) || {}

  const arr = parseJsonString(answer?.value) || []
  const fund = arr.length > index ? Number(arr[index]) : 0

  return fund || 0
}

export const getProgressOutcomeDescription = (questions: any) => {
  const question =
    questions.find(
      (question: any) =>
        question.question === 'Describe the outcome of your impact contribution to date'
    ) || {}
  return question?.answer?.value
}

export const getDefaultPosition = (questions: any) => {
  const question =
    questions.find(
      (question: any) => question.question === 'What outcome would have been observed anyway?'
    ) || {}
  return Number(question?.answer?.value) || 0
}

export const getCurrentPosition = (questions: any) => {
  const question =
    questions.find(
      (question: any) => question.question === 'What was the outcome of the period?'
    ) || {}
  return Number(question?.answer?.value) || 0
}

export const getBaselineTarget = (questions: any) => {
  const question =
    questions.find(
      (question: any) =>
        question.question ===
        'What is the target (expected quantitative value) of the impact assessment metric to attain the goal?'
    ) || {}
  return Number(question?.answer?.value) || 0
}

export const getBaselineOutcomeDescription = (questions: any) => {
  const question =
    questions.find(
      (question: any) =>
        question.question ===
        "Describe the level of the outcome of the impact assessment metric before the intervention (your organisation's investment in the strategic goal)?"
    ) || {}
  return question?.answer?.value
}

export const getBaselineStartPosition = (questions: any) => {
  const question =
    questions.find(
      (question: any) =>
        question.question ===
        "What is the baseline (current quantitative value) of the impact assessment metric before the intervention (your organisation's investment in the strategic goal)?"
    ) || {}
  return question?.answer?.value
}

export const getCompanyName = (questions: any) => {
  const question = questions.find((q: any) => q.question === 'Company Name') || {}
  return question?.answer?.value
}

export const getCompanyEmail = (questions: any) => {
  const question = questions.find((q: any) => q.question === 'Company Email') || {}
  return question?.answer?.value
}

export const getCompanyPhoneNumber = (questions: any) => {
  const question = questions.find((q: any) => q.question === 'Phone Number') || {}
  return question?.answer?.value
}

export const getCompanyLocation = (questions: any, allQuestions: any) => {
  const question = questions.find((q: any) => q.question === 'Location') || {}

  const {table = {}} = allQuestions.find((q: any) => q.id === question.id) || {}

  const names = (table?.table_names && table?.table_names[0]?.table_results) || []
  const {value = ''} = names.find(({id}: any) => id == question?.answer?.value) || {}

  return value
}

export const getCompanyFunding = (questions: any) => {
  const question =
    questions.find(
      (q: any) => q.question === 'How much funding has been invested in your company?'
    ) || {}
  return Number(question?.answer?.value) || 0
}

export const getCompanySector = (questions: any, allQuestions: any) => {
  const question =
    questions.find((q: any) => q.question === 'What is your sector of business?') || {}

  const {table = {}} = allQuestions.find((q: any) => q.id === question.id) || {}

  const names = (table?.table_names && table?.table_names[0]?.table_results) || []
  const {value = ''} = names.find(({id}: any) => id == question?.answer?.value) || {}

  return value
}

export const getCompanySize = (questions: any) => {
  const question =
    questions.find(
      (q: any) => q.question === 'What were the revenues in the last financial year?'
    ) || {}
  return question?.answer?.value || ''
}

export const getImpactFocusLocation = (questions: any, allQuestions: any) => {
  const question = questions.find(
    (q: any) => q.question === 'Where is the impact focus taking place?'
  )

  if (!question) return []

  if (!question?.answer?.value || question?.answer?.value == '') return []

  const ids = Array.isArray(parseJsonString(question?.answer?.value))
    ? parseJsonString(question?.answer?.value) || []
    : [question?.answer?.value]

  const {table = {}} =
    allQuestions.find((q: any) => q.question === 'Where is the impact focus taking place?') || {}

  const names = (table?.table_names && (table?.table_names[0] || {})?.table_results) || []
  const locations = names.filter(({id}: any) => ids.includes(id))

  return locations.map(({value}: any) => value)
}

export const getDescription = (questions: any) => {
  const question =
    questions.find(
      (q: any) => q.question === "Provide a brief description for your company's impact focus"
    ) || {}
  return question?.answer?.value
}

export const getImpactFocusName = (questions: any) => {
  const question = questions.find(
    (q: any) => q.question === "Provide a name for your company's impact focus"
  )
  return question?.answer?.value
}

export const getStakeHolders = (questions: any) => {
  const {answer: directAnswer} =
    questions.find(
      (q: any) => q.question === 'What demographics best describe your direct target stakeholders?'
    ) || {}

  const direct = parseJsonString(directAnswer?.value) || []

  const {answer: directValuesAnswer} =
    questions.find(
      (q: any) =>
        q.question ===
        'How many target stakeholders can directly experience the outcome through investments aligned in your impact focus?'
    ) || {}

  const directValues = parseJsonString(directValuesAnswer?.value) || []

  const {answer: inDirectAnswer} =
    questions.find(
      (q: any) =>
        q.question === 'What demographics best describe your indirect target stakeholders?'
    ) || {}

  const inDirect = parseJsonString(directAnswer?.value) || []

  const {answer: inDirectValuesAnswer} =
    questions.find(
      (q: any) =>
        q.question ===
        'How many target stakeholders can indirectly experience the outcome through investments in your impact focus?'
    ) || {}

  const inDirectValues = parseJsonString(inDirectValuesAnswer?.value) || []

  return {
    direct: direct.map((d: any, index: any) => ({
      name: d,
      value: Number(directValues[index] || 0) || 0,
    })),
    inDirect: inDirect.map((d: any, index: any) => ({
      name: d,
      value: Number(inDirectValues[index] || 0) || 0,
    })),
  }
}

export const getImpactFunding = (questions: any) => {
  const {answer} =
    questions.find(
      (q: any) => q.question === 'How much funding is allocated to the impact focus?'
    ) || {}

  return Number(answer?.value || 0) || 0
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export const getImpactCategory = (questions: any, allQuestions: any = []) => {
  const question =
    questions.find(
      (q: any) => q.question === 'Which Impact Category best describes your area of focus?'
    ) || {}

  const {table} = allQuestions.find(({id}: any) => id === question.id) || {}
  const [table_name] = table?.table_names || []
  const {table_results = []} = table_name || {}

  const {value} = question?.answer

  const answer = table_results.find(({id}: any) => id == value)
  //console.log(value)

  return answer?.value || ''
}

export const getImpactRisks = (questions: any, allQuestions: any = []) => {
  // Get Risks Types
  const {answer: riskTypeAnswer} =
    questions.find(
      (q: any) =>
        q.question ===
        'What type of risks do you anticipate can undermine the delivery of the expected outcomes of the impact focus?'
    ) || {}

  const riskTypes = parseJsonString(riskTypeAnswer?.value) || []

  //Get Liklihood
  const {answer: riskLikelihoodAnswer} =
    questions.find(
      (q: any) => q.question === 'What is the likelihood of each risk type materializing?'
    ) || {}

  const riskLikelihood = parseJsonString(riskLikelihoodAnswer?.value) || []

  const {answer: riskSeverityAnswer} =
    questions.find(
      (q: any) =>
        q.question ===
        'What is the severity of the consequence of each risk type on people and planet, if it does arise?'
    ) || {}

  const riskSeverity = parseJsonString(riskSeverityAnswer?.value) || []

  const risks = riskTypes.map((risk: any, index: any) => ({
    type: riskTypes[index],
    likelihood: riskLikelihood.length > index ? riskLikelihood[index] : '',
    severity: riskSeverity.length > index ? riskSeverity[index] : '',
    level: getRiskLevel(riskLikelihood[index], riskSeverity[index]),
  }))

  return risks
}

const getRiskLevel = (likelihood: any, severity: any) => {
  const severities = ['Very Minimal', 'Minimal', 'Severe', 'Very Severe']
  const likelihoods = ['Very Unlikely', 'Unlikely', 'Likely', 'Very Likely']

  const levels = ['Not Risky', 'Minimal Risk', 'Risky', 'Very Risky']

  const likelihoodValue =
    likelihoods.indexOf(likelihood) !== -1 ? likelihoods.indexOf(likelihood) : levels.length
  const severityValue =
    severities.indexOf(severity) !== -1 ? severities.indexOf(severity) : levels.length
  const level = Math.floor((likelihoodValue + severityValue) / 2)

  return levels[level]
}

export const getImpactDescription = (questions: any) => {
  const question = questions.find(
    (q: any) => q.question === "Provide a brief description for your company's impact focus"
  )
  return question?.answer?.value
}

export const getMeasurementPeriod = (questions: any) => {
  const question = questions.find(
    (q: any) =>
      q.question === 'How often do you want to track progress with the impact assessment metrics?'
  )
  return question?.answer?.value
}

export const getExpectedDuration = (questions: any) => {
  const question = questions.find(
    (q: any) =>
      q.question ===
      'What is the estimated time period for which stakeholders shall experience the outcome if your enterprise does not invest in the impact focus?'
  )
  return question?.answer?.value
}

export const getGoals = (questions: any, goalsQuestion: any) => {
  const question = questions.find(
    (q: any) => q.question === 'Which Strategic Goal(s) best matches your approach?'
  )
  // const goals = Array.isArray(question?.answer)
  //   ? question?.answer
  //   : question?.answer?.value === '' || question?.answer?.value === null
  //   ? []
  //   : [question?.answer]

  const {value} = Array.isArray(question.answer) ? question.answer[0] : question.answer

  let goals = parseJsonString(value) || []
  goals = Array.isArray(goals) ? goals : [goals]

  return goals.reduce((acc: any, goal: any, index: any) => {
    acc[index] = getGoalDetails(goal, goalsQuestion)
    return acc
  }, goals)
}

export const getGoalDetails = (goal: any, goalsQuestion: any) => {
  const names =
    goalsQuestion && goalsQuestion.table && goalsQuestion.table.table_names
      ? goalsQuestion.table.table_names[0].table_results
      : []

  const {value: name} = names.find(({id}: any) => id === goal) || {}

  return {...goal, name}
}

export const getMetrics = (questions: any, metricQuestions: any) => {
  const answeredQuestions = questions.filter(({tag}: any) => metricTags.includes(tag))

  let metrics: any = []

  answeredQuestions.forEach((question: any) => {
    const {value} = Array.isArray(question.answer) ? question.answer[0] : question.answer
    let temp = parseJsonString(value) || []

    temp = Array.isArray(temp) ? temp : [temp]

    metrics = [
      ...metrics,
      ...temp.map((t: any) => ({value: t, question_id: question.id, tags: [question.tag]})),
    ]
  })

  metrics = metrics.reduce((acc: any, metric: any, index: any) => {
    acc[index] = getMetricDetails(
      metric,
      metricQuestions.find(({id}: any) => id === metric.question_id)
    )
    return acc
  }, metrics)

  // //Remove duplicated metric name

  metrics = metrics.reduce((acc: any, metric: any, index: any) => {
    const temp = acc.find(({name}: any) => name === metric.name)
    return temp
      ? acc.map((a: any) => (temp.name === a.name ? {...a, tags: [...a.tags, ...metric.tags]} : a))
      : [...acc, metric]
  }, [])

  return metrics.filter((metric: any) => metric?.value)
}

export const getMetricDetails = (metric: any, metricQuestion: any) => {
  //console.log(metricQuestion.entity_source.table_names[0].table_results)
  const names = metricQuestion ? metricQuestion.table.table_names[0].table_results : []
  const descriptions = metricQuestion ? metricQuestion.table.table_names[3].table_results : []
  const footnotes = metricQuestion ? metricQuestion.table.table_names[2].table_results : []

  const {value: name} = names.find(({id}: any) => id == metric.value) || {}
  const {value: description} =
    descriptions.find(({parent_entity_value}: any) => parent_entity_value == metric.value) || {}
  const {value: footnote} =
    footnotes.find(({parent_entity_value}: any) => parent_entity_value == metric.value) || {}
  //console.log(name)
  //console.log(footnotes)
  //console.log(description)
  return {...metric, name, description, footnote, progress: [], baseline: {}}
}

export const getPercentageCompleted = (questions: any) => {
  const unAnsweredQuestions = questions.filter(
    ({answer, tag}: any) =>
      (answer?.value === null || answer?.value === '') &&
      tag !== 'risk-metrics' &&
      tag !== 'contribution-metrics'
  )

  return Math.floor(((questions.length - unAnsweredQuestions.length) * 100) / questions.length)
}

export const extractSDGSByImpact = (arr = []) => {
  return arr.map((i: any) => ({
    ...i,
    sdgs: i.metrics
      .reduce(
        (acc: any, metric: any) => [...(new Set([...acc, ...metric.development_goals]) as any)],
        []
      )
      .filter((value: any) => value),
  }))
}

export const getRiskColor = (type = '') => {
  if (type.toLowerCase() === 'very risky') return '#D32435'
  if (type.toLowerCase() === 'risky') return '#F68121'
  if (type.toLowerCase() === 'minimal risk') return '#F6CD0F'
  if (type.toLowerCase() === 'not risky') return '#319847'
  return '#319847'
}

export const extractData = (impactList = [], response: any) => {
  const {data = []} = response.data
  const [obj = {}] = data

  return impactList.map((impact: any) => ({
    ...impact,
    metrics: impact.metrics.map((metric: any) => ({
      ...metric,
      baseline: getBaseline(obj[`${impact.group_identifier}-${metric.value}-baseline`]),
      progress:
        getProgress(impact, obj[`${impact.group_identifier}-${metric.value}-progress`]) || [],
    })),
  }))
}

export const getDevelopmentGoals = (arr: any = []) =>
  arr.map((i: any) => sdgs.find(({label}: any) => label === i)?.value)

export const getTotalGoals = (arr = []) =>
  arr.reduce((acc: any, impact: any) => acc + impact.goals.length, 0)

export const getTotalMetrics = (arr = []) =>
  arr.reduce((acc: any, impact: any) => acc + impact.metrics.length, 0)

export const getPortfolioName = (questions: any) => {
  const question =
    questions.find((q: any) => q.question === 'What is the name of your Portfolio?') || {}
  return question?.answer?.value
}

export const getPortfolioFunding = (questions: any) => {
  const question =
    questions.find((q: any) => q.question === 'How much funding is allocated to the portfolio?') ||
    {}
  return question?.answer?.value
}

export const getPortfolioIdentifier = ([question]: any) => {
  return question?.group_identifier
}

export const getPortfolioDescription = (questions: any) => {
  const question =
    questions.find(
      (q: any) => q.question === "Provide a brief description for your company's impact focus"
    ) || {}
  return question?.answer?.value
}

export const alphaHex = () => {
  const data = []
  for (var i = 0; i <= 1; i += 0.01) {
    i = Math.round(i * 100) / 100
    var alpha = Math.round(i * 255)
    var hex = (alpha + 0x10000).toString(16).substr(-2).toUpperCase()
    data.push(hex)
  }
  return data
}

export const parseJsonString = (jsonString: any) => {
  try {
    const json = JSON.parse(jsonString)
    return json
  } catch (e) {}
}

export const getCompanyList = (portfolios: any) => {
  return portfolios.reduce((acc: any, portfolio: any) => [...acc, ...portfolio.companies], [])
}

export const getMetricsList = (portfolios: any) => {
  return portfolios.reduce((acc: any, portfolio: any) => {
    portfolio.companies.forEach((company: any) => {
      company.impacts.forEach((impact: any) => {
        impact.metrics.forEach((metric: any) => {
          acc.push(metric)
        })
      })
    })
    return acc
  }, [])
}

export const getDurationByYear = (year: any) => {
  if (year > 1970) return `01/01/${year} - 31/12/${year}`
}

export const getDurationByQuarter = (year: any, quarter: any) => {
  if (Number(year) <= 1970) return ''
  if (quarter === '' || quarter < 1 || quarter > 4) return ''

  const month = Number(quarter) * 3 - 2
  const startDate = moment(`1/${month}/${year}`, 'D/M/YYYY').format('DD/MM/YYYY')
  const endDate = moment(startDate, 'DD/MM/YYYY').endOf('quarter').format('DD/MM/YYYY')

  return `${startDate} - ${endDate}`
}

export const getDurationByMonth = (year: any, month: any) => {
  var startDate = moment([year, month - 1])
  var endDate = moment(startDate).endOf('month')

  return `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`
}

export const getYearFromDuration: any = (startDate: any, endDate: any) => {
  const isValidStartDate =
    startDate === moment(startDate, 'DD/MM/YYYY').startOf('year').format('DD/MM/YYYY')

  const isValidEndDate =
    endDate === moment(startDate, 'DD/MM/YYYY').endOf('year').format('DD/MM/YYYY')

  return moment(startDate, 'DD/MM/YYYY') ? moment(startDate, 'DD/MM/YYYY').year() : ''
}

export const getMonthFromDuration: any = (startDate: any, endDate: any) => {
  const isValidStartDate =
    startDate === moment(startDate, 'DD/MM/YYYY').startOf('month').format('DD/MM/YYYY')

  const isValidEndDate =
    endDate === moment(startDate, 'DD/MM/YYYY').endOf('month').format('DD/MM/YYYY')

  return isValidStartDate && isValidEndDate ? moment(startDate, 'DD/MM/YYYY').month() + 1 : ''
}

export const getQuarterFromDuration: any = (startDate: any, endDate: any) => {
  const isValidStartDate =
    startDate === moment(startDate, 'DD/MM/YYYY').startOf('quarter').format('DD/MM/YYYY')

  const isValidEndDate =
    endDate === moment(startDate, 'DD/MM/YYYY').endOf('quarter').format('DD/MM/YYYY')

  if (!(isValidStartDate && isValidEndDate)) return ''

  const quarter = Math.floor((moment(startDate, 'DD/MM/YYYY').month() + 3) / 3)

  return quarter
}

export const getHalfFromDuration: any = (startDate: any, endDate: any) => {
  const isValidStartDate =
    startDate === moment(startDate, 'DD/MM/YYYY').startOf('quarter').format('DD/MM/YYYY')

  const isValidEndDate =
    endDate === moment(startDate, 'DD/MM/YYYY').endOf('quarter').format('DD/MM/YYYY')

  if (!(isValidStartDate && isValidEndDate)) return ''

  const quarter = Math.floor((moment(startDate, 'DD/MM/YYYY').month() + 3) / 3)

  return quarter
}
