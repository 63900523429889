// import clsx from 'clsx'
// import {format} from 'path'
import {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
// import {useToasts} from 'react-toast-notifications'
import {approveCompanyAccess, denyCompanyAccess, getPortfoliosByCompany} from '../../../../api'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
// import {AuthContext} from '../../../context/AuthContext'
import {CompanyContext} from '../../../context/CompanyContext'
import {
  getCompanyName,
  getPortfolioFunding,
  getPortfolioIdentifier,
  getPortfolioName,
  maybePluralize,
} from '../../shared/Helpers'

const FundManagersList = () => {
  const [list, setList] = useState<any>([])
  const [isLoading, setLoading] = useState(true)
  const {company} = useContext(CompanyContext)

  useEffect(() => {
    getPortfoliosByCompany(company.group_identifier)
      .then(({data = []}) => {
        setList(formatList(data))
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const formatList = (data = []) =>
    data.map((d: any) => ({
      ...d,
      portfolio_name: getPortfolioName(d.portfolio),
      funding: getPortfolioFunding(d.portfolio),
    }))

  return (
    <>
      <PageTitle>Fund Managers</PageTitle>
      <div className='col-12 mb-10'>
        <div className='alert alert-dismissible bg-info d-flex flex-column flex-sm-row ps-6 pt-9 pe-15 m-0 pb-9'>
          <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative me-5'>
            <KTSVG
              path='/media/icons/duotone/Layout/Layout-polygon.svg'
              className='svg-icon-white position-absolute opacity-30'
              svgClassName='h-80px w-80px'
            />
            <KTSVG
              path='/media/icons/duotone/General/User.svg'
              className='svg-icon-3x svg-icon-info position-absolute'
            />
          </div>

          <div className='d-flex flex-column text-light pe-0'>
            <h3 className='mb-2 text-light'>Fund Managers & Portfolios</h3>
            <span>
              This list active and pending portfolios and fund mangers your company belongs to. You
              can approve or deny a request from a fund manager to be part of their portfolio.
            </span>
          </div>
        </div>
      </div>

      {!!list.filter(({status}: any) => status === 'Pending').length && (
        <div className='card card-custom mb-10'>
          <div className='card-body p-0 pt-2'>
            <div className='p-10 pb-0'>
              <h3>Pending Invitations</h3>
              <p className='fs-7 text-gray-600 fw-bold'>
                This is a list pending portfolio requests from fund managers. You can approve or
                deny a request from a fund manager to be part of their portfolio.
              </p>
            </div>
            <div className='table-responsive '>
              {/* begin::Table */}

              <table className='table table-row-bordered table-row-gray-200 align-middle gs-10 mb-0 pb-0'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-150px pt-5 pb-5'>Fund Manager</th>
                    <th className='min-w-100px pt-5 pb-5'>Portfolio</th>
                    <th className='min-w-150px pt-5 pb-5'>Status</th>
                    <th className='min-w-150px pt-5 pb-5'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {list
                    .filter(({status}: any) => status === 'Pending')
                    .map((portfolio: any, index: any) => (
                      <tr key={index}>
                        <td className='p-5 px-10'>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bolder fs-7 mb-1'>
                                {portfolio.company_info.company_name}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td className='p-5'>
                          <span className='text-gray-500 fw-bolder fs-8'>
                            {portfolio.portfolio_name}
                          </span>
                        </td>

                        <td className='p-5'>
                          <span className='text-gray-500 fw-bolder fs-8'>{portfolio.status}</span>
                        </td>
                        <td className='p-5'>
                          <Link
                            to={`/fund-managers/portfolio/${portfolio.portfolio_identifier}`}
                            className='fw-bold fs-7 me-4'
                          >
                            Respond To Invitation
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </div>
        </div>
      )}

      <div className='card card-custom'>
        <div className='card-body p-0 pt-2'>
          <div className='p-10 pb-0'>
            <h3>Active Portfolios</h3>
            <p className='fs-7 text-gray-600 fw-bold'>
              This list active portfolios and fund mangers your company belongs to. You can remove
              your company from a fund managers portfolio.
            </p>
          </div>
          <div className='table-responsive '>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-200 align-middle gs-10 mb-0 pb-0'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-150px pt-5 pb-5'>Fund Manager</th>
                  <th className='min-w-100px pt-5 pb-5'>Portfolio Name</th>
                  <th className='min-w-100px pt-5 pb-5'>Allocated Funds</th>
                  <th className='min-w-150px pt-5 pb-5'>Status</th>
                  <th className='min-w-150px pt-5 pb-5'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {list
                  .filter(({status}: any) => status === 'Active')
                  .map((portfolio: any, index: any) => (
                    <tr key={index}>
                      <td className='p-5 px-10'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fw-bolder fs-7 mb-1'>
                              {portfolio.company_info.company_name}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='p-5'>
                        <span className='text-gray-500 fw-bolder fs-8'>
                          {portfolio.portfolio_name}
                        </span>
                      </td>
                      <td className='p-5'>
                        <span className='text-gray-500 fw-bolder fs-8'>
                          USD {portfolio.funding}
                        </span>
                      </td>

                      <td className='p-5'>
                        <span className='text-gray-500 fw-bolder fs-8'>{portfolio.status}</span>
                      </td>
                      <td className='p-5'>
                        <Link
                          to={`/fund-managers/portfolio/${portfolio.portfolio_identifier}`}
                          className='fw-bold fs-7 me-4'
                        >
                          Preview
                        </Link>
                        {/* <Link to={''} className='fw-bold fs-7'>
                          Leave Portfolio
                        </Link> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default FundManagersList
