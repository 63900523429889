import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'
import {useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'

const SaveToImageButton = ({node}: any) => {
  const [saving, setSaving] = useState(false)
  const [showButton, setShowButton] = useState(true)

  const onSave = () => {
    setSaving(true)
    setTimeout(() => {
      setShowButton(false)
      htmlToImage.toPng(document.getElementById(node) as HTMLElement).then(function (dataUrl) {
        download(dataUrl, `${node}.png`)
        setSaving(false)
        setShowButton(true)
      })
    }, 2000)
  }

  return (
    <div className='save-to-image position-absolute top-0 end-0'>
      {showButton && (
        <button className='btn btn-primary btn-sm m-5 px-4 ps-3 py-2' onClick={onSave}>
          <KTSVG
            path='/media/icons/duotone/Navigation/Arrow-to-bottom.svg'
            className='svg-icon-white px-0 mx-0 me-2'
          />
          {saving ? 'Saving...' : 'Save Chart'}
        </button>
      )}
    </div>
  )
}

export default SaveToImageButton
