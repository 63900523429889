export const metricTags = [
  'summary-metrics',
  'what-metrics',
  'who-metrics',
  'how-much-metrics',
  'contribution-metrics',
  'risk-metrics',
]

export const inDirectStakeholdersQuestion =
  'What demographics best describe your indirect target stakeholders?'
export const directStakeholdersQuestion =
  'What demographics best describe your direct target stakeholders?'

export const inDirectHowManyQuestion =
  'How many target stakeholders can indirectly experience the outcome through investments in your impact focus?'
export const directHowManyQuestion =
  'How many target stakeholders can directly experience the outcome through investments aligned in your impact focus?'

export const MeasurementChannels =
  'How did you assess the impact contribution of your operations relative to what would have prevailed anyway (without your investment to align to the goal)?'
export const OutcomePeriod = 'Period'
export const ProgressOutcomeDescription = 'Describe the outcome of your impact contribution to date'
export const DefaultPosition = 'What outcome would have been observed anyway?'
export const CurrentPosition = 'What was the outcome of the period?'
export const BaselineTarget =
  'What is the target (expected quantitative value) of the impact assessment metric to attain the goal?'
export const BaselineOutcomeDescription =
  "Describe the level of the outcome of the impact assessment metric before the intervention (your organisation's investment in the strategic goal)?"
export const BaselineStartPosition =
  "What is the baseline (current quantitative value) of the impact assessment metric before the intervention (your organisation's investment in the strategic goal)?"
export const CompanyName = 'Company Name'
export const Description = "Provide a brief description for your company's impact focus"
export const ImpactFocusName = "Provide a name for your company's impact focus"
export const Goals = 'Which Strategic Goal(s) best matches your approach?'
