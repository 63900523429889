/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {CompanyContext} from '../../context/CompanyContext'
//import CompanySelector from './components/CompanySelector'
import GetStarted from './components/GetStarted'

const DashboardPage: FC = () => {
  const {companies} = useContext(CompanyContext)

  return (
    <>
      {!companies.length && <GetStarted />}
      {/* {companies.length > 1 && <CompanySelector />} */}
      {/* begin::Row */}
      <div className='row g-xl-8'>
        <div className='col-12'>
          <div
            className='card h-300px bgi-no-repeat bgi-size-cover bgi-position-center'
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/dashboard.jpg')}')`,
            }}
          ></div>
        </div>

        <div className='col-6'>
          <div className='card'>
            <div className='card-body d-flex align-items-center py-8'>
              <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
                <KTSVG
                  path='/media/icons/duotone/Layout/Layout-polygon.svg'
                  className='svg-icon-primary position-absolute opacity-15'
                  svgClassName='h-80px w-80px'
                />
                <KTSVG
                  path='/media/icons/duotone/Home/Building.svg'
                  className='svg-icon-3x svg-icon-primary position-absolute'
                />
              </div>
              <div className='ms-6  cursor-pointer'>
                <Link to='/company'>
                  <h3>Company</h3>
                  <p className='list-unstyled text-gray-600 fw-bold fs-7 p-0 m-0'>
                    Create and update your company profile information and data privacy preferences.
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='col-6'>
          <div className='card'>
            <div className='card-body d-flex align-items-center py-8'>
              <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
                <KTSVG
                  path='/media/icons/duotone/Layout/Layout-polygon.svg'
                  className='svg-icon-primary position-absolute opacity-15'
                  svgClassName='h-80px w-80px'
                />
                <KTSVG
                  path='/media/icons/duotone/Design/Join-1.svg'
                  className='svg-icon-3x svg-icon-primary position-absolute'
                />
              </div>
              <div className='ms-6  cursor-pointer'>
                <Link to='/company'>
                  <h3>Impact Management</h3>
                  <p className='list-unstyled text-gray-600 fw-bold fs-7 p-0 m-0'>
                    Set up impact measurement and management frameworks using globally accepted
                    standards.
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='col-6'>
          <div className='card'>
            <div className='card-body d-flex align-items-center py-8'>
              <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
                <KTSVG
                  path='/media/icons/duotone/Layout/Layout-polygon.svg'
                  className='svg-icon-primary position-absolute opacity-15'
                  svgClassName='h-80px w-80px'
                />
                <KTSVG
                  path='/media/icons/duotone/Home/Library.svg'
                  className='svg-icon-3x svg-icon-primary position-absolute'
                />
              </div>
              <div className='ms-6 cursor-pointer'>
                <Link to='#'>
                  <h3>Resources</h3>
                  <p className='list-unstyled text-gray-600 fw-bold fs-7 p-0 m-0'>
                    Access learning resources on impact investment, IMM, and business
                    sustainability.
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='col-6'>
          <div className='card'>
            <div className='card-body d-flex align-items-center py-8'>
              <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>
                <KTSVG
                  path='/media/icons/duotone/Layout/Layout-polygon.svg'
                  className='svg-icon-primary position-absolute opacity-15'
                  svgClassName='h-80px w-80px'
                />
                <KTSVG
                  path='/media/icons/duotone/Design/Cap-2.svg'
                  className='svg-icon-3x svg-icon-primary position-absolute'
                />
              </div>
              <div className='ms-6 cursor-pointer'>
                <a
                  href='http://develop-social-impact-management.makedudev.com/storage/files/fScSGL7Shj7Ri62MEqoVBgWxTSGVCYCRgJg4TfNF.pdf'
                  target='blank'
                >
                  <h3>IMM Framework Guide</h3>
                  <p className='list-unstyled text-gray-600 fw-bold fs-7 p-0 m-0'>
                    This guide aids organizations in understanding, developing and managing their
                    roadmaps.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const userString = localStorage.getItem('user')
  const user = JSON.parse(userString as string)

  return (
    <>
      <PageTitle>{`Welcome, ${user.fullname}`}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
