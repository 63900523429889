/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext} from 'react'
import {Link, useParams} from 'react-router-dom'
// import {PageTitle} from '../../../../../_metronic/layout/core'
// import LoadingSpinner from '../../../shared/LoadingSpinner'
import CurrentMetric from '../../../shared/CurrentMetric'
import {Section} from '../../../shared/Section'
import {QuestionContext} from '../../../../context/QuestionContext'
import {DashboardContext} from '../../../../context/DashboardContext'
import {AuthContext} from '../../../../context/AuthContext'

const initialTabs = [
  'company-impact-focus',
  'dimension-what',
  'dimension-who',
  'dimension-how-much',
  'dimension-contribution',
  'dimension-risk',
]

const CompanyImpactFocusDetails = () => {
  const {impactList} = useContext(DashboardContext)
  const {id, group_identifier, impact_focus_id}: any = useParams()
  const impact = impactList.find((i: any) => i.group_identifier === impact_focus_id)

  const {user, isAdmin} = useContext(AuthContext)
  const {portfolio_id} = useParams<any>()
  const prefix = user.type === 'Investor' ? `/portfolio/${portfolio_id}` : '/admin'

  const {sections} = useContext(QuestionContext)

  const tabs = sections
    .filter(({tag}: any) => initialTabs.includes(tag))
    .map((s: any) => ({
      ...s,
      questions: s.questions.map((question: any) => ({
        ...question,
        answer: (impact.questions.find((q: any) => q.id === question.id) || {}).answer,
      })),
    }))

  return (
    <>
      <div className='row'>
        <div className='col-12 mb-15'>
          <div className='card p-0'>
            <div className='card-body p-0 pt-2'>
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-bordered align-middle gy-5 gs-7 '>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder text-dark'>
                      <th className='col-6'>
                        Metric Name <br />
                      </th>
                      <th className='col-2'>Target</th>
                      <th className='col-2'>Current</th>
                      <th className='col-2'>Actions</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {impact.metrics.map((metric: any, index: any) => (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <Link
                                to={{
                                  pathname: `/company/${group_identifier}/impact-focus/${impact.group_identifier}/metrics/${metric.value}`,
                                  state: {impact, metric},
                                }}
                                className='text-dark fw-bolder text-hover-primary fs-7 mb-1'
                              >
                                {metric.name}
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td>
                          {!metric.baseline && '-'}

                          {metric.baseline && (
                            <span className='fw-bolder text-gray-600 fs-7'>
                              {metric?.baseline.target} {metric?.baseline.unit}
                            </span>
                          )}
                        </td>
                        <td>
                          <CurrentMetric
                            target={metric?.baseline?.target || 0}
                            baseline={metric?.baseline?.startPosition || 0}
                            unit={metric?.baseline?.unit || ''}
                            currentPosition={
                              metric?.progress && metric?.progress.length > 0
                                ? metric?.progress[0]?.currentPosition
                                : 0
                            }
                          />
                        </td>
                        <td>
                          {!!metric.progress.length && (
                            <Link
                              to={{
                                pathname: `/company/${group_identifier}/impact-focus/${impact.group_identifier}/metrics/${metric.value}`,
                              }}
                              className='fw-bold  fs-9'
                            >
                              History
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
            </div>
          </div>
        </div>

        {tabs.map((section: any, index: any) => (
          <div className='col-12'>
            <Section
              showCompletedStatus={true}
              key={section.title}
              heading={section.title}
              questions={section.questions}
              collapsed={false}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export {CompanyImpactFocusDetails}
