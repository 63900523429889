import {useContext, useEffect, useState} from 'react'
// import {Link, useLocation, useParams} from 'react-router-dom'
// import {useToasts} from 'react-toast-notifications'
// import {deleteResource, getAnswersByIdentifier} from '../../../../../api'
// import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
// import {PageTitle} from '../../../../../_metronic/layout/core'
import {CompanyContext} from '../../../../context/CompanyContext'
import {QuestionContext} from '../../../../context/QuestionContext'
import {ProfileHeader} from '../../../company/components/ProfileHeader'
// import {parseJsonString} from '../../../shared/Helpers'
// import LoadingSpinner from '../../../shared/LoadingSpinner'
import {Section} from '../../../shared/Section'

const tags = [
  'company-sdg',
  'company-info',
  'company-profile',
  'governance-section-investees',
  'transparency-section-investees',
  'data-privacy',
]

const CompanyDetails = () => {
  const {sections} = useContext(QuestionContext)

  const {company = {}} = useContext(CompanyContext)

  const [selectedSections = [], setSelectedSections] = useState(
    sections.filter(({tag}: any) => tags.includes(tag))
  )

  const getSections = (answeredQuestions: any = []) =>
    selectedSections.map((section: any) => ({
      ...section,
      questions: section.questions.map((question: any) => ({
        ...question,
        answer: answeredQuestions.find((q: any) => q.id === question.id)?.answer || {},
      })),
    }))

  useEffect(() => {
    setSelectedSections(getSections(company.questions))
  }, [])

  return (
    <>
      <>
        <ProfileHeader company={company} />
        {selectedSections.map((section: any, index: any) => (
          <Section heading={section.title} questions={section.questions} collapsed={false} />
        ))}
      </>
    </>
  )
}

export default CompanyDetails
