import {useContext, useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
import ReactToPrint from 'react-to-print'
import PrintComponent from '../../shared/PrintComponent'
import {currencyFormatter} from '../../shared/Helpers'
import {sdgs} from '../../shared/Constants'
import PieChart from '../../shared/PieChart'
import {AuthContext} from '../../../context/AuthContext'
import {PortfolioContext} from '../../../context/PortfolioContext'
import ReportHeader from './ReportHeader'
import SaveToImageButton from '../../shared/SaveToImageButton'

const ImpactObjectivesReport = () => {
  const componentRef = useRef<any>()
  const {sdgPerformance, impactList = []} = useContext(DashboardContext)

  const {company = {}} = useContext(CompanyContext)
  const {user, isAdmin} = useContext(AuthContext)
  const {portfolios = []} = useContext(PortfolioContext)

  const [selectedCompany, setSelectedCompany] = useState<any>()
  const [selectedPortfolio, setSelectedPortfolio] = useState<any>()

  const [state, setState] = useState({
    reportType: 'my-company',
    portfolio: '',
    company: '',
  })

  const handleChange = (evt: any) => {
    const value = evt.target.value
    setState({
      ...state,
      [evt.target.name]: value,
    })
  }

  const getSelectedCompany = () => {
    if (state.reportType !== 'investee') return company
    const [portfolio_id, company_id] = (state?.company || '').split('-')
    const {companies = []} =
      portfolios.find((portfolio: any) => portfolio.group_identifier === portfolio_id) || {}

    return companies.find((c: any) => c.group_identifier === company_id)
  }

  const getSelectedPortfolio = () => {
    if (state.reportType !== 'portfolio') return
    return portfolios.find((portfolio: any) => portfolio.group_identifier === state?.portfolio)
  }

  const showReport = () => {
    if (state.reportType === 'portfolio' && !selectedPortfolio) return false
    if (!selectedCompany) return false

    return true
  }

  useEffect(() => {
    setSelectedCompany(getSelectedCompany())
    setSelectedPortfolio(getSelectedPortfolio())
  }, [state, selectedPortfolio])

  const getDistributionData = () => {
    if (state.reportType === 'my-company') return getCompanyDistribution()
    if (state.reportType === 'portfolio') return getPortfolioDistribution()
    if (state.reportType === 'investee') return getInvesteeDistribution()
    if (state.reportType === 'cummulative') return getCummulativeDistribution()
    return []
  }

  const getPEData = () => {
    if (state.reportType === 'my-company') return getCompanyPE()
    if (state.reportType === 'portfolio') return getPortfolioPE()
    if (state.reportType === 'investee') return getInvesteePE()
    if (state.reportType === 'cummulative') return getCummulativePE()
    return []
  }

  const getCompanyDistribution = () =>
    sdgs
      .map((sdg: any) => ({
        category: sdg.label,
        color: sdg.color,
        value: impactList.reduce(
          (acc: any, impact: any) => (acc += impact.sdgs.filter((i: any) => i == sdg.value).length),
          0
        ),
      }))
      .filter((item: any) => item.value > 0)

  const getInvesteeDistribution = () =>
    sdgs
      .map((sdg: any) => ({
        category: sdg.label,
        color: sdg.color,
        value: (selectedCompany.impacts || []).reduce(
          (acc: any, impact: any) => (acc += impact.sdgs.filter((i: any) => i == sdg.value).length),
          0
        ),
      }))
      .filter((item: any) => item.value > 0)

  const getCummulativeDistribution = () =>
    sdgs
      .map((sdg: any) => ({
        category: sdg.label,
        color: sdg.color,
        value: portfolios.reduce((acc: any, portfolio: any) => {
          portfolio.companies.forEach((company: any) => {
            company.impacts.forEach((impact: any) => {
              acc += impact.sdgs.filter((i: any) => i == sdg.value).length
            })
          })

          return acc
        }, 0),
      }))
      .filter((item: any) => item.value > 0)

  const getPortfolioDistribution = () =>
    sdgs
      .map((sdg: any) => ({
        category: sdg.label,
        color: sdg.color,
        value: (selectedPortfolio.companies || []).reduce((acc: any, company: any) => {
          company.impacts.forEach((impact: any) => {
            acc += impact.sdgs.filter((i: any) => i == sdg.value).length
          })
          return acc
        }, 0),
      }))
      .filter((item: any) => item.value > 0)

  const getValueByCategory = (category: any) => {
    const arr = sdgPerformance.filter((s: any) => s.category === category)
    return Number((arr.reduce((acc: any, s: any) => acc + s.completed, 0) / arr.length).toFixed(1))
  }

  const getCompanyPE = () => [
    {
      category: 'People',
      value: getValueByCategory('People'),
      color: '#e6194b',
    },
    {
      category: 'Environment',
      value: getValueByCategory('Environment'),
      color: '#3cb44b',
    },
  ]

  const getInvesteePE = () =>
    ['People', 'Environment'].map((item: any) => ({
      category: item,
      value: (selectedCompany.impacts || []).reduce((acc: any, impact: any) => {
        acc += impact.sdgs.filter((i: any) =>
          sdgs
            .filter((sdg: any) => sdg.category === item)
            .map(({value}: any) => value)
            .includes(i)
        ).length

        return acc
      }, 0),
    }))

  const getPortfolioPE = () =>
    ['People', 'Environment'].map((item: any) => ({
      category: item,
      value: (selectedPortfolio.companies || []).reduce((acc: any, company: any) => {
        company.impacts.forEach((impact: any) => {
          acc += impact.sdgs.filter((i: any) =>
            sdgs
              .filter((sdg: any) => sdg.category === item)
              .map(({value}: any) => value)
              .includes(i)
          ).length
        })

        return acc
      }, 0),
    }))

  const getCummulativePE = () =>
    ['People', 'Environment'].map((item: any) => ({
      category: item,
      value: portfolios.reduce((acc: any, portfolio: any) => {
        portfolio.companies.forEach((company: any) => {
          company.impacts.forEach((impact: any) => {
            acc += impact.sdgs.filter((i: any) =>
              sdgs
                .filter((sdg: any) => sdg.category === item)
                .map(({value}: any) => value)
                .includes(i)
            ).length
          })
        })

        return acc
      }, 0),
    }))

  const getImpactList = () => {
    if (state.reportType === 'my-company') return impactList
    if (state.reportType === 'investee') return selectedCompany.impacts || []
    return []
  }

  return (
    <div>
      <ReactToPrint
        trigger={() => <button className='btn btn-dark btn-sm mb-5'>Print Report</button>}
        content={() => componentRef.current}
      />
      {user.type === 'Investor' && (
        <div className='card card-custom bg-dark text-white mb-10 py-10 px-10 pb-7'>
          <div className='mb-5'>
            <span className='fw-bolder'>Generate Report</span>
            <select
              className='form-select mt-1 fs-7 cursor-pointer'
              name='reportType'
              value={state.reportType}
              onChange={handleChange}
            >
              <option value='my-company'>My Company ({company.company_name})</option>
              <option value='portfolio'>Per Portfolio</option>
              <option value='investee'>Per Enterprise</option>
              <option value='cummulative'>Cummulative (All Portfolios)</option>
            </select>
          </div>

          {state.reportType === 'portfolio' && (
            <div className='mb-5'>
              <span className='fw-bolder w-150px mb-2'>Select Portfolio</span>
              <select
                className='form-select fs-7 mt-1 cursor-pointer'
                name='portfolio'
                value={state.portfolio}
                onChange={handleChange}
              >
                <option>Select Portfolio</option>
                {portfolios.map((portfolio: any) => (
                  <option value={portfolio.group_identifier}>{portfolio.name}</option>
                ))}
              </select>
            </div>
          )}
          {state.reportType === 'investee' && (
            <div className='mb-5'>
              <span className='fw-bolder w-150px mb-2'>Select Company</span>
              <select
                className='form-select fs-7 mt-1 cursor-pointer'
                name='company'
                value={state.company}
                onChange={handleChange}
              >
                <option>Select Company</option>
                {portfolios.map((portfolio: any) => (
                  <>
                    {!!portfolio.companies.length && (
                      <optgroup label={portfolio.name} className='mt-10'>
                        {portfolio.companies.map((company: any) => (
                          <option
                            value={`${portfolio.group_identifier}-${company.group_identifier}`}
                          >
                            {company.name}
                          </option>
                        ))}
                      </optgroup>
                    )}
                  </>
                ))}
              </select>
            </div>
          )}
        </div>
      )}
      {showReport() && (
        <PrintComponent ref={componentRef}>
          <div className='row g-xl-8'>
            <div className='col-12'>
              <div className='card p-0 overflow-hidden'>
                <div className='card-header m-0 p-0 border-0'>
                  <ReportHeader
                    company={selectedCompany}
                    portfolio={selectedPortfolio}
                    state={state}
                  />
                </div>
                <div
                  className='card-body p-15 pt-13 px-20 position-relative'
                  id='impact-objectives-report'
                >
                  <div className='mb-10'>
                    {/* <div className='text-gray-600 fs-7 fw-bolder mb-1'>
                    {moment().format('MMMM Do YYYY')}
                  </div> */}
                    <h2>Impact Objectives Report</h2>
                    <p className='fs-6 fw-bold text-gray-600 mt-3'>
                      Overview of enterprise's impact categories, strategic goals, and IMM
                      Framework(s).
                    </p>
                  </div>

                  {!!getImpactList().length && (
                    <div className='card card-custom p-0 pt-2'>
                      <table className='table table-striped table-row-bordered gs-5 gy-5'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bolder fs-7 '>
                            <th className='col-1'>No</th>
                            <th className='col-2'>Name</th>
                            <th className='col-2'>Category</th>
                            <th className='col-2'>Stakeholders</th>
                            <th className='col-2'>Funding</th>
                            <th className='col-4'>Development Goals</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {getImpactList().map((impact: any, index: any) => (
                            <tr
                              key={impact.group_identifier}
                              className='fw-bold text-gray-700 fs-7'
                            >
                              <td>{index + 1}</td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {impact.name}
                                  </div>
                                </div>
                              </td>
                              <td>{impact?.category}</td>
                              <td>
                                {!!impact?.stakeholders?.direct?.length && (
                                  <div className='mb-2 '>
                                    <b>Direct</b> -{' '}
                                    {impact?.stakeholders?.direct
                                      .map((s: any) => s.name)
                                      .join(', ')}{' '}
                                  </div>
                                )}

                                {!!impact?.stakeholders?.inDirect?.length && (
                                  <div className='mb-2 '>
                                    <b>inDirect</b> -{' '}
                                    {impact?.stakeholders?.inDirect
                                      .map((s: any) => s.name)
                                      .join(', ')}
                                  </div>
                                )}
                              </td>

                              <td>{currencyFormatter.format(impact?.funding)}</td>

                              <td>
                                <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2'>
                                  {impact.sdgs.map((sdg: any, index: any) => (
                                    <span
                                      className='symbol symbol-circle symbol-20px'
                                      key={`sgds-${impact.group_identifier}-${index}`}
                                    >
                                      <img
                                        alt='Logo'
                                        src={toAbsoluteUrl(`/media/images/sdgs/${sdg}.png`)}
                                        className='h-25px w-auto'
                                        title={`${sdgs.find((s: any) => s.value == sdg)?.label}`}
                                      />
                                    </span>
                                  ))}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* end::Table body */}
                      </table>
                    </div>
                  )}

                  <div className='col-12 mt-15 pb-20' id='distribution-chart'>
                    <div className=''>
                      <h2>Impact Per Sustainable Developmental Goals</h2>
                      <p className='fs-6 fw-bold text-gray-600 mt-3'>
                        This graph shows a distribution of your enterprise's impact focus based on
                        the 17 sustainable development goals (SDGs). All metrics in your IMM
                        framework(s) are mapped to the SDGs using the IRIS Catalog of Metrics.
                      </p>
                    </div>
                    <div className='position-relative'>
                      <SaveToImageButton node='distribution-chart' />
                      <PieChart
                        data={getDistributionData()}
                        labelTextType='aligned'
                        useDataColor={true}
                      />
                    </div>
                  </div>

                  <div
                    className='col-12 mt-15 position-relative pb-20'
                    id='people-environment-chart'
                  >
                    <div className=''>
                      <h2>People Vs Environment</h2>
                      <p className='fs-6 fw-bold text-gray-600 mt-3'>
                        This graph shows a distribution of your enterprise's focus based on two
                        pillars - environment and planet. All impact metrics in your IMM
                        framework(s) are mapped to the stakeholder(s) - people or planet using the
                        IRIS Catalog of Metrics.
                      </p>
                    </div>
                    <div className='position-relative'>
                      <SaveToImageButton node='people-environment-chart' />
                      <PieChart data={getPEData()} labelTextType='circular' useDataColor={true} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PrintComponent>
      )}
    </div>
  )
}

export default ImpactObjectivesReport
