/* eslint-disable jsx-a11y/anchor-is-valid */
//import React from 'react'
//import {Card5} from '../../../_metronic/partials/content/cards/Card5'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
//import {Link} from 'react-router-dom'

export function Error() {
  return (
    <>
      <div className='text-center p-20 pt-15 row bg-white'>
        <div className='col-sm-12 pt-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/illustrations/error.jpg')}
            className='h-200px mb-5 w-auto mx-auto'
          />

          <h2 className='fw-bolder text-center'>An Error Occurred</h2>
        </div>

        <div className='fs-6 text-gray-600 mt-5 mb-10 mx-auto col-lg-6'>
          An error occured trying to load the contents of this page. Kindly click on the reload
          button to refresh the page.
        </div>

        <a href=''>
          <button type='button' className='btn btn-lg btn-primary fw-bolder'>
            Reload
          </button>
        </a>
      </div>
    </>
  )
}
