// import moment from 'moment'
import React, {useContext, useRef, useMemo, useState, useEffect} from 'react'
// import {Link} from 'react-router-dom'
// import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
// import ReactToPrint from 'react-to-print'
// import PrintComponent from '../../shared/PrintComponent'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, {CSVExport} from 'react-bootstrap-table2-toolkit'
import {AuthContext} from '../../../context/AuthContext'
import {PortfolioContext} from '../../../context/PortfolioContext'
import ReportHeader from './ReportHeader'

const MetricsPerformanceHistory = () => {
  const {company = {}} = useContext(CompanyContext)
  const {impactList = []} = useContext(DashboardContext)

  const {user, isAdmin} = useContext(AuthContext)
  const {portfolios = []} = useContext(PortfolioContext)

  const [selectedCompany, setSelectedCompany] = useState<any>()
  const [selectedPortfolio, setSelectedPortfolio] = useState<any>()

  const [state, setState] = React.useState({
    reportType: 'my-company',
    portfolio: '',
    company: '',
  })

  useEffect(() => {
    setSelectedCompany(getSelectedCompany())
    setSelectedPortfolio(getSelectedPortfolio())
  }, [state, selectedPortfolio])

  const handleChange = (evt: any) => {
    const value = evt.target.value
    setState({
      ...state,
      [evt.target.name]: value,
    })
  }

  const getSelectedCompany = () => {
    if (state.reportType !== 'investee') return company
    const [portfolio_id, company_id] = (state?.company || '').split('-')
    const {companies = []} =
      portfolios.find((portfolio: any) => portfolio.group_identifier === portfolio_id) || {}

    return companies.find((c: any) => c.group_identifier === company_id)
  }

  const getSelectedPortfolio = () => {
    if (state.reportType !== 'portfolio') return
    return portfolios.find((portfolio: any) => portfolio.group_identifier === state?.portfolio)
  }

  const showReport = () => {
    if (state.reportType === 'portfolio' && !selectedPortfolio) return false
    if (!selectedCompany) return false

    return true
  }

  const columns = [
    {text: 'Impact Focus', dataField: 'impact_focus_name'},
    {text: 'Metric Name', dataField: 'metric_name'},
    {text: 'Target', dataField: 'target'},
    {text: 'Default Outcome', dataField: 'defaultPosition'},
    {text: 'Progress', dataField: 'progress'},
    {text: 'Channels', dataField: 'channels'},
    {text: 'Period', dataField: 'period'},
  ]

  const {ExportCSVButton} = CSVExport

  const getData = () => {
    if (state.reportType === 'my-company') return getCompanyData()
    if (state.reportType === 'investee') return getInvesteeData()
    return []
  }

  const getCompanyData = () => {
    const data = impactList.reduce((acc: any, impact: any) => {
      impact.metrics.forEach((metric: any) => {
        metric.progress.forEach((p: any) => {
          acc.push({
            impact_focus_name: impact.name,
            metric_name: metric.name,
            target: (metric.baseline || {}).target,
            defaultPosition: p.defaultPosition,
            progress: p.currentPosition,
            channels: p.channels,
            period: p.period.label,
          })
        })
      })
      return acc
    }, [])

    return data
  }

  const getInvesteeData = () => {
    const data = (selectedCompany?.impacts || []).reduce((acc: any, impact: any) => {
      impact.questions.forEach((question: any) => {
        acc.push({
          impact_focus_name: impact.name,
          question: question.question,
          answer: question.answer.value,
        })
      })
    }, [])

    return data
  }

  return (
    <div>
      <ToolkitProvider keyField='id' data={getData()} columns={columns} exportCSV>
        {(props) => (
          <div>
            <ExportCSVButton {...props.csvProps} className='btn btn-dark mb-7 btn-sm'>
              Export CSV
            </ExportCSVButton>

            {user.type === 'Investor' && (
              <div className='card card-custom bg-dark text-white mb-10 py-10 px-10 pb-7'>
                <div className='mb-5'>
                  <span className='fw-bolder'>Generate Report</span>
                  <select
                    className='form-select mt-1 fs-7 cursor-pointer'
                    name='reportType'
                    value={state.reportType}
                    onChange={handleChange}
                  >
                    <option value='my-company'>My Company ({company.company_name})</option>
                    <option value='investee'>Per Enterprise</option>
                  </select>
                </div>

                {state.reportType === 'portfolio' && (
                  <div className='mb-5'>
                    <span className='fw-bolder w-150px mb-2'>Select Portfolio</span>
                    <select
                      className='form-select fs-7 mt-1 cursor-pointer'
                      name='portfolio'
                      value={state.portfolio}
                      onChange={handleChange}
                    >
                      <option>Select Portfolio</option>
                      {portfolios.map((portfolio: any) => (
                        <option value={portfolio.group_identifier}>{portfolio.name}</option>
                      ))}
                    </select>
                  </div>
                )}
                {state.reportType === 'investee' && (
                  <div className='mb-5'>
                    <span className='fw-bolder w-150px mb-2'>Select Company</span>
                    <select
                      className='form-select fs-7 mt-1 cursor-pointer'
                      name='company'
                      value={state.company}
                      onChange={handleChange}
                    >
                      <option>Select Company</option>
                      {portfolios.map((portfolio: any) => (
                        <>
                          {!!portfolio.companies.length && (
                            <optgroup label={portfolio.name} className='mt-10'>
                              {portfolio.companies.map((company: any) => (
                                <option
                                  value={`${portfolio.group_identifier}-${company.group_identifier}`}
                                >
                                  {company.name}
                                </option>
                              ))}
                            </optgroup>
                          )}
                        </>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            )}

            {showReport() && (
              <div className='card p-0 overflow-hidden'>
                <ReportHeader
                  company={selectedCompany}
                  portfolio={selectedPortfolio}
                  state={state}
                />

                <div className='px-15 pt-10 pb-5 '>
                  <h2>Performance History Report (Raw)</h2>
                  <div className='fs-6 text-gray-600 '>
                    This report highlights the enterprise's current SDG performance data based on
                    impact metrics and relative targets. The data is provided in CSV. The format can
                    be downloaded as a spreadsheet for further analysis offline. All impact metrics
                    in your IMM framework(s) are mapped to the SDGs using the IRIS Catalog of
                    Metrics.
                  </div>
                </div>
                <div className='px-15 pb-10'>
                  <BootstrapTable
                    {...props.baseProps}
                    headerClasses='fw-bolder fs-7 '
                    rowClasses='fw-bold text-gray-700 fs-7'
                    // headerWrapperClasses=''
                    classes='table table-striped table-row-bordered gs-5 gy-5'
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </ToolkitProvider>
    </div>
  )
}

export default MetricsPerformanceHistory
