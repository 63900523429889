const LoadingSpinner = () => (
  <div
    className='indicator-progress text-center mx-auto'
    style={{display: 'block', padding: '140px 200px'}}
  >
    {/* <span className='spinner-border spinner-border-lg text-gray-500'></span> */}
    <div className='loading-placeholder p-3 mb-3 w-25'></div>
    <div className='loading-placeholder p-3 mb-10 w-100'></div>
  </div>
)

export default LoadingSpinner
