import axios from 'axios'

let config = {
  headers: {Authorization: `Bearer ${localStorage.getItem('access_token')}`},
}

const BASE_URL = process.env.REACT_APP_BASE_URL

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    if (401 === error.response.status) {
      window.location.href = '/logout'
      console.log(error.response.status)
    }
    return Promise.reject(error)
  }
)

export const getSectionQuestions = (tag: string) =>
  axios.get(`${BASE_URL}/section/questions/${tag}`, config)

export const getCompanySectionQuestions = (tag: string, id: any) =>
  id
    ? axios.get(`${BASE_URL}/company/${id}/display/${tag}`, config)
    : axios.get(`${BASE_URL}/section/questions/${tag}`, config)

export const getSectionAnswers = (tag: string, id: any) =>
  axios.get(`${BASE_URL}/company/${id}/section/${tag}/answers`, config)

export const getAnswersByIdentifier = (id: any, group_identifier: any) =>
  axios.get(`${BASE_URL}/company/${id}/answers/${group_identifier}`, config)

export const getAnswersByParent = (id: any, parent_group_identifier: any) =>
  axios.get(`${BASE_URL}/company/${id}/impact/${parent_group_identifier}/data`, config)

export const getAnswersByMultipleParent = (id: any, parent_group_identifiers: any) =>
  axios.post(`${BASE_URL}/company/${id}/impact/data`, {parent_group_identifiers}, config)

export const getSDGsByMetrics = (id: any, metrics: any) =>
  axios.post(`${BASE_URL}/company/${id}/sdgs`, {metrics}, config)

export const getAnswersByChild = (id: any, group_identifier: any) =>
  axios.get(`${BASE_URL}/company/${id}/data/${group_identifier}`, config)

export const addCompanyToPortfolio = (id: any, data: any) =>
  axios.post(`${BASE_URL}/add/company/${id}/portfolio`, data, config)

export const updateAssociation = (id: any, data: any) =>
  axios.post(`${BASE_URL}/activate/company/${id}/portfolio`, data, config)

export const inviteMember = (data: any, id: any) =>
  axios.post(`${BASE_URL}/company/${id}/add/member`, data, config)

export const addMember = (data: any, id: any) =>
  axios.post(`${BASE_URL}/company/add/member`, data, config)

export const requestCompanyAccess = (id: any) =>
  axios.post(`${BASE_URL}/request/company/${id}/access`, {}, config)

export const approveCompanyAccess = (id: any, member_id: any) =>
  axios.post(`${BASE_URL}/confirm/member/company/${id}`, {member_id}, config)

export const denyCompanyAccess = (id: any, member_id: any) =>
  axios.post(`${BASE_URL}/deny/member/company/${id}`, {member_id}, config)

export const activateUser = (token: any) => axios.post(`${BASE_URL}/user/activate/user/${token}`)
export const acceptPortfolioInvite = (token: any) =>
  axios.post(`${BASE_URL}/user/activate/company/portfolio/${token}`)

export const activateMember = (token: any) =>
  axios.post(`${BASE_URL}/user/activate/member/${token}`)

export const getImpactSDGs = (company_id: string, group_identifier: string) =>
  axios.get(`${BASE_URL}/company/${company_id}/${group_identifier}/sdgs`, config)

export const getMyCompanies = () => {
  return axios.get(`${BASE_URL}/my/companies`, config)
}

export const getCompanies = () => axios.get(`${BASE_URL}/companies`, config)
export const getDirectoryCompanies = () => axios.get(`${BASE_URL}/directory/companies`, config)
export const getCompanyByIdentifier = (id: any) => axios.get(`${BASE_URL}/company/${id}`, config)
export const getInvestorCompanies = () => axios.get(`${BASE_URL}/investor/companies`, config)

export const submitCompanyAnswers = (data: any, id: any) =>
  id
    ? axios.post(`${BASE_URL}/company/edit/${id}`, data, config)
    : axios.post(`${BASE_URL}/company`, data, config)

export const submitGeneralAnswers = (data: any, id: any, group_identifier: any) =>
  group_identifier
    ? axios.post(`${BASE_URL}/edit/company/${id}/section/questions/answers`, data, config)
    : axios.post(`${BASE_URL}/section/questions/answers`, data, config)

export const registerUser = (user: any) => axios.post(`${BASE_URL}/user/register`, user)
export const forgotPassword = (user: any) => axios.post(`${BASE_URL}/user/reset/password`, user)
export const resetPassword = (user: any, token: any) =>
  axios.post(`${BASE_URL}/user/change/password/${token}`, user)

export const loginUser = (credentials: any) => axios.post(`${BASE_URL}/user/login`, credentials)

export const uploadFile = (data: any) => axios.post(`${BASE_URL}/uploading-file-api`, data, config)

// Admin Apis
export const getSections = () => axios.get(`${BASE_URL}/section`, config)
export const getSection = (id: any) => axios.get(`${BASE_URL}/section/${id}`, config)
export const deleteSection = (id: any) => axios.delete(`${BASE_URL}/section/${id}`, config)
export const submitSection = (section: any, id: any) =>
  id
    ? axios.put(`${BASE_URL}/section/${id}`, section, config)
    : axios.post(`${BASE_URL}/section`, section, config)

export const getQuestions = () => axios.get(`${BASE_URL}/question`, config)
export const getQuestion = (id: any) => axios.get(`${BASE_URL}/question/${id}`, config)
export const deleteQuestion = (id: any) => axios.delete(`${BASE_URL}/question/${id}`, config)
export const submitQuestion = (question: any, id: any) =>
  id
    ? axios.put(`${BASE_URL}/question/${id}`, question, config)
    : axios.post(`${BASE_URL}/question`, question, config)

export const getUsers = () => axios.get(`${BASE_URL}/admin/user`, config)
export const getUser = (id: any) => axios.get(`${BASE_URL}/admin/user/${id}`, config)
export const deleteUser = (id: any) => axios.delete(`${BASE_URL}/admin/user/${id}`, config)
export const submitUser = (user: any, id: any) =>
  id
    ? axios.put(`${BASE_URL}/admin/user/${id}`, user, config)
    : axios.post(`${BASE_URL}/admin/user`, user, config)

export const getMetrics = () => axios.get(`${BASE_URL}/admin/metrics`, config)
export const getMetric = (id: any) => axios.get(`${BASE_URL}/admin/metrics/${id}`, config)
export const deleteMetric = (id: any) => axios.delete(`${BASE_URL}/admin/metrics/${id}`, config)
export const submitMetric = (metrics: any, id: any) =>
  id
    ? axios.put(`${BASE_URL}/admin/metrics/${id}`, metrics, config)
    : axios.post(`${BASE_URL}/admin/metrics`, metrics, config)

export const getLocations = () => axios.get(`${BASE_URL}/admin/locations`, config)
export const getLocation = (id: any) => axios.get(`${BASE_URL}/admin/locations/${id}`, config)
export const deleteLocation = (id: any) => axios.delete(`${BASE_URL}/admin/locations/${id}`, config)
export const deleteImpactFocus = (id: any) =>
  axios.delete(`${BASE_URL}/delete/impact/${id}`, config)
export const submitLocation = (locations: any, id: any) =>
  id
    ? axios.put(`${BASE_URL}/admin/locations/${id}`, locations, config)
    : axios.post(`${BASE_URL}/admin/locations`, locations, config)

export const getResources = () => axios.get(`${BASE_URL}/resource`, config)
export const getResource = (id: any) => axios.get(`${BASE_URL}/resource/${id}`, config)
export const deleteResource = (id: any) => axios.delete(`${BASE_URL}/resource/${id}`, config)
export const submitResource = (resource: any, id: any) =>
  id
    ? axios.put(`${BASE_URL}/resource/${id}`, resource, config)
    : axios.post(`${BASE_URL}/resource`, resource, config)

export const getThemes = () => axios.get(`${BASE_URL}/admin/impact/theme`, config)
export const getTheme = (id: any) => axios.get(`${BASE_URL}/admin/impact/theme/${id}`, config)
export const deleteTheme = (id: any) => axios.delete(`${BASE_URL}/admin/impact/theme/${id}`, config)
export const submitTheme = (themes: any, id: any) =>
  id
    ? axios.put(`${BASE_URL}/admin/impact/theme/${id}`, themes, config)
    : axios.post(`${BASE_URL}/admin/impact/theme`, themes, config)

export const getImpactCategories = () => axios.get(`${BASE_URL}/admin/impact/category`, config)
export const getImpactCategory = (id: any) =>
  axios.get(`${BASE_URL}/admin/impact/category/${id}`, config)
export const deleteImpactCategory = (id: any) =>
  axios.delete(`${BASE_URL}/admin/impact/category/${id}`, config)
export const submitImpactCategory = (category: any, id: any) =>
  id
    ? axios.put(`${BASE_URL}/admin/impact/category/${id}`, category, config)
    : axios.post(`${BASE_URL}/admin/impact/category`, category, config)

export const getRoles = () => axios.get(`${BASE_URL}/roles`, config)
export const getRole = (id: any) => axios.get(`${BASE_URL}/roles/${id}`, config)
export const deleteRole = (id: any) => axios.delete(`${BASE_URL}/roles/${id}`, config)
export const submitRole = (role: any, id: any) =>
  id
    ? axios.put(`${BASE_URL}/roles/${id}`, role, config)
    : axios.post(`${BASE_URL}/roles`, role, config)

export const getRolePrivileges = (id: any) => axios.get(`${BASE_URL}/role/${id}/privileges`, config)
export const setRolePrivileges = (privileges: any, id: any) =>
  axios.put(`${BASE_URL}/role/${id}/privileges`, privileges, config)

export const getEntitys = () => axios.get(`${BASE_URL}/entity`, config)
export const getEntity = (id: any) => axios.get(`${BASE_URL}/entity/${id}`, config)
export const deleteEntity = (id: any) => axios.delete(`${BASE_URL}/entity/${id}`, config)
export const submitEntity = (entity: any, id: any) =>
  id
    ? axios.put(`${BASE_URL}/entity/${id}`, entity, config)
    : axios.post(`${BASE_URL}/entity`, entity, config)

export const getEntities = () => axios.get(`${BASE_URL}/entity`, config)
export const getEntityFields = () => axios.get(`${BASE_URL}/entity`, config)

export const getImpactDistribution = (filter_by: any, time_period: any) =>
  axios.get(
    `${BASE_URL}/admin/distribution/of/impacts?${getFilterQuery(filter_by, time_period)}`,
    config
  )

export const getFilterQuery = (filter_by: any, time_period: any) => {
  let query = ''

  if (filter_by && filter_by != '') query = `filter_by=${filter_by}&`
  if (time_period && time_period != '') query += `time_period=${time_period}`
  return query
}

export const getSDGPerformance = (filter_by: any, time_period: any) =>
  axios.get(
    `${BASE_URL}/admin/all/sdg/performance?${getFilterQuery(filter_by, time_period)}`,
    config
  )

export const getPeopleEnvironmentData = (filter_by: any, time_period: any) =>
  axios.get(
    `${BASE_URL}/admin/people/planet/graph?${getFilterQuery(filter_by, time_period)}`,
    config
  )

export const getDataCollectionData = (filter_by: any, time_period: any) =>
  axios.get(
    `${BASE_URL}/admin/data/collection/graph?${getFilterQuery(filter_by, time_period)}`,
    config
  )

export const getImpactLocationData = (filter_by: any, time_period: any) =>
  axios.get(
    `${BASE_URL}/admin/geographic/distribution/graph?${getFilterQuery(filter_by, time_period)}`,
    config
  )

export const getFundingByImpactLocation = (filter_by: any, time_period: any) =>
  axios.get(
    `${BASE_URL}/admin/funding/by/impact/location?${getFilterQuery(filter_by, time_period)}`,
    config
  )

export const getFundingByCompanyLocation = (filter_by: any, time_period: any) =>
  axios.get(
    `${BASE_URL}/admin/funding/by/company/location?${getFilterQuery(filter_by, time_period)}`,
    config
  )

export const getFundingByCompanySize = (filter_by: any, time_period: any) =>
  axios.get(
    `${BASE_URL}/admin/funding/by/company/size?${getFilterQuery(filter_by, time_period)}`,
    config
  )

export const getFundingBySector = (filter_by: any, time_period: any) =>
  axios.get(`${BASE_URL}/admin/funding/by/sector?${getFilterQuery(filter_by, time_period)}`, config)

export const getRiskProfileDistribution = (filter_by: any, time_period: any) =>
  axios.get(
    `${BASE_URL}/admin/risk/profile/distribution?${getFilterQuery(filter_by, time_period)}`,
    config
  )

export const getRiskAnalysis = (filter_by: any, time_period: any) =>
  axios.get(`${BASE_URL}/admin/risk/analysis?${getFilterQuery(filter_by, time_period)}`, config)

export const getExpectedDurationOfImpact = (filter_by: any, time_period: any) =>
  axios.get(
    `${BASE_URL}/admin/expected/duration/of/impact?${getFilterQuery(filter_by, time_period)}`,
    config
  )

export const getFundingByCompanyLocationDetails = (location: any) =>
  axios.get(`${BASE_URL}/admin/company/funding/by/location/${location}`, config)

export const getFundingBySectorDetails = (sector: any) =>
  axios.get(`${BASE_URL}/admin/company/funding/by/sector/${sector}`, config)

export const getFundingByCompanySizeDetails = (company_size: any) =>
  axios.get(`${BASE_URL}/admin/company/funding/by/size/${company_size}`, config)

export const getFundingByImpactLocationDetails = (location: any) =>
  axios.get(`${BASE_URL}/admin/company/funding/by/impact/location/${location}`, config)

export const getCompanyDistributionBySDG = (sdg_number: any) =>
  axios.get(`${BASE_URL}/admin/company/impact/distribution/${sdg_number}`, config)

export const getCompanyPerformanceBySDG = (sdg_number: any) =>
  axios.get(`${BASE_URL}/admin/company/sdg/performance/${sdg_number}`, config)

export const getImpactLocationDetails = (location: any) =>
  axios.get(`${BASE_URL}/admin/company/geographic/distribution/${location}`, config)

export const getPeopleEnvironmentDetails = (grouping: any) =>
  axios.get(`${BASE_URL}/admin/company/people/environment/${grouping}`, config)

export const getRiskProfileDetails = (risk: any) =>
  axios.get(`${BASE_URL}/admin/company/risk/profile/${risk}`, config)

export const getRiskAnalysisDetails = (risk: any) =>
  axios.get(`${BASE_URL}/admin/company/risk/analysis/${risk}`, config)

export const getDataCollectionDetails = (channel: any) =>
  axios.get(`${BASE_URL}/admin/company/data/collection/${channel}`, config)

export const getExpectedDurationOfImpactDetails = (duration: any) =>
  axios.post(`${BASE_URL}/admin/company/duration/of/impact`, {duration}, config)

export const getCompanyIMP = (identifier: any) =>
  axios.get(`${BASE_URL}/company/${identifier}/imp`, config)

export const getSectorBenchmark = (identifier: any) =>
  axios.get(`${BASE_URL}/company/${identifier}/benchmark`, config)

// Reports API
export const getCompanyReport = () => axios.get(`${BASE_URL}/admin/company/report`, config)
export const getImpactReport = () => axios.get(`${BASE_URL}/admin/impact/report`, config)
export const getSDGReport = () => axios.get(`${BASE_URL}/admin/sdg/report`, config)

export const getPortfoliosByCompany = (group_identifier: any) =>
  axios.get(`${BASE_URL}/company/${group_identifier}/portfolios`, config)

export const acceptPortfolioInvitation = (group_identifier: any, portfolio_identifier: any) =>
  axios.post(
    `${BASE_URL}/company/${group_identifier}/portfolios/accept`,
    {portfolio_identifier},
    config
  )

export const declinePortfolioInvitation = (group_identifier: any, portfolio_identifier: any) =>
  axios.post(
    `${BASE_URL}/company/${group_identifier}/portfolios/decline`,
    {portfolio_identifier},
    config
  )

export const leavePortfolioInvitation = (group_identifier: any, portfolio_identifier: any) =>
  axios.post(
    `${BASE_URL}/company/${group_identifier}/portfolios/leave`,
    {portfolio_identifier},
    config
  )
