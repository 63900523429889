import {useContext, useEffect, useState} from 'react'
import {Link, useParams, useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
import {sdgs} from '../../shared/Constants'
import CurrentMetric from '../../shared/CurrentMetric'
import {getMetricPercentageCompleted} from '../../shared/Helpers'

const PeopleEnvironmentDetails = () => {
  const {category} = useParams<any>() || {}

  const {sdgPerformance, impactList} = useContext(DashboardContext)
  const {company = {}} = useContext(CompanyContext)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  const getValueByCategory = (category: any) => {
    const arr = sdgPerformance.filter((s: any) => s.category === category)
    return Number((arr.reduce((acc: any, s: any) => acc + s.completed, 0) / arr.length).toFixed(1))
  }

  const affectedSdgs = sdgs.filter((sdg) => sdg.category === category)

  const metrics = impactList.reduce((acc: any, impact: any) => {
    impact.metrics.forEach((metric: any) => {
      const includeMetric = !!metric.development_goals.filter((s: any) =>
        affectedSdgs.map((sdg: any) => sdg.value).includes(s)
      )
      if (includeMetric) {
        acc.push({impact: {...impact, metrics: []}, metric})
      }
    })

    return acc
  }, [])

  const data = [
    {
      category: 'People',
      value: getValueByCategory('People'),
      color: '#e6194b',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      category: 'Environment',
      value: getValueByCategory('Environment'),
      color: '#3cb44b',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
  ]

  const selected = data.find((d: any) => d.category === category)

  return (
    <>
      <PageTitle>{selected?.category}</PageTitle>
      <div className='d-flex mb-10 p-10' style={{backgroundColor: selected?.color}}>
        <div className='flex-grow-1 text-white'>
          <h2 className='text-white'>{selected?.category}</h2>
          <p>{selected?.description}</p>
        </div>
        <div className='percentage ms-10 text-center text-white'>
          <span className='fw-bolder ' style={{fontSize: 40}}>
            {selected?.value}
          </span>
          %<span className='d-block fs-8'>Completed</span>
        </div>
      </div>

      <div className='card card-custom p-10'>
        <h2>Contributing Sustainable Development Goals</h2>
        <p className='fw-bold text-gray-600'>{selected?.description}</p>
        <div className='d-flex  bg-white'>
          {sdgs
            .filter((sdg) => sdg.category === category)
            .map((sdg) => (
              <Link to={`${prefix}/dashboard/sdg/${sdg.value}`} title={sdg.label}>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl(`/media/images/${sdg?.value}.png`)}
                  className='h-100px'
                  title={`Sustainable Development Goal ${sdg.value}`}
                />
              </Link>
            ))}
        </div>
      </div>

      <div className=' card card-custom p-10 mt-10'>
        <h2>Contributing Metrics</h2>
        <p className='fw-bold text-gray-600'>{selected?.description}</p>

        <div className='table-responsive '>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-200 align-middle gs-10 mb-0 pb-0'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-dark'>
                <th className='pt-3 pb-3 col-5'>Metric Name</th>
                <th className='pt-3 pb-3 col-2'>Impact Focus</th>
                <th className='pt-3 pb-3 col-1'>Target</th>
                <th className='pt-3 pb-3 col-2'>Progress</th>
                <th className='pt-3 pb-3 col-1'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}

            {metrics.map((i: any) => (
              <>
                <tbody>
                  <tr>
                    <td>
                      <div className='d-flex justify-content-start flex-column py-1'>
                        <Link
                          to={{
                            pathname: `${prefix}/impact-focus/${i?.impact?.group_identifier}/metrics/${i?.metric?.value}`,
                          }}
                          className='fw-bold  fs-9'
                        >
                          <span className='text-gray-700 fw-bolder fs-7 mb-1'>
                            {i.metric?.name}
                          </span>
                        </Link>
                      </div>
                    </td>

                    <td>
                      <div className='d-flex justify-content-start flex-column py-1'>
                        <Link
                          to={{
                            pathname: `${prefix}/impact-focus/${i?.impact?.group_identifier}`,
                          }}
                          className='fw-bold  fs-9'
                        >
                          {i.impact?.name}
                        </Link>
                      </div>
                    </td>
                    <td>
                      <span className='text-gray-500 fw-bolder fs-8'>
                        {i.metric?.baseline
                          ? `${i.metric?.baseline.target} ${i.metric?.baseline.unit}`
                          : '-'}
                      </span>
                    </td>

                    <td>
                      <CurrentMetric
                        target={i?.metric?.baseline?.target || 0}
                        baseline={i?.metric?.baseline?.startPosition || 0}
                        unit={i?.metric?.baseline?.unit}
                        currentPosition={
                          i?.metric?.progress && i.metric?.progress?.length > 0
                            ? i?.metric?.progress[0]?.currentPosition
                            : 0
                        }
                      />
                    </td>

                    <td>
                      {i?.metric?.progress && i.metric?.progress?.length > 0 && (
                        <Link
                          to={{
                            pathname: `${prefix}/impact-focus/${i?.impact?.group_identifier}/metrics/${i?.metric?.value}`,
                          }}
                          className='fw-bold  fs-9'
                        >
                          View History
                        </Link>
                      )}
                    </td>
                  </tr>
                </tbody>
              </>
            ))}

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>
    </>
  )
}

export default PeopleEnvironmentDetails
