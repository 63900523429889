import {createContext, useContext, useEffect, useState} from 'react'
import {matchPath, useLocation} from 'react-router'
import {getAnswersByIdentifier, getCompanyByIdentifier, getMyCompanies} from '../../api'
import LoadingSpinner from '../modules/shared/LoadingSpinner'
import {AuthContext} from './AuthContext'

export const CompanyContext = createContext<any>({})

export const CompanyProvider = ({children}: any) => {
  const {user, isAdmin} = useContext(AuthContext)
  const [company, setCompany] = useState<any>()
  const [companies, setCompanies] = useState([])
  const [invitedMembers, setInvitedMembers] = useState([])
  const [isLoadingCompanies, setLoadingCompanies] = useState(true)
  const [hasErrors, setError] = useState(false)

  const location = useLocation<any>()
  const {pathname} = useLocation()

  const {group_identifier} =
    (matchPath(pathname, {
      path: '/company/:group_identifier',
    })?.params as any) || {}

  useEffect(() => {
    group_identifier ? reloadCompany(group_identifier) : reloadCompanies()
  }, [group_identifier])

  const saveInvitedMember = (member: any) => {
    const arr: any = [...invitedMembers, member]
    // localStorage.setItem('invitedMembers', JSON.stringify(arr))
    setInvitedMembers(arr)
  }

  const reloadCompany = (group_identifier: any) => {
    setLoadingCompanies(true)
    getCompanyByIdentifier(group_identifier)
      .then(async (response) => {
        const {data = {}} = response.data || {}
        const questions = await getCompanyQuestions(data)
        setCompanies(data)
        setCompany({...data, questions})
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoadingCompanies(false))
  }

  const reloadCompanies = (switchCompany = true) => {
    setLoadingCompanies(true)

    if (user.type !== 'Investor' && user.type !== 'Investee') return setLoadingCompanies(false)

    const {reload} = location.state || {}
    if (reload) {
      location.state.reload = undefined
    }

    getMyCompanies()
      .then(async (response) => {
        const {data = []} = response.data || {}
        const primaryCompany = !!data.length && data[0]

        if (!primaryCompany) return

        const questions = await getCompanyQuestions(primaryCompany)

        setCompanies(data)
        setCompany({...primaryCompany, questions})
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoadingCompanies(false))
  }

  const getCompanyQuestions = async ({id, group_identifier}: any) => {
    try {
      const response = await getAnswersByIdentifier(id, group_identifier)
      const {data = []} = response.data || {}
      return data
    } catch (error) {
      console.log(error)
      return []
    }
  }
  return (
    <CompanyContext.Provider
      value={{companies, company, setCompany, reloadCompanies, invitedMembers, saveInvitedMember}}
    >
      {isLoadingCompanies && <LoadingSpinner />}
      {!isLoadingCompanies && children}
    </CompanyContext.Provider>
  )
}
