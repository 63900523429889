import {createContext, useContext, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
// import {matchPath} from 'react-router'
import {getAnswersByMultipleParent, getAnswersByParent, getSDGsByMetrics} from '../../api'
import {impScaleCategory, sdgs} from '../modules/shared/Constants'
import {
  extractData,
  extractSDGSByImpact,
  getDevelopmentGoals,
  getExpectedDuration,
  getGoals,
  getImpactCategory,
  getImpactDescription,
  getImpactFocusLocation,
  getImpactFocusName,
  getImpactFunding,
  getImpactRisks,
  getMeasurementPeriod,
  getMetricPercentageCompleted,
  getMetrics,
  getPercentageCompleted,
  getStakeHolders,
  getTotalGoals,
  getTotalMetrics,
  removeInvalidProgress,
} from '../modules/shared/Helpers'
import LoadingSpinner from '../modules/shared/LoadingSpinner'
import {CompanyContext} from './CompanyContext'
import {QuestionContext} from './QuestionContext'

export const DashboardContext = createContext<any>({})

export const DashboardProvider = ({children}: any) => {
  const [isLoading, setLoading] = useState(true)
  const [impactList, setImpactList] = useState([])

  const {company = {}} = useContext(CompanyContext)
  const {metricQuestion, goalsQuestion, allQuestions} = useContext(QuestionContext)

  const location = useLocation<any>()

  useEffect(() => {
    getImpactList()
  }, [])

  const getImpactList = async () => {
    try {
      const {reload} = location.state || {}
      if (reload) {
        location.state.reload = undefined
      }

      setLoading(true)
      let impactList: any = []

      if (!company) return setLoading(false)

      const response = await getAnswersByParent(company.id, company.group_identifier)

      impactList = extractImpactList(response)

      const identifiers = impactList.reduce((acc: any, i: any) => {
        i.metrics.forEach((metric: any) => {
          acc.push(`${i.group_identifier}-${metric.value}-baseline`)
          acc.push(`${i.group_identifier}-${metric.value}-progress`)
        })
        return acc
      }, [])

      if (!identifiers.length) {
        setLoading(false)
        setImpactList(impactList)
        return
      }

      const dataResponse = await getAnswersByMultipleParent(company.id, identifiers)

      impactList = extractData(impactList, dataResponse)

      const metrics = impactList.reduce(
        (acc: any, i: any) => [...acc, ...i.metrics.map((metric: any) => metric?.value)],
        []
      )

      if (!metrics.length) {
        setLoading(false)
        setImpactList(impactList)
        return
      }

      const {data} = await getSDGsByMetrics(company.id, metrics)

      impactList = impactList.map((i: any) => ({
        ...i,
        metrics: i.metrics.map((metric: any) => ({
          ...metric,
          development_goals: getDevelopmentGoals(data[metric?.value]) || [],
        })),
      }))

      impactList = extractSDGSByImpact(impactList)

      impactList = removeInvalidProgress(impactList)

      setImpactList(impactList)

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const extractImpactList = (response: any) => {
    const {data = []} = response?.data
    const [obj] = data

    const list: any = []

    for (const key in obj) {
      if (getImpactFocusName(obj[key])) {
        list.push({
          name: getImpactFocusName(obj[key]),
          category: getImpactCategory(obj[key], allQuestions),
          risks: getImpactRisks(obj[key], allQuestions),
          location: getImpactFocusLocation(obj[key], allQuestions),
          stakeholders: getStakeHolders(obj[key]),
          expectedDuration: getExpectedDuration(obj[key]),
          funding: getImpactFunding(obj[key]),
          description: getImpactDescription(obj[key]),
          metrics: getMetrics(obj[key], metricQuestion),
          goals: getGoals(obj[key], goalsQuestion),
          completed: getPercentageCompleted(obj[key]),
          measurementPeriod: getMeasurementPeriod(obj[key]),
          questions: obj[key],
          group_identifier: key,
          sdgs: [],
        })
      }
    }

    return list
  }
  const getSDGPerformance = () => {
    const performance = sdgs.map((sdg: any) => ({
      ...sdg,
      metrics: impactList.reduce((acc: any, impact: any) => {
        impact.metrics.forEach((metric: any) => {
          if (metric.development_goals.includes(sdg.value)) {
            acc.push({
              metric,
              impact,
              percentageCompleted: getMetricPercentageCompleted(metric),
            })
          }
        })
        return acc
      }, []),
    }))

    const allPerformance = performance.map((p: any) => ({
      ...p,

      completed:
        Number(
          (
            p.metrics.reduce((a: any, b: any) => a + Number(b.percentageCompleted), 0) /
            p.metrics.length
          ).toFixed(1)
        ) || 0,
    }))

    return allPerformance.sort((a, b) => b.completed - a.completed)
  }

  const getImpPerformance = () => {
    const performance = impScaleCategory.map((imp: any) => ({
      ...imp,
      metrics: impactList.reduce((acc: any, impact: any) => {
        impact.metrics.forEach((metric: any) => {
          if (metric.tags.includes(imp.tag)) {
            acc.push({
              metric,
              impact,
              percentageCompleted: getMetricPercentageCompleted(metric),
            })
          }
        })
        return acc
      }, []),
    }))

    return performance.map((p: any) => ({
      ...p,

      completed:
        Number(
          (
            p.metrics.reduce((a: any, b: any) => a + Number(b.percentageCompleted), 0) /
            p.metrics.length
          ).toFixed(1)
        ) || 0,
    }))
  }

  return (
    <DashboardContext.Provider
      value={{
        getImpactList,
        impactList,
        isLoading,
        totalGoals: getTotalGoals(impactList),
        totalMetrics: getTotalMetrics(impactList),
        sdgPerformance: getSDGPerformance(),
        impPerformance: getImpPerformance(),
      }}
    >
      {isLoading && <LoadingSpinner />}
      {!isLoading && children}
    </DashboardContext.Provider>
  )
}
