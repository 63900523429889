import axios from 'axios'
import {data} from 'jquery'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'
import {UsersTableMock} from '../__mocks__/usersTableMock'

import {loginUser} from './../../../../api/index'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  //return axios.post(LOGIN_URL, {email, password})

  loginUser({email, password})
    .then(({user, access_token}: any) => {
      if (access_token === undefined) return Promise.reject({})

      const user_template = UsersTableMock.table.find((x) => x.id === 1)

      const res = {
        id: user.id,
        username: user.email,
        email: user.email,
        auth: {
          accessToken: user.access_token,
          refreshToken: user.access_token,
        },
        fullname: user.name,
        firstname: user.name,
        phone: user.phone_number,
        type: user.type,
        password: undefined,
        ...user_template,
      }

      return Promise.resolve(res)
    })
    .catch(() => {
      return Promise.reject({})
    })

  // const user = UsersTableMock.table.find(
  //   (x) => x.email.toLowerCase() === email.toLowerCase() && x.password === password
  // )

  // if (user) {
  //   const auth = user.auth
  //   return Promise.resolve({...auth, password: undefined})
  // }

  // return Promise.reject({})
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  //return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)

  const userString = localStorage.getItem('user')

  if (userString != null) {
    const user = JSON.parse(userString)
    return Promise.resolve({data: {...user, password: undefined}})
  }

  return Promise.reject({})
}
