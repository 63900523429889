import {createContext, useContext, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router'
import {getAnswersByMultipleParent, getAnswersByParent, getSDGsByMetrics} from '../../api'
import {sdgs} from '../modules/shared/Constants'
import {
  extractSDGSByImpact,
  getBaseline,
  getCompanyEmail,
  getCompanyFunding,
  getCompanyList,
  getCompanyLocation,
  getCompanyName,
  getCompanyPhoneNumber,
  getCompanyPortfolioFund,
  getCompanySector,
  getCompanySize,
  getDescription,
  getDevelopmentGoals,
  getExpectedDuration,
  getGoals,
  getImpactCategory,
  getImpactDescription,
  getImpactFocusLocation,
  getImpactFocusName,
  getImpactFunding,
  getImpactRisks,
  getMeasurementPeriod,
  getMetricPercentageCompleted,
  getMetrics,
  getMetricsList,
  getPercentageCompleted,
  getPortfolioName,
  getProgress,
  getStakeHolders,
  removeInvalidProgress,
} from '../modules/shared/Helpers'
import LoadingSpinner from '../modules/shared/LoadingSpinner'
import {AuthContext} from './AuthContext'
import {CompanyContext} from './CompanyContext'
import {QuestionContext} from './QuestionContext'

export const PortfolioContext = createContext<any>({})

export const PortfolioProvider = ({children}: any) => {
  const {company = {}} = useContext(CompanyContext)
  const {user, isAdmin, isInvestor, isInvestee} = useContext(AuthContext)
  const {metricQuestion, goalsQuestion, allQuestions} = useContext(QuestionContext)

  const [portfolios, setPortfolios] = useState([])
  const [isLoading, setLoading] = useState(true)

  const location = useLocation<any>()

  const params = useParams<any>()
  const {id, group_identifier} = isAdmin ? params : company

  useEffect(() => {
    getPortfolios()
  }, [id, group_identifier])

  const getPortfolios = async () => {
    if (isInvestee || !id || !group_identifier) return setLoading(false)

    let list = [],
      response = {},
      identifiers = []
    try {
      const {reload} = location.state || {}
      if (reload) {
        location.state.reload = undefined
      }

      setLoading(true)

      // Get Portfolios
      response = await getAnswersByParent(id, `${group_identifier}-portfolio`)
      list = extractPortfolios(response)

      if (!list.length) return setLoading(false)

      // Get Companies
      identifiers = list.map((i: any) => i.group_identifier)
      response = await getAnswersByMultipleParent(id, identifiers)

      list = extractCompanies(list, response)

      // Get Portfolio Funding
      // identifiers = list.map((portfolio: any) => `${portfolio.group_identifier}-fund`)
      // response = await getAnswersByMultipleParent(company.id, identifiers)
      list = extractPortfolioFunding(list)

      // Get Impact Focus
      identifiers = list.reduce(
        (acc: any, i: any) => [
          ...acc,
          ...i.companies.map((company: any) => company.group_identifier),
        ],
        []
      )

      response = await getAnswersByMultipleParent(id, identifiers)

      list = extractImpactFocus(list, response)

      // Get Metrics Data
      identifiers = list.reduce((acc: any, i: any) => {
        i.companies.forEach((company: any) => {
          company.impacts.forEach((impact: any) => {
            impact.metrics.forEach((metric: any) => {
              acc.push(`${impact.group_identifier}-${metric.value}-baseline`)
              acc.push(`${impact.group_identifier}-${metric.value}-progress`)
            })
          })
        })
        return acc
      }, [])

      response = await getAnswersByMultipleParent(id, identifiers)
      list = extractMetricsData(list, response)

      const metrics = list.reduce((acc: any, i: any) => {
        i.companies.forEach((company: any) => {
          company.impacts.forEach((impact: any) => {
            impact.metrics.forEach((metric: any) => {
              acc.push(metric.value)
            })
          })
        })
        return acc
      }, [])

      response = await getSDGsByMetrics(id, metrics)
      list = extractDevelopmentGoals(list, response)

      // Remove Invalid Impacts
      list = list.map((i: any) => ({
        ...i,
        companies: i.companies.map((company: any) => ({
          ...company,
          // impacts: removeInvalidProgress(company.impacts),
        })),
      }))

      setPortfolios(list)
      setLoading(false)
    } catch (error) {
      setPortfolios(list)
      setLoading(false)
    }
  }

  const extractDevelopmentGoals = (portfolios: any, response: any) => {
    const {data = []} = response || {}

    return portfolios.map((portfolio: any) => ({
      ...portfolio,
      companies: portfolio.companies.map((company: any) => ({
        ...company,
        impacts: extractSDGSByImpact(
          company.impacts.map((impact: any) => ({
            ...impact,
            metrics: impact.metrics.map((metric: any) => ({
              ...metric,
              development_goals: getDevelopmentGoals(data[metric?.value]) || [],
            })),
          }))
        ),
      })),
    }))
  }

  // const extractPortfolioFunding = (portfolios: any, response: any) => {
  //   const {data = []} = response.data || {}

  //   const [obj = {}] = data || []

  //   return portfolios.map((portfolio: any) => ({
  //     ...portfolio,
  //     companies: portfolio.companies.map((company: any) => ({
  //       ...company,
  //       // portfolioFunding: getPortflioFunding(obj[company.group_identifier]),
  //     })),
  //   }))
  // }

  const extractPortfolios = (response: any) => {
    const {data = []} = response.data || {}
    const [obj = {}] = data || []

    let list: any = []

    for (const key in obj) {
      list = [
        ...list,
        {
          name: getPortfolioName(obj[key]),
          description: getDescription(obj[key]),
          questions: obj[key],
          group_identifier: key,
          companies: [],
        },
      ]
    }

    return list
  }

  const extractCompanies = (portfolios: any, response: any) => {
    const {data = []} = response.data
    const [obj = {}] = data

    const list = portfolios.map((portfolio: any) => ({
      ...portfolio,
      companies: getCompanies(obj[portfolio.group_identifier] || []),
    }))

    return list
  }

  const extractPortfolioFunding = (portfolios: any) => {
    return portfolios.map((portfolio: any) => ({
      ...portfolio,
      companies: portfolio.companies.map((company: any, index: any) => ({
        ...company,
        portfolioFunding: getCompanyPortfolioFund(portfolio.questions, index),
      })),
    }))
  }

  const getCompanies = (arr: any) => {
    const [obj = {}] = arr || []
    const list = []

    for (const key in obj) {
      list.push({
        name: getCompanyName(obj[key]),
        location: getCompanyLocation(obj[key], allQuestions),
        funding: getCompanyFunding(obj[key]),
        sector: getCompanySector(obj[key], allQuestions),
        size: getCompanySize(obj[key]),
        email: getCompanyEmail(obj[key]),
        phone_number: getCompanyPhoneNumber(obj[key]),
        questions: obj[key],
        group_identifier: key,
        impacts: [],
        portfolioFunding: 0,
      })
    }

    return list
  }

  const extractImpactFocus = (portfolios: any, response: any) => {
    const {data = []} = response.data || {}

    const [obj = {}] = data || []

    return portfolios.map((portfolio: any) => ({
      ...portfolio,
      companies: portfolio.companies.map((company: any) => ({
        ...company,
        impacts: getImpactFocus(obj[company.group_identifier]),
      })),
    }))
  }

  const getImpactFocus = (arr: any) => {
    const [obj = {}] = arr || []
    const list = []

    for (const key in obj) {
      list.push({
        name: getImpactFocusName(obj[key]),
        description: getImpactDescription(obj[key]),
        category: getImpactCategory(obj[key], allQuestions),
        funding: getImpactFunding(obj[key]),
        stakeholders: getStakeHolders(obj[key]),
        risks: getImpactRisks(obj[key], allQuestions),
        location: getImpactFocusLocation(obj[key], allQuestions),
        metrics: getMetrics(obj[key], metricQuestion),
        goals: getGoals(obj[key], goalsQuestion),
        measurementPeriod: getMeasurementPeriod(obj[key]),
        completed: getPercentageCompleted(obj[key]),
        expectedDuration: getExpectedDuration(obj[key]),
        questions: obj[key],
        group_identifier: key,
        sdgs: [],
      })
    }

    return list
  }

  const extractMetricsData = (portfolios: any, response: any) => {
    const {data = []} = response.data || {}
    const [obj = {}] = data || []
    return portfolios.map((portfolio: any) => ({
      ...portfolio,
      companies: portfolio.companies.map((company: any) => ({
        ...company,
        impacts: company.impacts.map((impact: any) => ({
          ...impact,
          metrics: impact.metrics.map((metric: any) => ({
            ...metric,
            baseline: getBaseline(obj[`${impact.group_identifier}-${metric.value}-baseline`]),
            progress: getProgress(
              impact,
              obj[`${impact.group_identifier}-${metric.value}-progress`]
            ),
          })),
        })),
      })),
    }))
  }

  const getSDGPerformance = () => {
    const metrics = getMetricsList(portfolios)
    return sdgs
      .map((sdg: any) => ({
        ...sdg,
        metrics: metrics.filter((metric: any) => metric.development_goals.includes(sdg.value)),
      }))
      .map((sdg: any) => ({
        ...sdg,
        completed:
          Number(
            (
              sdg.metrics.reduce(
                (acc: any, metric: any) => acc + getMetricPercentageCompleted(metric),
                0
              ) / sdg.metrics.length
            ).toFixed(1)
          ) || 0,
      }))
      .sort((a, b) => b.completed - a.completed)
  }

  return (
    <PortfolioContext.Provider
      value={{
        portfolios,
        getPortfolios,
        metrics: getMetricsList(portfolios),
        companies: getCompanyList(portfolios),
        sdgPerformance: getSDGPerformance(),
      }}
    >
      {isLoading && <LoadingSpinner />}
      {!isLoading && children}
    </PortfolioContext.Provider>
  )
}
