// import moment from 'moment'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
import ReactToPrint from 'react-to-print'
import PrintComponent from '../../shared/PrintComponent'
import {PortfolioContext} from '../../../context/PortfolioContext'
// import {stat} from 'fs'
import ReportHeader from './ReportHeader'
import {sdgs} from '../../shared/Constants'
import {getMetricPercentageCompleted} from '../../shared/Helpers'
import {AuthContext} from '../../../context/AuthContext'
import SaveToImageButton from '../../shared/SaveToImageButton'

const SDGPerformanceReport = () => {
  const componentRef = useRef<any>()
  const {company = {}} = useContext(CompanyContext)
  const {user, isAdmin} = useContext(AuthContext)
  const {sdgPerformance} = useContext(DashboardContext)
  const {sdgPerformance: cummulativeSDGPerformance, portfolios = []} = useContext(PortfolioContext)

  const [selectedCompany, setSelectedCompany] = useState<any>()
  const [selectedPortfolio, setSelectedPortfolio] = useState<any>()

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  const [state, setState] = React.useState({
    reportType: 'my-company',
    portfolio: '',
    company: '',
  })

  function handleChange(evt: any) {
    const value = evt.target.value
    setState({
      ...state,
      [evt.target.name]: value,
    })
  }

  useEffect(() => {
    setSelectedCompany(getSelectedCompany())
    setSelectedPortfolio(getSelectedPortfolio())
  }, [state, selectedPortfolio])

  const getPerformanceData = () => {
    if (state.reportType === 'my-company') return getCompanyPerformance()
    if (state.reportType === 'portfolio') return getPortfolioPerformance()
    if (state.reportType === 'investee') return getInvesteePerformance()
    if (state.reportType === 'cummulative') return getCummulativePerformance()
    return []
  }

  const getCompanyPerformance = () => sdgPerformance
  const getCummulativePerformance = () => cummulativeSDGPerformance
  const getPortfolioPerformance = () => {
    return sdgs
      .map((sdg: any) => ({
        ...sdg,
        metrics:
          selectedPortfolio?.companies.reduce((acc: any, company: any) => {
            company.impacts.forEach((impact: any) => {
              impact.metrics.forEach((metric: any) => {
                if (metric.development_goals.includes(sdg.value)) acc.push(metric)
              })
            })
            return acc
          }, []) || [],
      }))
      .map((sdg: any) => ({
        ...sdg,
        completed:
          Number(
            (
              sdg.metrics.reduce(
                (acc: any, metric: any) => acc + getMetricPercentageCompleted(metric),
                0
              ) / sdg.metrics.length
            ).toFixed(1)
          ) || 0,
      }))
      .sort((a, b) => b.completed - a.completed)
  }

  const getInvesteePerformance = () => {
    return sdgs
      .map((sdg: any) => ({
        ...sdg,
        metrics:
          (selectedCompany?.impacts || []).reduce((acc: any, impact: any) => {
            impact.metrics.forEach((metric: any) => {
              if (metric.development_goals.includes(sdg.value)) acc.push(metric)
            })
            return acc
          }, []) || [],
      }))
      .map((sdg: any) => ({
        ...sdg,
        completed:
          Number(
            (
              sdg.metrics.reduce(
                (acc: any, metric: any) => acc + getMetricPercentageCompleted(metric),
                0
              ) / sdg.metrics.length
            ).toFixed(1)
          ) || 0,
      }))
      .sort((a, b) => b.completed - a.completed)
  }

  const getSelectedCompany = () => {
    if (state.reportType !== 'investee') return company
    const [portfolio_id, company_id] = (state?.company || '').split('-')
    const {companies = []} =
      portfolios.find((portfolio: any) => portfolio.group_identifier === portfolio_id) || {}

    return companies.find((c: any) => c.group_identifier === company_id)
  }

  const getSelectedPortfolio = () => {
    if (state.reportType !== 'portfolio') return
    return portfolios.find((portfolio: any) => portfolio.group_identifier === state?.portfolio)
  }

  const showReport = () => {
    if (state.reportType === 'portfolio' && !selectedPortfolio) return false
    if (!selectedCompany) return false

    return true
  }

  return (
    <div>
      <ReactToPrint
        trigger={() => <button className='btn btn-dark btn-sm mb-5'>Print Report</button>}
        content={() => componentRef.current}
      />

      {user.type === 'Investor' && (
        <div className='card card-custom bg-dark text-white mb-10 py-10 px-10 pb-7'>
          <div className='mb-5'>
            <span className='fw-bolder'>Generate Report</span>
            <select
              className='form-select mt-1 fs-7 cursor-pointer'
              name='reportType'
              value={state.reportType}
              onChange={handleChange}
            >
              <option value='my-company'>My Company ({company.company_name})</option>
              <option value='portfolio'>Per Portfolio</option>
              <option value='investee'>Per Enterprise</option>
              <option value='cummulative'>Cummulative (All Portfolios)</option>
            </select>
          </div>

          {state.reportType === 'portfolio' && (
            <div className='mb-5'>
              <span className='fw-bolder w-150px mb-2'>Select Portfolio</span>
              <select
                className='form-select fs-7 mt-1 cursor-pointer'
                name='portfolio'
                value={state.portfolio}
                onChange={handleChange}
              >
                <option>Select Portfolio</option>
                {portfolios.map((portfolio: any) => (
                  <option value={portfolio.group_identifier}>{portfolio.name}</option>
                ))}
              </select>
            </div>
          )}
          {state.reportType === 'investee' && (
            <div className='mb-5'>
              <span className='fw-bolder w-150px mb-2'>Select Company</span>
              <select
                className='form-select fs-7 mt-1 cursor-pointer'
                name='company'
                value={state.company}
                onChange={handleChange}
              >
                <option>Select Company</option>
                {portfolios.map((portfolio: any) => (
                  <>
                    {!!portfolio.companies.length && (
                      <optgroup label={portfolio.name} className='mt-10'>
                        {portfolio.companies.map((company: any) => (
                          <option
                            value={`${portfolio.group_identifier}-${company.group_identifier}`}
                          >
                            {company.name}
                          </option>
                        ))}
                      </optgroup>
                    )}
                  </>
                ))}
              </select>
            </div>
          )}
        </div>
      )}

      {showReport() && (
        <PrintComponent ref={componentRef}>
          <div className='row g-xl-8'>
            <div className='col-12'>
              <div className='card p-0 overflow-hidden'>
                <ReportHeader
                  company={selectedCompany}
                  portfolio={selectedPortfolio}
                  state={state}
                />
                <div
                  className='card-body p-15 pt-13 px-20 position-relative'
                  id='sdg-performance-report'
                >
                  <SaveToImageButton node='sdg-performance-report' />

                  <div className='mb-10'>
                    <h2>Sustainable Development Goals Performance Report</h2>
                    <p className='fs-6 text-gray-600 mt-3'>
                      This report highlights the enterprise's aggregated current SDG performance
                      based on impact metrics and relative targets. The graphs show your
                      enterprise's aggregated recent performance on impact metrics close to its set
                      targets. All impact metrics in your IMM framework(s) are mapped to the SDGs
                      using the IRIS Catalog of Metrics.
                    </p>
                  </div>
                  <div className='row'>
                    {getPerformanceData().map((p: any, index: any) => (
                      <div className='col-6 mb-8'>
                        <div className='row'>
                          <div className='col-2 w-40px p-0'>
                            <Link to={`${prefix}/dashboard/sdg/${p.value}`} title={p.label}>
                              <img
                                alt='Logo'
                                src={toAbsoluteUrl(`/media/images/sdgs/${p.value}.png`)}
                                className='h-40px w-auto'
                                title={p.label}
                              />
                            </Link>
                          </div>
                          <div className='col-7 overflow-hidden pt-1'>
                            <span className='fw-bold fs-8 d-inline-block text-truncate overflow-hidden pb-1'>
                              {p.label}
                            </span>
                            <div className='progress h-6px w-100 bg-secondary overflow-hidden'>
                              <div
                                className='progress-bar '
                                role='progressbar'
                                style={{
                                  width: `${
                                    p.completed && p.completed != 'NaN' ? p.completed : 0.0
                                  }%`,
                                  backgroundColor: p.color,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className='col-2  fw-bold fs-6 text-start pt-2'>
                            {p.completed && p.completed != 'NaN' ? p.completed : '0.0'}%
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PrintComponent>
      )}
    </div>
  )
}

export default SDGPerformanceReport
