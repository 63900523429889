import {useContext} from 'react'
import {useParams} from 'react-router-dom'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
import AmChart from '../../shared/AmChart'
import SaveToImageButton from '../../shared/SaveToImageButton'

const DataCollectionMethods = () => {
  const {impactList} = useContext(DashboardContext)
  const {company = {}} = useContext(CompanyContext)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  const getData = () => {
    const data = impactList.reduce((acc: any, impact: any) => {
      impact.metrics.forEach((metric: any) => {
        metric.progress.forEach((p: any) => {
          p.channels.forEach((channel: any) => {
            acc[channel || 'No data available'] = (acc[channel || 'No data available'] || 0) + 1
          })
        })
      })

      return acc
    }, {})

    return Object.keys(data)
      .reduce(
        (acc: any, channel: any) => [
          ...acc,
          {
            category: channel,
            value: data[channel],
            link: `${prefix}/dashboard/data-collection/${channel}`,
          },
        ],
        []
      )
      .filter(({value}: any) => value)
  }

  return (
    <div className='card card-custom mb-7' id='data-collection-methods-chart'>
      <SaveToImageButton node='data-collection-methods-chart' />
      <div className='card-body'>
        <h3>Data Collection Methods</h3>
        <p className='fs-7 text-gray-600 mb-10'>
          This graph shows a distribution of how your enterprise is collecting data to measure
          progress on its impact metrics. Data collection methods can signal the validity, quality,
          and level of inference made using the data provided.
        </p>
        <AmChart data={getData()} labelTextType='circular' />
      </div>
    </div>
  )
}

export default DataCollectionMethods
