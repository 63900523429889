import clsx from 'clsx'
import moment from 'moment'
import {useContext} from 'react'
import {useParams} from 'react-router'
// import {Link} from 'react-router-dom'
// import {PageTitle} from '../../../../../_metronic/layout/core'
import {DashboardContext} from '../../../../context/DashboardContext'
import LineChart from '../../../shared/LineChart'

const CompanyMetricHistory = () => {
  const {impact_focus_id, metric_id}: any = useParams()

  const {impactList} = useContext(DashboardContext)

  const [metric] = impactList.reduce((acc: any, impact: any) => {
    if (impact.group_identifier === impact_focus_id) {
      impact.metrics.forEach((metric: any) => {
        if (metric.value == metric_id) acc.push(metric)
      })
    }
    return acc
  }, [])

  const data: any = []

  if (metric.progress && Array.isArray(metric.progress) && metric.progress.length > 0) {
    metric.progress.map((p: any) => {
      data.push({
        date: moment(p.period.endDate).format('YYYY-MM-DD'),
        value: p.currentPosition,
      })
    })

    data.push({
      date: moment(data[data.length - 1].date)
        .subtract('days', 1)
        .format('YYYY-MM-DD'),
      value: Number(metric.baseline.startPosition) || 0,
    })
  }

  const getPercentageCompleted = (value: any, total: any) => ((value / total) * 100).toFixed(1)

  return (
    <>
      <div className={clsx('py-10', {'ps-8 pe-3': data.length > 0})}>
        <div className='progress h-30px  w-100 bg-dark bg-opacity-10'>
          {metric.progress && metric.progress.length > 0 && (
            <div
              className={clsx('progress-bar bg-primary')}
              role='progressbar'
              style={{
                width: `${getPercentageCompleted(
                  metric.progress[0].currentPosition,
                  metric?.baseline.target
                )}%`,
              }}
              title={`Period: ${metric.progress[0].period.label} - ${getPercentageCompleted(
                metric.progress[0].currentPosition,
                metric?.baseline.target
              )}%`}
            >
              <span className='fw-bolder text-gray-800 progress-bar-indicator  progress-bar-indicator-top'>
                Completed{' '}
                {getPercentageCompleted(
                  metric.progress[0].currentPosition,
                  metric?.baseline.target
                )}
                %
              </span>
            </div>
          )}

          {/* <span className='fw-bolder text-gray-800 progress-bar-indicator progress-bar-indicator-last  '>
            Target / {metric.baseline.target}
          </span> */}
        </div>
      </div>

      {data.length > 0 && (
        <div className='col-12 mb-10'>
          <LineChart
            data={data}
            target={Number(metric.baseline.target)}
            // baseline={Number(metric.baseline.startPosition)}
          />
        </div>
      )}

      <div className='col-12 mb-15'>
        <div className='card p-0'>
          <div className='card-header '>
            <h3 className='fw-bolder mt-8 text-gray-800'>Provided Data</h3>
          </div>
          <div className='card-body p-0 pt-2'>
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-bordered align-middle gy-5 gs-7 '>
                {/* begin::Table head */}
                {data.length > 0 && (
                  <thead>
                    <tr className='fw-bolder text-dark'>
                      <th className='min-w-150px'>Period</th>
                      <th>Target</th>
                      <th>Progress</th>
                      <th>Percentage</th>
                      <th>Measurement Channels</th>
                    </tr>
                  </thead>
                )}
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data.length == 0 && (
                    <div className='text-gray-700 fw-bolder fs-7 p-5 pb-2 m-0 text-center'>
                      No data has been provided yet.{' '}
                    </div>
                  )}

                  {metric.progress &&
                    metric.progress.map((p: any, index: any) => (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bolder text-hover-primary fs-7 mb-1'>
                                {p.duration}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className='fw-bolder text-gray-600 d-block fs-7'>
                            {metric?.baseline.target} {metric?.baseline?.unit}
                          </span>
                        </td>
                        <td>
                          <span className='fw-bolder text-gray-600 d-block fs-7'>
                            {p.currentPosition} {metric?.baseline?.unit}
                          </span>
                        </td>
                        <td>
                          <span className='fw-bolder text-gray-600 d-block fs-7'>
                            {metric.baseline.target > 0 &&
                              ((p.currentPosition / metric?.baseline.target) * 100).toFixed(1)}
                            %{metric?.baseline.target <= 0 && '-'}
                          </span>
                        </td>
                        <td>
                          <span className='fw-bolder text-gray-600 d-block fs-7'>
                            {p.channels.length > 0 &&
                              p.channels.map(({value}: any) => value).join(', ')}
                            {p.channels.length === 0 && <>-</>}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyMetricHistory
