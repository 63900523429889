import {useContext} from 'react'
import {Link, NavLink, useParams} from 'react-router-dom'
import {AuthContext} from '../../../../context/AuthContext'

const CompanyNav = () => {
  const {user, isAdmin} = useContext(AuthContext)
  const {id, group_identifier, portfolio_id} = useParams<any>()
  const prefix = user.type === 'Investor' ? `` : ''

  return (
    <>
      <div className=' mb-8 bg-dark  pb-0 rounded overflow-hidden'>
        <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x  fw-bold fs-7' role='tablist'>
          <li className='nav-item m-0 me-1 p-0'>
            <NavLink
              to={`/company/${group_identifier}/dashboard`}
              className='nav-link cursor-pointer px-7 py-6 text-white m-0'
              role='tab'
            >
              Dashboard
            </NavLink>
          </li>
          <li className='nav-item m-0 me-1 p-0'>
            <NavLink
              to={`/company/${group_identifier}/info`}
              className='nav-link cursor-pointer px-7 py-6 text-white m-0'
              role='tab'
            >
              Company Info
            </NavLink>
          </li>
          <li className='nav-item m-0 me-1 p-0'>
            <NavLink
              to={`/company/${group_identifier}/impact-focus`}
              className='nav-link cursor-pointer px-7 py-6 text-white m-0'
              role='tab'
            >
              Impact Mgt
            </NavLink>
          </li>
          <li className='nav-item m-0 me-1 p-0'>
            <NavLink
              to={`/company/${group_identifier}/reports`}
              className='nav-link cursor-pointer px-7 py-6 text-white m-0'
              role='tab'
            >
              Reports
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  )
}

export default CompanyNav
