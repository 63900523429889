import React, {useState, useEffect} from 'react'
import {useParams, Redirect} from 'react-router-dom'
import {acceptPortfolioInvite} from '../../../../api'
import LoadingSpinner from '../../shared/LoadingSpinner'

const PortfolioInvitationAcceptance: React.FC = () => {
  const [doRedirect, setRedirect] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const {token} = useParams<any>()

  const alert = 'Invitation has been accepted. Please proceed to login'

  useEffect(() => {
    acceptPortfolioInvite(token).finally(() => {
      setLoading(false)
      setRedirect(true)
    })
  }, [])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {doRedirect && <Redirect to={{pathname: '/auth/login', state: {alert}}} />}
    </>
  )
}

export default PortfolioInvitationAcceptance
