import {useContext} from 'react'
import {Link, useParams} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
import PieChart from '../../shared/PieChart'

const DataCollectionDetails = () => {
  const {category} = useParams<any>() || {}

  const {impactList} = useContext(DashboardContext)
  const {company = {}} = useContext(CompanyContext)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  const dataMap = impactList.reduce((acc: any, impact: any) => {
    impact.metrics.forEach((metric: any) => {
      metric.progress.forEach((p: any) => {
        p.channels.forEach((channel: any) => {
          if (category === channel) {
            acc[impact.name] = {
              impact,
              value: (acc[impact.name]?.value || 0) + 1,
            }
          }
        })
      })
    })

    return acc
  }, {})

  const data = Object.keys(dataMap).reduce(
    (acc: any, category: any) => [
      ...acc,
      {
        category: category,
        value: dataMap[category].value,
        link: `${prefix}/impact-focus/${dataMap[category].impact.group_identifier}`,
      },
    ],
    []
  )

  return (
    <>
      <PageTitle>{`Data Collection Method - ${category}`}</PageTitle>

      <div className='card card-custom p-10'>
        <h2>Distribution by Impact Focus</h2>
        <p className='fw-bold text-gray-600'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className='d-flex  bg-white'>
          <PieChart data={data} />
        </div>
      </div>

      <div className=' card card-custom p-10 mt-10'>
        <h2>Data Collections Methods</h2>
        <p className='fw-bold text-gray-600'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>

        <div className='table-responsive '>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-200 align-middle gs-10 mb-0 pb-0'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-dark'>
                <th className='pt-3 pb-3 col-6'>Impact Focus</th>
                <th className='pt-3 pb-3 col-4'>Total Number</th>
                <th className='pt-3 pb-3 col-2'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}

            {data.map((impact: any) => (
              <>
                <tbody>
                  <tr>
                    <td>
                      <div className='d-flex justify-content-start flex-column py-1'>
                        <Link to={impact.link} className='fw-bold  fs-6'>
                          {impact?.category}
                        </Link>
                      </div>
                    </td>

                    <td>{impact?.value}</td>

                    <td>
                      <Link to={impact.link} className='fw-bold  fs-9'>
                        View Details
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </>
            ))}

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>
    </>
  )
}

export default DataCollectionDetails
