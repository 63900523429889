import clsx from 'clsx'
import {useContext, useEffect, useState} from 'react'
import {Link, useParams, useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
import PieChart from '../../shared/PieChart'
import {sdgs} from '../../shared/Constants'
import CurrentMetric from '../../shared/CurrentMetric'
import {getMetricPercentageCompleted, maybePluralize} from '../../shared/Helpers'

const ImpactDurationDetails = () => {
  const {category} = useParams<any>() || {}

  const {impactList} = useContext(DashboardContext)
  const {company = {}} = useContext(CompanyContext)

  const data = impactList.filter((impact: any) => impact.expectedDuration === category)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  return (
    <>
      <PageTitle>{`Expected Duration of Impact - ${category}`}</PageTitle>

      <div className=' card card-custom p-10 '>
        <h2>Impact Focuses ({`${category}`})</h2>
        <p className='fw-bold text-gray-600'>
          This graph shows an aggregated distribution of your enterprise's projection of the
          duration where stakeholders will experience the outcome of your impact focus.
        </p>

        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-200 align-middle gs-10 mb-0 pb-0'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Name</th>
                <th className='min-w-100px'> Duration of Impact</th>
                <th className='min-w-100px'>Development Goals</th>
                <th className='min-w-100px '>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {impactList.map((impact: any) => (
                <tr key={impact.group_identifier}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <Link
                          to={{
                            pathname: `${prefix}/impact-focus/${impact.group_identifier}`,
                            state: {impact},
                          }}
                          className='text-dark fw-bolder text-hover-primary fs-6 mb-1'
                        >
                          {impact.name}
                        </Link>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {maybePluralize(impact.goals.length, 'Goal')},{' '}
                          {maybePluralize(impact.metrics.length, 'Metric')}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className='fs-7 fw-bold'>{impact.expectedDuration}</td>

                  <td>
                    <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2'>
                      {impact.sdgs.map((sdg: any, index: any) => (
                        <Link
                          to={{
                            pathname: `${prefix}/dashboard/sdg/${sdg}`,
                          }}
                          className='symbol symbol-circle symbol-20px'
                          key={`sgds-${impact.group_identifier}-${index}`}
                        >
                          <img
                            alt='Logo'
                            src={toAbsoluteUrl(`/media/images/sdgs/${sdg}.png`)}
                            className='h-40px w-auto'
                            title={`${sdgs.find((s: any) => s.value == sdg)?.label}`}
                          />
                        </Link>
                      ))}
                    </div>
                  </td>

                  <td>
                    <div className='d-flex justify-content-start flex-shrink-0'>
                      <Link
                        to={{
                          pathname: `${prefix}/impact-focus/${impact.group_identifier}`,
                          state: {impact},
                        }}
                        className='text-color-primary'
                      >
                        View Details
                        {/* <KTSVG
                        path='/media/icons/duotone/General/Settings-1.svg'
                        className='svg-icon-3'
                      /> */}
                      </Link>
                      {/* <Link
                      to={{pathname: `${impact.link}/edit`, state: {impact}}}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotone/Communication/Write.svg'
                        className='svg-icon-3'
                      />
                    </Link> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>
    </>
  )
}

export default ImpactDurationDetails
