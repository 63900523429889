import {useContext} from 'react'
import {DashboardContext} from '../../../context/DashboardContext'
import PieChart from '../../shared/PieChart'
import BarChart from '../../shared/BarChart'
import {CompanyContext} from '../../../context/CompanyContext'
import {useParams} from 'react-router-dom'
import SaveToImageButton from '../../shared/SaveToImageButton'

const PeopleEnvironment = () => {
  const {sdgPerformance} = useContext(DashboardContext)
  const {company = {}} = useContext(CompanyContext)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  const getValueByCategory = (category: any) => {
    const arr = sdgPerformance.filter((s: any) => s.category === category)
    return Number((arr.reduce((acc: any, s: any) => acc + s.completed, 0) / arr.length).toFixed(1))
  }

  const data = [
    {
      category: 'People',
      value: getValueByCategory('People'),
      color: '#e6194b',
      link: `${prefix}/dashboard/people-environment/People`,
    },
    {
      category: 'Environment',
      value: getValueByCategory('Environment'),
      color: '#3cb44b',
      link: `${prefix}/dashboard/people-environment/Environment`,
    },
  ]

  return (
    <div className='card card-custom mb-7' id='people-environment-chart'>
      <SaveToImageButton node='people-environment-chart' />
      <div className='card-body'>
        <h3>People vs Environment</h3>
        <p className='fs-7 text-gray-600 mb-10'>
          This graph shows a distribution of your enterprise's focus based on two pillars -
          environment and planet. All impact metrics in your IMM framework(s) are mapped to the
          stakeholder(s) - people or planet using the IRIS Catalog of Metrics.
        </p>
        <PieChart data={data} labelTextType='circular' useDataColor={true} />
      </div>
    </div>
  )
}

export default PeopleEnvironment
