export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      const invitedMembers = JSON.parse(localStorage.getItem('invitedMembers') || '[]')
      const user = JSON.parse(localStorage.getItem('user') || '{}')
      const member = invitedMembers.find((m: any) => m.email === user.email && m.confirmed)

      if (member) {
        config.headers.Authorization = `Bearer ${member.access_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
