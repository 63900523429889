const CurrentMetric = ({currentPosition = 0, baseline = 0, target = 0, color, unit = ''}: any) => {
  const getPercentageCompleted = () => {
    if (target > 0 && target - baseline !== 0) {
      const completed = ((currentPosition - baseline) / (target - baseline)) * 100
      if (completed > 100) return 100
      if (completed < 0) return 0
      return completed.toFixed(1)
    }

    return 0
  }

  return (
    <div className='d-flex '>
      <span className='text-gray-500 fw-bolder fs-8'>
        {currentPosition} {unit}
      </span>

      <div className='flex-grow-1 text-center mx-2' style={{marginTop: -12}}>
        <span className='fw-bolder fs-10 text-gray-700'>{getPercentageCompleted()}%</span>
        <div className='progress h-6px bg-secondary '>
          <div
            className='progress-bar bg-success'
            role='progressbar'
            style={{width: `${getPercentageCompleted()}%`}}
            title={`${getPercentageCompleted()}% completed`}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default CurrentMetric
