import {useContext, useEffect, useState} from 'react'
import {getCompanyIMP} from '../../../../api'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
import {impScaleCategory} from '../../shared/Constants'
import LoadingSpinner from '../../shared/LoadingSpinner'
import SaveToImageButton from '../../shared/SaveToImageButton'
import IMPScale from './IMPScale'

const IMPReportSummary = () => {
  const {company = {}} = useContext(CompanyContext)
  const [isLoading, setLoading] = useState(true)
  const [impData, setImpData] = useState<any>([])

  useEffect(() => {
    getCompanyIMP(company.group_identifier)
      .then(({data = []}) => {
        setImpData(data)
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }, [])

  return (
    <div className='card card-custom mb-10' id='imp-report'>
      <SaveToImageButton node='imp-report' />

      <div className='card-body'>
        <h3>IMP Assessment</h3>
        <p className='fs-7 text-gray-600'>
          This graph shows your enterprise's aggregated impact based on current performance across
          the IMP's Five Dimensions of Impact core metrics in your IMM framework(s) and embedded
          calculations.
        </p>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <div className=''>
            {impData.map((data: any) => (
              <IMPScale {...data} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default IMPReportSummary
