import {useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'

const GetStarted = () => {
  const [showAlert, setShowAlert] = useState(true)

  return (
    <>
      {showAlert && (
        <div className='col-12 mb-10'>
          <div className='alert alert-dismissible bg-info d-flex flex-column flex-sm-row ps-6 pt-8 pe-15 m-0 pb-10'>
            <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative me-5'>
              <KTSVG
                path='/media/icons/duotone/Layout/Layout-polygon.svg'
                className='svg-icon-white position-absolute opacity-30'
                svgClassName='h-80px w-80px'
              />
              <KTSVG
                path='/media/icons/duotone/Home/Building.svg'
                className='svg-icon-3x svg-icon-info position-absolute'
              />
            </div>

            <div className='d-flex flex-column text-light pe-0'>
              <h3 className='mb-2 text-light'>Get Started</h3>
              <span>
                Welcome to the UNDP, Egypt platform. You currently do not have any company setup on
                your account. Creating a company is the first step to experiencing the full
                functionality for the platform. Click on the button below to start the process.
              </span>
              <div className='mt-5'>
                <Link to='/company/new'>
                  <button type='button' className='btn btn-bg-primary text-light px-7 me-3'>
                    Create A Company
                  </button>
                </Link>
                <button
                  type='button'
                  className='btn fw-normal fs-7 btn-link text-light'
                  onClick={() => setShowAlert(false)}
                >
                  No, thanks
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default GetStarted
