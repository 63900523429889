import {KTSVG} from '../../../../_metronic/helpers'

const ReportHeader = ({company, portfolio, state}: any) => (
  <div className='d-flex w-100 bg-dark p-10  text-white '>
    <div className='me-7'>
      <div className='symbol symbol-100px symbol-lg-75px symbol-fixed position-relative'>
        {/* <img src={toAbsoluteUrl('/media/stock/600x600/img-22.jpg')} alt='Metornic' /> */}
      </div>
    </div>

    <div className='flex-grow-1 d-flex justify-content-between align-items-start flex-wrap mb-0'>
      <div className='d-flex flex-column'>
        <div className='text-white  fs-7 fw-bold mb-2'>
          {state.reportType === 'cummulative' && 'Cummulative Report'}
          {state.reportType === 'portfolio' && portfolio && `Portfolio - ${portfolio.name}`}
        </div>

        <div className='d-flex align-items-center mb-1'>
          <span className='text-white text-hover-primary fs-3 fw-bolder me-1'>
            {company.company_name || company.name}
          </span>
        </div>

        <div className='d-flex flex-column fw-bold fs-7  pe-2'>
          <div className='d-flex align-items-center text-white text-hover-primary mb-1'>
            <KTSVG
              path='/media/icons/duotone/Communication/Active-call.svg'
              className='svg-icon-4 me-1'
            />
            {company?.company_phone_number || company?.phone_number}
            <KTSVG
              path='/media/icons/duotone/Communication/Incoming-box.svg'
              className='svg-icon-4 ms-3 me-1'
            />
            {company?.company_email || company?.email}
          </div>

          <div className='d-flex align-items-center text-white text-hover-primary me-5'>
            <KTSVG path='/media/icons/duotone/Map/Marker1.svg' className='svg-icon-4 me-1' />
            {company?.location || company?.company_location?.value}, Egypt
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ReportHeader
