import React, {useContext, useEffect} from 'react'
import {Link, Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {ImpactMeasurement} from '../../../../_metronic/partials/modals/impact-measurement/ImpactMeasurement'
import {CompanyContext} from '../../../context/CompanyContext'
import DataCollectionDetails from '../../dashboard/components/DataCollectionDetails'
import ImpactDurationDetails from '../../dashboard/components/ImpactDurationDetails'
import IMPReportDetails from '../../dashboard/components/IMPReportDetails'
import LocationFundingDetails from '../../dashboard/components/LocationFundingDetails'
import PeopleEnvironmentDetails from '../../dashboard/components/PeopleEnvironmentDetails'
import RiskLevelsDetails from '../../dashboard/components/RiskLevelsDetails'
import RiskProfileDetails from '../../dashboard/components/RiskProfileDetails'
import SdgMetricsDetails from '../../impact-focus/components/SdgMetricsDetails'
import ImpactRawData from '../../reports/components/ImpactDataReport'
import ImpactInvestmentReport from '../../reports/components/ImpactInvestmentReport'
import ImpactObjectivesReport from '../../reports/components/ImpactObjectivesReport'
import ImpAssessmentReport from '../../reports/components/ImpAssessmentReport'
import MetricsPerformanceHistory from '../../reports/components/MetricsPerformanceHistoryReport'
import SDGPerformanceDataReport from '../../reports/components/SDGPerformanceDataReport'
import SDGPerformanceReport from '../../reports/components/SDGPerformanceReport'
import CompaniesList from './components/CompaniesList'
import CompanyDashboard from './components/CompanyDashboard'
import CompanyDetails from './components/CompanyDetails'
import {CompanyImpactFocus} from './components/CompanyImpactFocus'
import {CompanyImpactFocusDetails} from './components/CompanyImpactFocusDetails'
import CompanyMetricHistory from './components/CompanyMetricHistory'
import CompanyNav from './components/CompanyNav'
import CompanyReports from './components/CompanyReports'

const CompaniesPage: React.FC = () => {
  const {company = {}} = useContext(CompanyContext)

  return (
    <Switch>
      <Route exact path='/company'>
        <PageTitle>Enterprise</PageTitle>
        <CompaniesList />
      </Route>

      <Route path='/company/:group_identifier'>
        <PageTitle>{company?.company_name}</PageTitle>
        <CompanyNav />

        <Switch>
          <Redirect
            from='/company/:group_identifier'
            exact={true}
            to='/company/:group_identifier/dashboard'
          />

          <Route exact path='/company/:group_identifier/info'>
            <PageTitle>{company?.company_name}</PageTitle>

            <CompanyDetails />
          </Route>

          <Route exact path='/company/:group_identifier/impact-focus'>
            <PageTitle>{company?.company_name}</PageTitle>

            <CompanyImpactFocus />
          </Route>

          <Route exact path='/company/:group_identifier/dashboard'>
            <PageTitle>{company?.company_name}</PageTitle>

            <CompanyDashboard />
          </Route>

          <Route exact path='/company/:group_identifier/reports'>
            <PageTitle>{company?.company_name}</PageTitle>

            <CompanyReports />
          </Route>

          <Route exact path='/company/:group_identifier/impact-focus/:impact_focus_id'>
            <PageTitle>{company?.company_name}</PageTitle>

            <CompanyImpactFocusDetails />
          </Route>

          <Route
            exact
            path='/company/:group_identifier/impact-focus/:impact_focus_id/metrics/:metric_id'
          >
            <PageTitle>{company?.company_name}</PageTitle>

            <CompanyMetricHistory />
          </Route>

          <Route exact path='/company/:group_identifier/reports/imp-assessment-report'>
            <PageTitle>{company?.company_name}</PageTitle>

            <ImpAssessmentReport />
          </Route>

          <Route exact path='/company/:group_identifier/reports/impact-investment-report'>
            <PageTitle>{company?.company_name}</PageTitle>

            <ImpactInvestmentReport />
          </Route>

          <Route exact path='/company/:group_identifier/reports/sdg-performance-report'>
            <PageTitle>{company?.company_name}</PageTitle>

            <SDGPerformanceReport />
          </Route>

          <Route exact path='/company/:group_identifier/reports/sdg-performance-data'>
            <PageTitle>{company?.company_name}</PageTitle>

            <SDGPerformanceDataReport />
          </Route>

          <Route
            exact
            path='/company/:group_identifier/reports/impact-measurement-accountability-report'
          >
            <PageTitle>{company?.company_name}</PageTitle>

            <ImpactMeasurement />
          </Route>

          <Route exact path='/company/:group_identifier/reports/impact-objectives-report'>
            <PageTitle>{company?.company_name}</PageTitle>

            <ImpactObjectivesReport />
          </Route>

          <Route exact path='/company/:group_identifier/reports/impact-data-report'>
            <PageTitle>{company?.company_name}</PageTitle>

            <ImpactRawData />
          </Route>

          <Route exact path='/company/:group_identifier/reports/metrics-performance-history'>
            <PageTitle>{company?.company_name}</PageTitle>

            <MetricsPerformanceHistory />
          </Route>

          {/* Dashboard Links */}
          <Route exact path='/company/:group_identifier/dashboard/people-environment/:category'>
            <PageTitle>{company?.company_name}</PageTitle>

            <PeopleEnvironmentDetails />
          </Route>

          <Route exact path='/company/:group_identifier/dashboard/data-collection/:category'>
            <PageTitle>{company?.company_name}</PageTitle>

            <DataCollectionDetails />
          </Route>

          <Route exact path='/company/:group_identifier/dashboard/impact-duration/:category'>
            <PageTitle>{company?.company_name}</PageTitle>

            <ImpactDurationDetails />
          </Route>

          <Route exact path='/company/:group_identifier/dashboard/risk-levels/:category'>
            <PageTitle>{company?.company_name}</PageTitle>

            <RiskLevelsDetails />
          </Route>

          <Route exact path='/company/:group_identifier/dashboard/location-funding/:category'>
            <PageTitle>{company?.company_name}</PageTitle>

            <LocationFundingDetails />
          </Route>

          <Route exact path='/company/:group_identifier/dashboard/risk-profile/:category'>
            <PageTitle>{company?.company_name}</PageTitle>

            <RiskProfileDetails />
          </Route>

          <Route exact path='/company/:group_identifier/dashboard/imp/:category'>
            <IMPReportDetails />
          </Route>

          <Route exact path='/company/:group_identifier/dashboard/sdg/:sdg_number'>
            <SdgMetricsDetails />
          </Route>
        </Switch>
      </Route>
    </Switch>
  )
}

export default CompaniesPage
