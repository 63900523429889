/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {StepperComponent} from '../../../assets/ts/components'

interface ICreateAccount {
  description: string
  category: string
  framework: string
  dbName: string
  dbType: string
  nameOnCard: string
  cardNumber: string
  cardExpiryMonth: string
  cardExpiryYear: string
  cardCvv: string
  saveCard: string
}

const inits: ICreateAccount = {
  description: '',
  category: '1',
  framework: '1',
  dbName: '',
  dbType: '1',
  nameOnCard: '',
  cardNumber: '4111 1111 1111 1111',
  cardExpiryMonth: '1',
  cardExpiryYear: '2',
  cardCvv: '123',
  saveCard: '1',
}

const createAppSchema = [
  Yup.object({
    description: Yup.string().required().label('App name'),
    category: Yup.string().required().label('Category'),
  }),
  Yup.object({
    framework: Yup.string().required().label('Framework'),
  }),
  Yup.object({
    dbName: Yup.string().required().label('Database name'),
    dbType: Yup.string().required().label('Database engine'),
  }),
  Yup.object({
    nameOnCard: Yup.string().required().label('Name'),
    cardNumber: Yup.string().required().label('Card Number'),
    cardExpiryMonth: Yup.string().required().label('Expiration Month'),
    cardExpiryYear: Yup.string().required().label('Expiration Year'),
    cardCvv: Yup.string().required().label('CVV'),
  }),
]

const ImpactFocus: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0])
  const [initValues] = useState<ICreateAccount>(inits)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    window.scrollTo(0, 0)

    if (!stepper.current) {
      return
    }

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex])

    stepper.current.goNext()
    // if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
    //   stepper.current.goNext()
    // } else {
    //   stepper.current.goto(1)
    //   actions.resetForm()
    // }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='modal fade' id='kt_modal_impact_focus' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-900px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>New Impact Focus</h2>

            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            <div
              ref={stepperRef}
              className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
              id='kt_modal_impact_focus_stepper'
            >
              <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
                <div className='stepper-nav ps-lg-10'>
                  <div className='stepper-item current' data-kt-stepper-element='nav'>
                    <div className='stepper-line w-40px'></div>

                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Details</h3>

                      <div className='stepper-desc'>Name your Impact Focus</div>
                    </div>
                  </div>

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-line w-40px'></div>

                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>What</h3>

                      <div className='stepper-desc'>Define the problem</div>
                    </div>
                  </div>

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-line w-40px'></div>

                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>3</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Who</h3>

                      <div className='stepper-desc'>Specify the target audience</div>
                    </div>
                  </div>

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-line w-40px'></div>

                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>4</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>How Much</h3>

                      <div className='stepper-desc'>Specify target metrics</div>
                    </div>
                  </div>

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-line w-40px'></div>

                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>5</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Contribution</h3>

                      <div className='stepper-desc'>Review impact focus</div>
                    </div>
                  </div>

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-line w-40px'></div>

                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>6</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Risk</h3>

                      <div className='stepper-desc'>What are the impact risk</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex-row-fluid py-lg-5 px-lg-15'>
                <Formik
                  // validationSchema={currentSchema}
                  initialValues={initValues}
                  onSubmit={submitStep}
                >
                  {() => (
                    <Form className='form' noValidate id='kt_modal_impact_focus_form'>
                      <div className='current' data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span className='required'>Name of Impact Focus</span>
                              <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Specify your unique app name'
                              ></i>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              name='description'
                              placeholder=''
                            />
                            <div className='fv-plugins-message-container invalid-feedback'>
                              <ErrorMessage name='description' />
                            </div>
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                              <span>Brief Description</span>
                            </label>

                            <Field
                              type='textarea'
                              className='form-control form-control-lg form-control-solid'
                              name='description'
                              placeholder=''
                            />
                            <div className='fv-plugins-message-container invalid-feedback'>
                              <ErrorMessage name='description' />
                            </div>
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>Which Impact Category best describes your area of focus?</span>
                            </label>

                            <select className='form-select form-select-solid fs-5 '>
                              <option>Choose Category</option>
                              <option value={'Investee'}>Enterprise</option>
                              <option value={'Investor'}>Fund Manager</option>
                            </select>

                            <div className='fv-plugins-message-container invalid-feedback'>
                              <ErrorMessage name='description' />
                            </div>
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>Which Impact Theme aligns with your impact priorities?</span>
                            </label>

                            <select className='form-select form-select-solid fs-5 '>
                              <option>Choose Theme</option>
                            </select>

                            <div className='fv-plugins-message-container invalid-feedback'>
                              <ErrorMessage name='description' />
                            </div>
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>Which Strategic Goal(s) best matches your approach?</span>
                            </label>

                            <select className='form-select form-select-solid fs-5 '>
                              <option>Choose Goals</option>
                            </select>

                            <div className='fv-plugins-message-container invalid-feedback'>
                              <ErrorMessage name='description' />
                            </div>
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                Do you have funding allocated to the attainment of the impact focus?
                              </span>
                            </label>

                            <select className='form-select form-select-solid fs-5 '>
                              <option>No</option>
                              <option>Yes</option>
                            </select>
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>How much funding is allocated to the impact focus?</span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>When do you expect to have attained the impact focus?</span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='att'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                What impact assessment metrics do you want to use to track progress
                                of the impact focus?
                              </span>
                            </label>

                            <select className='form-select form-select-solid fs-5 '>
                              <option>Choose Metrics Assessment</option>
                            </select>
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                How often do you want to track progress with the impact assessment
                                metrics?
                              </span>
                            </label>

                            <select className='form-select form-select-solid fs-5 '>
                              <option>Monthly</option>
                              <option>Quarterly</option>
                              <option>Semi-annually</option>
                              <option>Annually</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>How much funding is allocated to the impact focus?</span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund1'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>When do you expect to have attained the impact focus?</span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='att1'
                            />
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                Who is helped through investments aligned with this Strategic Goal?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                2. What demographics best describes your direct target stakeholders.
                                Tick All that Apply
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='att'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                3. What demographics best describes your indirect target
                                stakeholders. Tick All that Apply
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                What are the geographic attributes of those who are affected?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='att'
                            />
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='pb-10 pb-lg-10'>
                            <h2 className='fw-bolder text-dark'>How Much</h2>

                            <div className='text-gray-400 fw-bold fs-6'>
                              If you need more info, please check out &nbsp;
                              <a href='#' className='text-primary fw-bolder'>
                                Help Page
                              </a>
                              .
                            </div>
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                How many target stakeholders can directly experience the outcome
                                through your company's investments aligned with this Strategic Goal?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                How much change can direct target stakeholders experience through
                                your company's investments aligned with this Strategic Goal?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='att'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                Which direct target stakeholders will experience a positive impact
                                through your company's investments alligned with this Strategic
                                Goal?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                Which direct target stakeholders will expreince a negative impact
                                through your company's investments alligned with this Strategic
                                Goal?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='att'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                How many target stakeholders can indirectly experience the outcome
                                through your company's investments aligned with this Strategic Goal?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                Which indirect target stakeholders will expreince a positive impact
                                through your company's investments alligned with this Strategic
                                Goal?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                Which indirect target stakeholders will experience a negative impact
                                through your company's investments aligned with this Strategic Goal?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='att'
                            />
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='pb-10 pb-lg-10'>
                            <h2 className='fw-bolder text-dark'>Contribution</h2>

                            <div className='text-gray-400 fw-bold fs-6'>
                              If you need more info, please check out &nbsp;
                              <a href='#' className='text-primary fw-bolder'>
                                Help Page
                              </a>
                              .
                            </div>
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                How can your company's investments in line with this Strategic Goal
                                contribute to outcomes?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                Are these investments’ effects likely better than, worse than, the
                                same as what would happen otherwise?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='att'
                            />
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='pb-10 pb-lg-10'>
                            <h2 className='fw-bolder text-dark'>Risk</h2>

                            <div className='text-gray-400 fw-bold fs-6'>
                              If you need more info, please check out &nbsp;
                              <a href='#' className='text-primary fw-bolder'>
                                Help Page
                              </a>
                              .
                            </div>
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                What impact risks do your company's investments aligned with this
                                Strategic Goal run?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                How is this risk related to your company's investments aligned with
                                this Strategic Goal?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='att'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                For every selected risk, what is likelihood of materialising?{' '}
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                For every selected risk, what is the severity of the consequence on
                                people and planet?{' '}
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='att'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                How do you intend to mitigate the imapct risks of your company's
                                investments aligned with this Strategic Goal run?{' '}
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>
                        </div>
                      </div>

                      <div className='d-flex flex-stack pt-10'>
                        <div className='me-2'>
                          <button
                            onClick={prevStep}
                            type='button'
                            className='btn btn-lg btn-light-primary me-3'
                            data-kt-stepper-action='previous'
                          >
                            <KTSVG
                              path='/media/icons/duotone/Navigation/Left-2.svg'
                              className='svg-icon-4 me-1'
                            />
                            Back
                          </button>
                        </div>

                        <div>
                          <button type='submit' className='btn btn-lg btn-primary me-3'>
                            <span className='indicator-label'>
                              {stepper.current?.currentStepIndex !==
                                stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                              {stepper.current?.currentStepIndex ===
                                stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                              <KTSVG
                                path='/media/icons/duotone/Navigation/Right-2.svg'
                                className='svg-icon-3 ms-2 me-0'
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ImpactFocus}
