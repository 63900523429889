import React from 'react'
import {Route, Switch} from 'react-router-dom'
import AccountActivation from './components/AccountActivation'
import PortfolioInvitationAcceptance from './components/PortfolioInvitationAcceptance'

const ActivationPage: React.FC = () => {
  return (
    <Switch>
      {/* <Route exact path='/activate/email'>
        <EmailTemplate />
      </Route> */}

      <Route exact path='/activate/:token'>
        <AccountActivation />
      </Route>

      <Route exact path='/activate/portfolio-invite/:token'>
        <PortfolioInvitationAcceptance />
      </Route>
    </Switch>
  )
}

export default ActivationPage
