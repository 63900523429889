import {useContext} from 'react'
import {DashboardContext} from '../../../context/DashboardContext'
// import PieChart from '../../shared/PieChart'
// import {sdgs} from '../../shared/Constants'
// import AmChart from '../../shared/AmChart'
import {CompanyContext} from '../../../context/CompanyContext'
import {useParams} from 'react-router-dom'
import {parseJsonString} from '../../shared/Helpers'
import AmChart3D from '../../shared/AmChart3D'
import SaveToImageButton from '../../shared/SaveToImageButton'

export const ImpactFocusCharts = () => {
  const {impactList, sdgPerformance} = useContext(DashboardContext)
  const {company = {}} = useContext(CompanyContext)
  const prioritisedSdgs: any = []

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  const data = sdgPerformance
    .map((sdg: any) => ({
      ...sdg,
      category: (parseJsonString(company.sdgs) || []).includes(sdg.value)
        ? `${sdg.label}**`
        : sdg.label,
      link: `${prefix}/dashboard/sdg/${sdg.value}`,
      color: sdg.color,
      value: impactList.reduce(
        (acc: any, impact: any) => (acc += impact.sdgs.filter((i: any) => i == sdg.value).length),
        0
      ),
      // completed: sdgPerformance.find((s:any)=> s.value === sdg.value)?.completed
    }))

    .filter((item: any) => item.value > 0)

  // console.log('My data here >> ', data, parseJsonString(company.sdgs))

  return (
    <div className='card card-custom'>
      {/* <div className='card-header card-header-stretch'>
        <div className='card-title d-flex align-items-center'>
          <h3 className='fw-bolder m-0 text-gray-800'>Summary</h3>
        </div>
      </div> */}

      <div className='card-body ' id='distribution-chart'>
        <SaveToImageButton node='distribution-chart' />
        <div className='row'>
          <div className='col-12 text-center pt-10'>
            {/* {isLoading && <LoadingChart />} */}
            {/* {!isLoading && ( */}
            <div className='text-center'>
              <h2>Impact Per Sustainable Developmental Goals</h2>
              <p className='fw-bold text-gray'>Prioritised Sustainable Goals **</p>
            </div>
            {/* )} */}
            <AmChart3D data={data} useDataColor={true} />
          </div>
        </div>
      </div>
    </div>
  )
}
