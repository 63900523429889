import {useContext} from 'react'
import {DashboardContext} from '../../../context/DashboardContext'
import {PortfolioContext} from '../../../context/PortfolioContext'
import PieChart from '../../shared/PieChart'
import BarChart from '../../shared/BarChart'
import {CompanyContext} from '../../../context/CompanyContext'
import AmChart from '../../shared/AmChart'
import {useParams} from 'react-router-dom'
import SaveToImageButton from '../../shared/SaveToImageButton'

const FundingByImpact = () => {
  const {impactList} = useContext(DashboardContext)
  const {company = {}} = useContext(CompanyContext)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  const dataMap = impactList.reduce((acc: any, impact: any) => {
    impact.location.forEach((loc: any) => {
      acc[loc] = acc[loc] || 0 + impact.funding
    })

    return acc
  }, {})

  const data = Object.keys(dataMap)
    .reduce(
      (acc: any, category: any) => [
        ...acc,
        {
          category,
          value: dataMap[category],
          link: `${prefix}/dashboard/location-funding/${category}`,
        },
      ],
      []
    )

    .filter(({value}: any) => value)

  return (
    <div className='card card-custom mb-7' id='funding-impact-chart'>
      <SaveToImageButton node='funding-impact-chart' />

      <div className='card-body'>
        <h3>Funding by Impact Location</h3>
        <p className='fs-7 text-gray-600 mb-10'>
          This graph shows a distribution of how your enterprise's funding is allocated based on the
          location of stakeholders who are to benefit from the outcomes.
        </p>
        <AmChart data={data} height={380} labelTextType='circular' />
      </div>
    </div>
  )
}

export default FundingByImpact
