import _ from 'lodash'

import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import {parseJsonString} from './Helpers'

export function Section({
  heading,
  questions,
  collapsed = true,
  showHeader = true,
  loading = false,
  editLink,
  showCompletedStatus = false,
}: any) {
  const [visible, setVisible] = useState(!collapsed)

  const completedQuestions = () => {
    let completed = true

    for (const question of questions) {
      if (!question.answer) {
        completed = false
        break
      }
      if (
        !Array.isArray(question.answer) &&
        (question.answer.value === null || question.answer.value === '')
      ) {
        completed = false
        break
      }

      if (
        Array.isArray(question.answer) &&
        (question.answer.map((a: any) => a.value).includes('') ||
          question.answer.map((a: any) => a.value).includes(null))
      ) {
        completed = false
        break
      }
    }

    return completed
  }

  return (
    <div className='card card-custom mb-15'>
      {showHeader && (
        <div className='card-header'>
          <h3 className='card-title'>
            {heading}{' '}
            {/* {showCompletedStatus && !completedQuestions() && (
              <span className='fs-9 bg-warning d-inline-block px-2 p-1 mt-1 ms-3'>Incomplete</span>
            )} */}
          </h3>

          <div className='card-toolbar'>
            {/* <Link to={`/company/${company.id}/edit`}> */}

            {loading && (
              <span className='indicator-progress text-center ' style={{display: 'block'}}>
                <span className='spinner-border spinner-border-sm text-gray-500'></span>
              </span>
            )}

            {!loading && !editLink && (
              <button
                type='button'
                className='btn btn-sm btn-light fs-8'
                onClick={() => setVisible(!visible)}
              >
                {visible ? 'Collapse' : 'Show'}
              </button>
            )}

            {!loading && editLink && (
              <Link to={editLink} className='btn btn-sm btn-light fs-8'>
                Edit
              </Link>
            )}

            {/* </Link> */}
          </div>
        </div>
      )}
      {!loading && visible && (
        <div className='card-body px-10 py-0 '>
          {questions.map((question: any) => (
            <div key={question.id} className='fs-6 p-5' style={{borderBottom: '1px solid #EFF2F5'}}>
              {/* <span className='fs-9'>
                --- {question.answer_type} {question.parent_id} ---
              </span> */}

              {question.answer_type !== 'multi-display-select' && (
                <div className='fs-9 text-gray-500 fw-bold mb-2'>{question.question}</div>
              )}

              <div className='text-dark fw-bolder fs-6'>
                {question.answer && (
                  <>
                    {question.answer_type === 'multi-display-select' && (
                      <div className='row pt-5'>
                        {(parseJsonString(question.answer.value) || []).map((a: any) => (
                          <>
                            <div className='col mb-8'>
                              <img
                                alt=''
                                src={toAbsoluteUrl(`/media/images/${a}.png`)}
                                className='h-150px w-auto'
                              />
                            </div>
                          </>
                        ))}
                      </div>
                    )}

                    {(question.answer_type === 'dropdown' ||
                      question.answer_type === 'multi-select') &&
                      !question.answer_option &&
                      question.table &&
                      question.table.table_names &&
                      Array.isArray(question.table.table_names) &&
                      question.table.table_names.length > 0 &&
                      question.table.table_names[0].table_results.map((option: any, index: any) => (
                        <>
                          {!Array.isArray(parseJsonString(question.answer.value)) &&
                            option.id == question.answer.value &&
                            option.value}
                          {Array.isArray(parseJsonString(question.answer.value)) &&
                            parseJsonString(question.answer.value)
                              .map((a: any) => a)
                              .includes(option.id) && <li className='mb-1'>{option.value}</li>}
                        </>
                      ))}

                    {(question.answer_type === 'dropdown' ||
                      question.answer_type === 'multi-select') &&
                      question.answer_option && (
                        <>
                          {(parseJsonString(question.answer_option) || []).map((option: any) => (
                            <>
                              {!Array.isArray(parseJsonString(question.answer.value)) &&
                                option.key == question.answer.value &&
                                option.value}

                              {Array.isArray(parseJsonString(question.answer.value)) &&
                                parseJsonString(question.answer.value)
                                  .map((a: any) => a)
                                  .includes(option.key) && <li className='mb-1'>{option.value}</li>}
                            </>
                          ))}
                        </>
                      )}

                    {(question.answer_type === 'dropdown' ||
                      question.answer_type === 'multi-select') &&
                      !question.answer_option &&
                      !question.table &&
                      question.answer && (
                        <>
                          {!Array.isArray(parseJsonString(question.answer.value)) &&
                            question.answer &&
                            question.answer.value}

                          {Array.isArray(parseJsonString(question.answer.value)) &&
                            parseJsonString(question.answer.value).map(
                              (a: any) => a && <li className='mb-1'>{_.startCase(_.toLower(a))}</li>
                            )}
                        </>
                      )}

                    {(question.answer_type === 'text-field' ||
                      question.answer_type === 'radio' ||
                      question.answer_type === 'currency' ||
                      question.answer_type === 'date' ||
                      question.answer_type === 'date-range' ||
                      question.answer_type === 'text-area') &&
                      !question.parent_id && <>{question.answer.value}</>}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
