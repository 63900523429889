import {useEffect, useRef} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import _ from 'lodash'
import {useHistory} from 'react-router'
import {alphaHex} from './Helpers'

am4core.useTheme(am4themes_animated)

const AmChart = ({data = [], useDataColor = false, useAlpha = false, height = 350}: any) => {
  const history = useHistory()
  const chartRef = useRef<any>(null)

  const CHART_ID = _.uniqueId('chart-')

  useEffect(() => {
    if (!chartRef.current) {
      chartRef.current = am4core.create(CHART_ID, am4charts.PieChart)

      chartRef.current.data = useAlpha ? data.sort((a: any, b: any) => b.value - a.value) : data

      chartRef.current.innerRadius = am4core.percent(40)

      // let title = chartRef.current.titles.create()
      // title.text = 'Development Goals'
      // title.fontSize = 25
      // title.marginBottom = 20

      // Add and configure Series
      let pieSeries = chartRef.current.series.push(new am4charts.PieSeries())
      pieSeries.dataFields.value = 'value'
      pieSeries.dataFields.category = 'category'
      pieSeries.dataFields.link = 'link'
      // pieSeries.slices.template.stroke = am4core.color('#fff')
      // pieSeries.slices.template.strokeWidth = 2
      // pieSeries.slices.template.strokeOpacity = 1

      pieSeries.labels.template.maxWidth = 90
      pieSeries.labels.template.fontSize = 11
      pieSeries.labels.template.wrap = true

      if (useDataColor)
        pieSeries.colors.list = data.map((item: any, index: any) => {
          const alphaValue = alphaHex()[100 - Math.floor(index * (100 / (data.length + 2)))]
          return am4core.color(`${item.color}${useAlpha ? alphaValue : ''}`)
        })

      if (!useDataColor) {
        pieSeries.colors.list = [
          am4core.color('#7C3697'),
          am4core.color('#F15523'),
          am4core.color('#3D5EAC'),
          am4core.color('#62B146'),
          am4core.color('#D0DD37'),
          am4core.color('#F9BD19'),
          am4core.color('#F99B1E'),
          am4core.color('#1496CE'),
          am4core.color('#EF3224'),
          am4core.color('#F3EC3A'),
          am4core.color('#A71E48'),

          am4core.color('#463191'),

          am4core.color('#e6194b'),
          am4core.color('#3cb44b'),
          am4core.color('#ffe119'),
          am4core.color('#4363d8'),
          am4core.color('#f58231'),
          am4core.color('#911eb4'),
          am4core.color('#46f0f0'),
          am4core.color('#f032e6'),
          am4core.color('#bcf60c'),
          am4core.color('#fabebe'),
          am4core.color('#008080'),
          am4core.color('#e6beff'),
          am4core.color('#9a6324'),
          am4core.color('#fffac8'),
          am4core.color('#800000'),
          am4core.color('#aaffc3'),
          am4core.color('#808000'),
          am4core.color('#ffd8b1'),
          am4core.color('#000075'),
          am4core.color('#808080'),
          am4core.color('#ffffff'),
          am4core.color('#000000'),
        ]
      }

      // This creates initial animation
      // pieSeries.hiddenState.properties.opacity = 1
      pieSeries.hiddenState.properties.endAngle = -90
      pieSeries.hiddenState.properties.startAngle = -90

      pieSeries.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer
      pieSeries.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer

      pieSeries.slices.template.events.on('hit', function (ev: any) {
        const {link} = ev.target.dataItem

        if (link) history.push(link)

        let series = ev.target.dataItem.component
        series.slices.each(function (item: any) {
          if (item.isActive && item != ev.target) {
            item.isActive = false
          }
        })
      })

      pieSeries.labels.template.events.on('hit', function (ev: any) {
        const {link} = ev.target.dataItem

        if (link) history.push(link)

        let series = ev.target.dataItem.component
        series.slices.each(function (item: any) {
          if (item.isActive && item != ev.target) {
            item.isActive = false
          }
        })
      })

      // pieSeries.patterns = new am4core.PatternSet()
    }

    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [])

  // Load data into chart
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = data
    }
  }, [data])

  // Handle component unmounting, dispose chart
  useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [])

  return (
    <div
      id={CHART_ID}
      style={{
        width: '100%',
        height: `${height}px`,
      }}
    />
  )
}

export default AmChart
