import {useEffect, useRef} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import _ from 'lodash'
import {useHistory} from 'react-router-dom'

am4core.useTheme(am4themes_animated)

const BarChart = ({data = [], height = 200, preLabelFormat = '', postLabelFormat = ''}: any) => {
  const chartRef = useRef<any>(null)
  const history = useHistory()

  const CHART_ID = _.uniqueId('bar-')

  useEffect(() => {
    if (!chartRef.current) {
      chartRef.current = am4core.create(CHART_ID, am4charts.XYChart)

      let categoryAxis = chartRef.current.yAxes.push(new am4charts.CategoryAxis())
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.dataFields.category = 'category'
      categoryAxis.renderer.minGridDistance = 1
      categoryAxis.renderer.inversed = true
      categoryAxis.renderer.grid.template.disabled = true

      let valueAxis = chartRef.current.xAxes.push(new am4charts.ValueAxis())
      valueAxis.min = 0
      //valueAxis.max = 100

      let series = chartRef.current.series.push(new am4charts.ColumnSeries())
      series.dataFields.categoryY = 'category'
      series.dataFields.valueX = 'value'
      series.dataFields.link = 'link'
      series.tooltipText = '{valueX.value}'
      series.columns.template.strokeOpacity = 0
      series.columns.template.column.cornerRadiusBottomRight = 5
      series.columns.template.column.cornerRadiusTopRight = 5

      let labelBullet = series.bullets.push(new am4charts.LabelBullet())
      labelBullet.label.horizontalCenter = 'left'
      labelBullet.label.dx = 10
      labelBullet.label.text = `${preLabelFormat}{values.valueX.workingValue.formatNumber('#.0as')}${postLabelFormat}`
      labelBullet.locationX = 1

      series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer
      // series.dataFields.template.cursorOverStyle = am4core.MouseCursorStyle.pointer

      // Add events
      categoryAxis.renderer.labels.template.events.on('hit', function (ev: any) {
        const {link} = ev.target.dataItem

        if (link) history.push(link)
      })
      categoryAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer

      // as by default columns of the same series are of the same color, we add adapter which takes colors from chartRef.current.colors color set
      series.columns.template.adapter.add('fill', function (fill: any, target: any) {
        return chartRef.current.colors.getIndex(target.dataItem.index)
      })

      series.columns.template.events.on('hit', function (ev: any) {
        const {link} = ev.target.dataItem

        if (link) history.push(link)
      })

      categoryAxis.sortBySeries = series
      chartRef.current.data = data
    }

    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [])

  // Load data into chart
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = data
    }
  }, [data])

  // Handle component unmounting, dispose chart
  useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [])

  return (
    <div
      id={CHART_ID}
      style={{
        width: '100%',
        height: `${height}px`,
      }}
    />
  )
}

export default BarChart
