import {any} from '@amcharts/amcharts4/.internal/core/utils/Array'
import {useContext} from 'react'
import {DashboardContext} from '../../../context/DashboardContext'
import PieChart from '../../shared/PieChart'
import BarChart from '../../shared/BarChart'
import GroupBarChart from '../../shared/GroupBarChart'
import {getAnswerByQuestion, getAnswerByQuestionTag, getRiskColor} from '../../shared/Helpers'
import VerticalBarChart from '../../shared/VerticalBarChart'
import {CompanyContext} from '../../../context/CompanyContext'
import AmChart from '../../shared/AmChart'
import {useParams} from 'react-router-dom'
import SaveToImageButton from '../../shared/SaveToImageButton'

const RiskLevels = () => {
  const {impactList} = useContext(DashboardContext)
  const {company = {}} = useContext(CompanyContext)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  const getData = () => {
    const data = impactList.reduce((acc: any, impact: any) => {
      impact.risks.forEach((risk: any) => {
        acc[risk.level] = (acc[risk.level] || 0) + 1
      })

      return acc
    }, {})

    return Object.keys(data).reduce(
      (acc: any, type: any) => [
        ...acc,
        {
          category: type,
          value: data[type],
          color: getRiskColor(type),
          link: `${prefix}/dashboard/risk-levels/${type}`,
        },
      ],
      []
    )
  }

  return (
    <div className='card card-custom mb-7' id='risk-levels-chart'>
      <SaveToImageButton node='risk-levels-chart' />

      <div className='card-body'>
        <h3>Risk Levels Analysis</h3>
        <p className='fs-7 text-gray-600 mb-10'>
          This graph shows an aggregated distribution of your enterprise's risk assessments based on
          the levels of risk for all risk types related to its impact focus.
        </p>
        <AmChart data={getData()} labelTextType='circular' useDataColor={true} />
      </div>
    </div>
  )
}

export default RiskLevels
