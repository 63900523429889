/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {StepperComponent} from '../../../assets/ts/components'

interface ICreateAccount {
  description: string
  category: string
  framework: string
  dbName: string
  dbType: string
  nameOnCard: string
  cardNumber: string
  cardExpiryMonth: string
  cardExpiryYear: string
  cardCvv: string
  saveCard: string
}

const inits: ICreateAccount = {
  description: '',
  category: '1',
  framework: '1',
  dbName: '',
  dbType: '1',
  nameOnCard: '',
  cardNumber: '4111 1111 1111 1111',
  cardExpiryMonth: '1',
  cardExpiryYear: '2',
  cardCvv: '123',
  saveCard: '1',
}

const createAppSchema = [
  Yup.object({
    description: Yup.string().required().label('App name'),
    category: Yup.string().required().label('Category'),
  }),
  Yup.object({
    framework: Yup.string().required().label('Framework'),
  }),
  Yup.object({
    dbName: Yup.string().required().label('Database name'),
    dbType: Yup.string().required().label('Database engine'),
  }),
  Yup.object({
    nameOnCard: Yup.string().required().label('Name'),
    cardNumber: Yup.string().required().label('Card Number'),
    cardExpiryMonth: Yup.string().required().label('Expiration Month'),
    cardExpiryYear: Yup.string().required().label('Expiration Year'),
    cardCvv: Yup.string().required().label('CVV'),
  }),
]

const ImpactMeasurement: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0])
  const [initValues] = useState<ICreateAccount>(inits)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    window.scrollTo(0, 0)

    if (!stepper.current) {
      return
    }

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex])

    stepper.current.goNext()
    // if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
    //   stepper.current.goNext()
    // } else {
    //   stepper.current.goto(1)
    //   actions.resetForm()
    // }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='modal fade' id='kt_modal_impact_measurement' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-900px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Impact Measurement</h2>

            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            <div
              ref={stepperRef}
              className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
              id='kt_modal_impact_measurement_stepper'
            >
              <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
                <div className='stepper-nav ps-lg-10'>
                  <div className='stepper-item current' data-kt-stepper-element='nav'>
                    <div className='stepper-line w-40px'></div>

                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Baseline</h3>

                      <div className='stepper-desc'>Define baseline metrics</div>
                    </div>
                  </div>

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-line w-40px'></div>

                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Progress</h3>

                      <div className='stepper-desc'>Communication on Progress</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex-row-fluid py-lg-5 px-lg-15'>
                <Formik
                  // validationSchema={currentSchema}
                  initialValues={initValues}
                  onSubmit={submitStep}
                >
                  {() => (
                    <Form className='form' noValidate id='kt_modal_impact_measurement_form'>
                      <div className='current' data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='pb-10 pb-lg-10'>
                            <h2 className='fw-bolder text-dark'>Baseline</h2>

                            <div className='text-gray-400 fw-bold fs-6'>
                              If you need more info, please check out &nbsp;
                              <a href='#' className='text-primary fw-bolder'>
                                Help Page
                              </a>
                              .
                            </div>
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                Which impact assessment metric would you like to report on?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                What is the target (expected quantitaive value) of the impact
                                assessment metric to attain the goal?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='att'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                Describe the level of the outcome of the impact assessment metric
                                before the intervention (your organition's investment in the
                                strategic goal)?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                What is the baseline (current quantitaive value) of the impact
                                assessment metric before the intervention (your organition's
                                investment in the strategic goal)?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='att'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                How did you assess the baseline of the impact contribution of your
                                operations?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>
                        </div>
                      </div>

                      <div data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='pb-10 pb-lg-10'>
                            <h2 className='fw-bolder text-dark'>Communication on Progress </h2>

                            <div className='text-gray-400 fw-bold fs-6'>
                              If you need more info, please check out &nbsp;
                              <a href='#' className='text-primary fw-bolder'>
                                Help Page
                              </a>
                              .
                            </div>
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                How did you assess the impact contribution of your operations
                                relative to what would have prevailed anyway (without your
                                investment to allign to the goal)?
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>Describe the outcome of your impact contribution to date</span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='att'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>
                                Prompt the user of their pre-selected reporting frequency of the
                                impact assessment metric:
                              </span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>What is the start date of this assessment?</span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>

                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>What is the end date of this assessment?</span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              placeholder=''
                              name='fund'
                            />
                          </div>
                        </div>
                      </div>

                      <div className='d-flex flex-stack pt-10'>
                        <div className='me-2'>
                          <button
                            onClick={prevStep}
                            type='button'
                            className='btn btn-lg btn-light-primary me-3'
                            data-kt-stepper-action='previous'
                          >
                            <KTSVG
                              path='/media/icons/duotone/Navigation/Left-2.svg'
                              className='svg-icon-4 me-1'
                            />
                            Back
                          </button>
                        </div>

                        <div>
                          <button type='submit' className='btn btn-lg btn-primary me-3'>
                            <span className='indicator-label'>
                              {stepper.current?.currentStepIndex !==
                                stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                              {stepper.current?.currentStepIndex ===
                                stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                              <KTSVG
                                path='/media/icons/duotone/Navigation/Right-2.svg'
                                className='svg-icon-3 ms-2 me-0'
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ImpactMeasurement}
