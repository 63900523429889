// import moment from 'moment'
import React, {useContext, useEffect, useRef, useState} from 'react'
// import {Link} from 'react-router-dom'
// import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
import ReactToPrint from 'react-to-print'
import PrintComponent from '../../shared/PrintComponent'
import VerticalBarChart from '../../shared/VerticalBarChart'
// import {access} from 'fs'
import {sdgs} from '../../shared/Constants'
import PieChart from '../../shared/PieChart'
import ReportHeader from './ReportHeader'
import {AuthContext} from '../../../context/AuthContext'
import {PortfolioContext} from '../../../context/PortfolioContext'

const ImpactInvestmentReport = () => {
  const componentRef = useRef<any>()
  const {impactList = []} = useContext(DashboardContext)

  const {company = {}} = useContext(CompanyContext)
  const {user, isAdmin} = useContext(AuthContext)
  const {portfolios = []} = useContext(PortfolioContext)

  const [selectedCompany, setSelectedCompany] = useState<any>()
  const [selectedPortfolio, setSelectedPortfolio] = useState<any>()

  const [state, setState] = useState({
    reportType: 'my-company',
    portfolio: '',
    company: '',
  })

  const handleChange = (evt: any) => {
    const value = evt.target.value
    setState({
      ...state,
      [evt.target.name]: value,
    })
  }

  const getSelectedCompany = () => {
    if (state.reportType !== 'investee') return company
    const [portfolio_id, company_id] = (state?.company || '').split('-')
    const {companies = []} =
      portfolios.find((portfolio: any) => portfolio.group_identifier === portfolio_id) || {}

    return companies.find((c: any) => c.group_identifier === company_id)
  }

  const getSelectedPortfolio = () => {
    if (state.reportType !== 'portfolio') return
    return portfolios.find((portfolio: any) => portfolio.group_identifier === state?.portfolio)
  }

  const showReport = () => {
    if (state.reportType === 'portfolio' && !selectedPortfolio) return false
    if (!selectedCompany) return false

    return true
  }

  useEffect(() => {
    setSelectedCompany(getSelectedCompany())
    setSelectedPortfolio(getSelectedPortfolio())
  }, [state, selectedPortfolio])

  const getFundingData = () => {
    if (state.reportType === 'my-company') return getMyCompanyFunding()
    if (state.reportType === 'portfolio') return getPortfolioFunding()
    if (state.reportType === 'investee') return getInvesteeFunding()
    if (state.reportType === 'cummulative') return getCummulativeFunding()
    return []
  }

  const getLocationData = () => {
    if (state.reportType === 'my-company') return getMyCompanyLocationFunding()
    if (state.reportType === 'portfolio') return getPortfolioLocationFunding()
    if (state.reportType === 'investee') return getInvesteeLocationFunding()
    if (state.reportType === 'cummulative') return getCummulativeLocationFunding()
    return []
  }

  const getSDGData = () => {
    if (state.reportType === 'my-company') return getMyCompanySDGFunding()
    if (state.reportType === 'portfolio') return getPortfolioSDGFunding()
    if (state.reportType === 'investee') return getInvesteeSDGFunding()
    if (state.reportType === 'cummulative') return getCummulativeSDGFunding()

    return []
  }

  const getMyCompanyLocationFunding = () => {
    const dataMap = impactList.reduce((acc: any, impact: any) => {
      impact.location.forEach((loc: any) => {
        acc[loc] = acc[loc] || 0 + impact.funding
      })

      return acc
    }, {})

    // impactList.reduce((acc: any, impact: any) => {
    //   acc[impact.location] = (acc[impact.location] || 0) + impact.funding
    //   return acc
    // }, {})

    return Object.keys(dataMap)
      .reduce((acc: any, category: any) => [...acc, {category, value: dataMap[category]}], [])
      .filter((s: any) => s.value)
  }

  const getInvesteeLocationFunding = () => {
    const dataMap = (selectedCompany.impacts || []).reduce((acc: any, impact: any) => {
      acc[impact.location] = (acc[impact.location] || 0) + impact.funding
      return acc
    }, {})

    return Object.keys(dataMap)
      .reduce((acc: any, category: any) => [...acc, {category, value: dataMap[category]}], [])
      .filter((s: any) => s.value)
  }

  const getCummulativeLocationFunding = () => {
    const dataMap = portfolios.reduce((acc: any, portfolio: any) => {
      portfolio.companies.forEach((company: any) => {
        company.impacts.forEach((impact: any) => {
          acc[impact.location] = (acc[impact.location] || 0) + company.portfolioFunding
        })
      })

      return acc
    }, {})

    return Object.keys(dataMap)
      .reduce((acc: any, category: any) => [...acc, {category, value: dataMap[category]}], [])
      .filter((s: any) => s.value)
  }

  const getPortfolioLocationFunding = () => {
    const dataMap = (selectedPortfolio.companies || []).reduce((acc: any, company: any) => {
      company.impacts.forEach((impact: any) => {
        acc[impact.location] = (acc[impact.location] || 0) + company.portfolioFunding
      })

      return acc
    }, {})

    return Object.keys(dataMap)
      .reduce((acc: any, category: any) => [...acc, {category, value: dataMap[category]}], [])
      .filter((s: any) => s.value)
  }

  const getMyCompanyFunding = () =>
    impactList.map(({name, category, funding}: any) => ({
      category: `${name}`,
      value: funding,
    }))

  const getInvesteeFunding = () =>
    (selectedCompany.impacts || []).map(({name, category, funding}: any) => ({
      category: `${name}`,
      value: funding,
    }))

  const getPortfolioFunding = () =>
    (selectedPortfolio.companies || []).reduce((acc: any, company: any) => {
      acc.push({
        category: company?.name,
        value: company.portfolioFunding,
      })

      return acc
    }, [])

  const getCummulativeFunding = () =>
    portfolios.reduce((acc: any, portfolio: any) => {
      portfolio.companies.forEach((company: any) => {
        acc.push({
          category: company?.name,
          value: company.portfolioFunding,
        })
      })
      return acc
    }, [])

  const getMyCompanySDGFunding = () =>
    sdgs
      .map((sdg: any) => ({
        category: sdg.label,
        color: sdg.color,
        value: impactList.reduce((acc: any, impact: any) => {
          if (impact.sdgs.includes(sdg.value)) {
            acc += impact.funding
          }
          return acc
        }, 0),
      }))
      .filter((s: any) => s.value)

  const getInvesteeSDGFunding = () =>
    sdgs
      .map((sdg: any) => ({
        category: sdg.label,
        color: sdg.color,
        value: (selectedCompany.impacts || []).reduce((acc: any, impact: any) => {
          if (impact.sdgs.includes(sdg.value)) {
            acc += impact.funding
          }
          return acc
        }, 0),
      }))
      .filter((s: any) => s.value)

  const getPortfolioSDGFunding = () =>
    sdgs
      .map((sdg: any) => ({
        category: sdg.label,
        color: sdg.color,
        value: (selectedPortfolio.companies || []).reduce((acc: any, company: any) => {
          company.impacts.forEach((impact: any) => {
            if (impact.sdgs.includes(sdg.value)) {
              acc += company.portfolioFunding
            }
          })
          return acc
        }, 0),
      }))
      .filter((s: any) => s.value)

  const getCummulativeSDGFunding = () =>
    sdgs
      .map((sdg: any) => ({
        category: sdg.label,
        color: sdg.color,
        value: portfolios.reduce((acc: any, portfolio: any) => {
          portfolio.companies.forEach((company: any) => {
            company.impacts.forEach((impact: any) => {
              if (impact.sdgs.includes(sdg.value)) {
                acc += company.portfolioFunding
              }
            })
          })

          return acc
        }, 0),
      }))
      .filter((s: any) => s.value)

  return (
    <div>
      <ReactToPrint
        trigger={() => <button className='btn btn-dark btn-sm mb-5'>Print Report</button>}
        content={() => componentRef.current}
      />
      {user.type === 'Investor' && (
        <div className='card card-custom bg-dark text-white mb-10 py-10 px-10 pb-7'>
          <div className='mb-5'>
            <span className='fw-bolder'>Generate Report</span>
            <select
              className='form-select mt-1 fs-7 cursor-pointer'
              name='reportType'
              value={state.reportType}
              onChange={handleChange}
            >
              <option value='my-company'>My Company ({company.company_name})</option>
              <option value='portfolio'>Per Portfolio</option>
              <option value='investee'>Per Enterprise</option>
              <option value='cummulative'>Cummulative (All Portfolios)</option>
            </select>
          </div>

          {state.reportType === 'portfolio' && (
            <div className='mb-5'>
              <span className='fw-bolder w-150px mb-2'>Select Portfolio</span>
              <select
                className='form-select fs-7 mt-1 cursor-pointer'
                name='portfolio'
                value={state.portfolio}
                onChange={handleChange}
              >
                <option>Select Portfolio</option>
                {portfolios.map((portfolio: any) => (
                  <option value={portfolio.group_identifier}>{portfolio.name}</option>
                ))}
              </select>
            </div>
          )}
          {state.reportType === 'investee' && (
            <div className='mb-5'>
              <span className='fw-bolder w-150px mb-2'>Select Company</span>
              <select
                className='form-select fs-7 mt-1 cursor-pointer'
                name='company'
                value={state.company}
                onChange={handleChange}
              >
                <option>Select Company</option>
                {portfolios.map((portfolio: any) => (
                  <>
                    {!!portfolio.companies.length && (
                      <optgroup label={portfolio.name} className='mt-10'>
                        {portfolio.companies.map((company: any) => (
                          <option
                            value={`${portfolio.group_identifier}-${company.group_identifier}`}
                          >
                            {company.name}
                          </option>
                        ))}
                      </optgroup>
                    )}
                  </>
                ))}
              </select>
            </div>
          )}
        </div>
      )}
      {showReport() && (
        <PrintComponent ref={componentRef}>
          <div className='row g-xl-8'>
            <div className='col-12'>
              <div className='card p-0 overflow-hidden'>
                <div className='card-header m-0 p-0 border-0'>
                  <ReportHeader
                    company={selectedCompany}
                    portfolio={selectedPortfolio}
                    state={state}
                  />
                </div>

                <div className='card-body p-15 pt-13 px-20'>
                  <div className='mb-10'>
                    {/* <div className='text-gray-600 fs-7 fw-bold mb-1'>
                    {moment().format('MMMM Do YYYY')}
                  </div> */}
                    <h2>Impact Investment Report</h2>
                    <p className='fs-6 text-gray-600 mt-3'>
                      This report highlights the overview and trend analysis of your enterprise's
                      impact investment initiatives based on investees' funding allocations and
                      impacts focus.
                    </p>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <VerticalBarChart
                        data={getFundingData()}
                        height={400}
                        numberFormat='$#,###'
                      />
                    </div>

                    <div className='col-12 mt-15'>
                      <h2>Funding by SDGs</h2>
                      <p className='fs-6 text-gray-600 mt-3'>
                        This graph shows a distribution of investments based on your enterprise's
                        impact focus based on their impact metrics and the 17 sustainable
                        development goals (SDGs). All metrics in IMM framework(s) are mapped to the
                        SDGs using the IRIS Catalog of Metrics.
                      </p>
                      <PieChart
                        data={getSDGData()}
                        height={400}
                        labelTextType='aligned'
                        useDataColor={true}
                      />
                    </div>

                    <div className='col-12 mt-15'>
                      <h2>Funding by Location</h2>
                      <p className='fs-6 text-gray-600 mt-3'>
                        This graph shows a distribution of how your enterprise's funding is
                        allocated based on the location of stakeholders who are to benefit from the
                        outcomes.
                      </p>
                      <PieChart data={getLocationData()} height={400} labelTextType='aligned' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PrintComponent>
      )}
    </div>
  )
}

export default ImpactInvestmentReport
