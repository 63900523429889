import {useContext} from 'react'
import {useParams} from 'react-router-dom'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
import AmChart from '../../shared/AmChart'
import SaveToImageButton from '../../shared/SaveToImageButton'

const ExpectedDurationDistribution = () => {
  const {impactList} = useContext(DashboardContext)
  const {company = {}} = useContext(CompanyContext)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  const getData = () => {
    const data = impactList.reduce((acc: any, impact: any) => {
      acc[impact.expectedDuration || 'No data available'] =
        (acc[impact.expectedDuration || 'No data available'] || 0) + 1
      return acc
    }, {})

    return Object.keys(data).reduce(
      (acc: any, type: any) => [
        ...acc,
        {
          category: type,
          value: data[type],
          link: `${prefix}/dashboard/impact-duration/${type}`,
        },
      ],
      []
    )
  }

  return (
    <div className='card card-custom mb-7' id='expected-duration-chart'>
      <SaveToImageButton node='expected-duration-chart' />
      <div className='card-body'>
        <h3>Expected Duration of Impact</h3>
        <p className='fs-7 text-gray-600 mb-10'>
          This graph shows an aggregated distribution of your enterprise's projection of the
          duration where stakeholders will experience the outcome of your impact focus.
        </p>
        <AmChart data={getData()} labelTextType='circular' />
      </div>
    </div>
  )
}

export default ExpectedDurationDistribution
