//import moment from 'moment'
import React, {useContext, useEffect, useRef, useState} from 'react'
//import {Link} from 'react-router-dom'
//import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
import ReactToPrint from 'react-to-print'
import PrintComponent from '../../shared/PrintComponent'
import IMPScaleFull from '../../dashboard/components/IMPScaleFull'
//import {impScaleCategory} from '../../shared/Constants'
//import {getMetricPercentageCompleted} from '../../shared/Helpers'
import {AuthContext} from '../../../context/AuthContext'
import {PortfolioContext} from '../../../context/PortfolioContext'
import ReportHeader from './ReportHeader'
import {getCompanyIMP} from '../../../../api'
import LoadingSpinner from '../../shared/LoadingSpinner'

const ImpAssessmentReport = () => {
  const componentRef = useRef<any>()
  const {impactList = []} = useContext(DashboardContext)
  const {company = {}} = useContext(CompanyContext)
  const {user, isAdmin} = useContext(AuthContext)
  const {portfolios = []} = useContext(PortfolioContext)

  const [selectedCompany, setSelectedCompany] = useState<any>()
  const [selectedPortfolio, setSelectedPortfolio] = useState<any>()

  const [state, setState] = useState({
    reportType: 'my-company',
    portfolio: '',
    company: '',
  })

  const handleChange = (evt: any) => {
    const value = evt.target.value
    setState({
      ...state,
      [evt.target.name]: value,
    })
  }

  const getSelectedCompany = () => {
    if (state.reportType !== 'investee') return company
    const [portfolio_id, company_id] = (state?.company || '').split('-')
    const {companies = []} =
      portfolios.find((portfolio: any) => portfolio.group_identifier === portfolio_id) || {}

    return companies.find((c: any) => c.group_identifier === company_id)
  }

  const getSelectedPortfolio = () => {
    if (state.reportType !== 'portfolio') return
    return portfolios.find((portfolio: any) => portfolio.group_identifier === state?.portfolio)
  }

  const showReport = () => {
    if (state.reportType === 'portfolio' && !selectedPortfolio) return false
    if (!selectedCompany) return false
    return true
  }

  useEffect(() => {
    const group_identifier = getSelectedGroupIdentifer()
    if (group_identifier) getCompanyImpData(group_identifier)

    setSelectedCompany(getSelectedCompany())
    setSelectedPortfolio(getSelectedPortfolio())
  }, [state, selectedPortfolio])

  const [impact, setImpact] = useState(impactList.length && impactList[0])
  const [impPerformance, setImpPerformance] = useState<any>([])

  // useEffect(() => {
  //   const performance = impScaleCategory.map((p: any) => {
  //     const arr = impact.metrics.reduce((acc: any, metric: any) => {
  //       if (metric.tags.includes(p.tag)) acc.push(getMetricPercentageCompleted(metric))
  //       return acc
  //     }, [])

  //     const completed = arr.length ? arr.reduce((a: any, b: any) => a + b, 0) / arr.length : 0
  //     return {...p, completed}
  //   })

  //   setImpPerformance(performance)
  // }, [impact])

  const onChange = (event: any) => {
    setImpact(impactList.find((i: any) => i.group_identifier == event.target.value))
  }

  const [isLoading, setLoading] = useState(true)
  const [impData, setImpData] = useState<any>([])

  const getCompanyImpData = (group_identifier: any) => {
    setLoading(true)
    getCompanyIMP(group_identifier)
      .then(({data = []}) => {
        setImpData(data)
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }

  const getSelectedGroupIdentifer = () => {
    if (state.reportType === 'my-company') return company.group_identifier
    if (state.reportType === 'investee' && state.company !== '') return state.company.split('-')[1]
  }

  return (
    <div>
      {user.type === 'Investor' && (
        <div className='card card-custom bg-dark text-white mb-10 py-10 px-10 pb-7'>
          <div className='mb-5'>
            <span className='fw-bolder'>Generate Report</span>
            <select
              className='form-select mt-1 fs-7 cursor-pointer'
              name='reportType'
              value={state.reportType}
              onChange={handleChange}
            >
              <option value='my-company'>My Company ({company.company_name})</option>
              {/* <option value='portfolio'>Per Portfolio</option> */}
              <option value='investee'>Per Enterprise</option>
              {/* <option value='cummulative'>Cummulative (All Portfolios)</option> */}
            </select>
          </div>

          {state.reportType === 'portfolio' && (
            <div className='mb-5'>
              <span className='fw-bolder w-150px mb-2'>Select Portfolio</span>
              <select
                className='form-select fs-7 mt-1 cursor-pointer'
                name='portfolio'
                value={state.portfolio}
                onChange={handleChange}
              >
                <option>Select Portfolio</option>
                {portfolios.map((portfolio: any) => (
                  <option value={portfolio.group_identifier}>{portfolio.name}</option>
                ))}
              </select>
            </div>
          )}
          {state.reportType === 'investee' && (
            <div className='mb-5'>
              <span className='fw-bolder w-150px mb-2'>Select Company</span>
              <select
                className='form-select fs-7 mt-1 cursor-pointer'
                name='company'
                value={state.company}
                onChange={handleChange}
              >
                <option value={''}>Select Company</option>
                {portfolios.map((portfolio: any) => (
                  <>
                    {!!portfolio.companies.length && (
                      <optgroup label={portfolio.name} className='mt-10'>
                        {portfolio.companies.map((company: any) => (
                          <option
                            value={`${portfolio.group_identifier}-${company.group_identifier}`}
                          >
                            {company.name}
                          </option>
                        ))}
                      </optgroup>
                    )}
                  </>
                ))}
              </select>
            </div>
          )}
        </div>
      )}

      {showReport() && (
        <div className='d-flex mb-5'>
          <div className='flex-grow-1 pt-3'>
            <ReactToPrint
              trigger={() => <button className='btn btn-dark btn-sm'>Print Report</button>}
              content={() => componentRef.current}
            />
          </div>
          {/* <div className='d-flex w-500px'>
            <span className='fw-bolder w-150px pt-4'>Area of Focus:</span>
            <select
              className='form-select fs-7  cursor-pointer'
              aria-label='Select example'
              onChange={onChange}
            >
              {impactList.map((i: any) => (
                <option
                  value={i.group_identifier}
                  selected={i.group_identifier === impact.group_identifier}
                >
                  {i.name} ({i.category})
                </option>
              ))}
            </select>
          </div> */}
        </div>
      )}

      {showReport() && (
        <PrintComponent ref={componentRef}>
          <div className='row g-xl-8'>
            <div className='col-12'>
              <div className='card p-0 overflow-hidden'>
                <div className='card-header m-0 p-0 border-0'>
                  <ReportHeader
                    company={selectedCompany}
                    portfolio={selectedPortfolio}
                    state={state}
                  />
                </div>
                {isLoading && (
                  <div>
                    <LoadingSpinner />
                  </div>
                )}
                {!isLoading && (
                  <div className='card-body p-15 pt-13 px-20'>
                    <div className='row'>
                      <div className='col-6'>
                        <div className='pe-5'>
                          <h3>Description</h3>
                          <div style={{textAlign: 'justify'}}>{company.company_description}</div>
                        </div>
                      </div>
                      <div className='col-6'>
                        <div className='ps-5'>
                          <h3>{impact?.category}</h3>
                          <div style={{textAlign: 'justify'}}>{impact?.description}</div>
                        </div>
                      </div>
                    </div>

                    <div className='row mt-15'>
                      <div className='col-6'>
                        <h3>Impact Management Project Assessment</h3>
                      </div>

                      <div className='col-12'>
                        {impData.map((category: any) => (
                          <IMPScaleFull {...category} />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </PrintComponent>
      )}
    </div>
  )
}

export default ImpAssessmentReport
