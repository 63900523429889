import {useContext, useEffect, useState} from 'react'
import {Link, useParams, useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {CompanyContext} from '../../../context/CompanyContext'
import {DashboardContext} from '../../../context/DashboardContext'
// import {sdgs} from '../../shared/Constants'
import CurrentMetric from '../../shared/CurrentMetric'
// import {getMetricPercentageCompleted} from '../../shared/Helpers'
import ReadMoreDiv from '../../shared/ReadMoreDiv'

const SdgMetricsDetails = () => {
  const {sdg_number} = useParams<any>() || {}

  const {sdgPerformance} = useContext(DashboardContext)
  const {company = {}} = useContext(CompanyContext)
  const sdg = sdgPerformance.find((s: any) => s.value === sdg_number)

  const {group_identifier} = useParams<any>()
  const prefix = group_identifier ? `/company/${group_identifier}` : ''

  return (
    <>
      <PageTitle>{sdg?.label}</PageTitle>
      <div className='d-flex mb-10 p-10' style={{backgroundColor: sdg?.color}}>
        <div className='image me-10'>
          <img
            alt='Logo'
            src={toAbsoluteUrl(`/media/images/${sdg?.value}.png`)}
            className='h-100px w-auto'
            title={`Sustainable Development Goal ${sdg.value}`}
          />
        </div>
        <div className='flex-grow-1 text-white'>
          <h2 className='text-white'>{sdg?.label}</h2>
          <ReadMoreDiv height={100}>
            <div dangerouslySetInnerHTML={{__html: sdg?.description}} />
          </ReadMoreDiv>
        </div>
        <div className='percentage ms-10 text-center text-white'>
          <span className='fw-bolder ' style={{fontSize: 40}}>
            {sdg.completed && sdg.completed != 'NaN' ? sdg.completed : 0}
          </span>
          %<span className='d-block fs-8'>Completed</span>
        </div>
      </div>

      <div className='card card-custom mb-13'>
        <div className='card-body p-0 pt-2'>
          <div className='table-responsive '>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-200 align-middle gs-10 mb-0 pb-0'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-dark'>
                  <th className='pt-3 pb-3 col-5'>Metric Name</th>
                  <th className='pt-3 pb-3 col-2'>Impact Focus</th>
                  <th className='pt-3 pb-3 col-1'>Target</th>
                  <th className='pt-3 pb-3 col-2'>Progress</th>
                  <th className='pt-3 pb-3 col-1'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              {sdg.metrics &&
                sdg.metrics.map((i: any) => (
                  <>
                    <tbody>
                      <tr>
                        <td>
                          <div className='d-flex justify-content-start flex-column py-1'>
                            <Link
                              to={{
                                pathname: `${prefix}/impact-focus/${i?.impact?.group_identifier}/metrics/${i?.metric?.value}`,
                              }}
                              className='fw-bold  fs-9'
                            >
                              <span className='text-gray-700 fw-bolder fs-7 mb-1'>
                                {i.metric?.name}
                              </span>
                            </Link>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex justify-content-start flex-column py-1'>
                            <Link
                              to={{
                                pathname: `${prefix}/impact-focus/${i?.impact?.group_identifier}`,
                              }}
                              className='fw-bold  fs-9'
                            >
                              {i.impact?.name}
                            </Link>
                          </div>
                        </td>
                        <td>
                          <span className='text-gray-500 fw-bolder fs-8'>
                            {i.metric?.baseline
                              ? `${i.metric?.baseline.target} ${i.metric?.baseline.unit}`
                              : '-'}
                          </span>
                        </td>

                        <td>
                          <CurrentMetric
                            color={sdg?.color}
                            target={i?.metric?.baseline?.target || 0}
                            baseline={i?.metric?.baseline?.startPosition || 0}
                            unit={i?.metric?.baseline?.unit}
                            currentPosition={
                              i?.metric?.progress && i.metric?.progress?.length > 0
                                ? i?.metric?.progress[0]?.currentPosition
                                : 0
                            }
                          />
                        </td>

                        <td>
                          {i?.metric?.progress && i.metric?.progress?.length > 0 && (
                            <Link
                              to={{
                                pathname: `${prefix}/impact-focus/${i?.impact?.group_identifier}/metrics/${i?.metric?.value}`,
                              }}
                              className='fw-bold  fs-9'
                            >
                              View History
                            </Link>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </>
                ))}

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default SdgMetricsDetails
