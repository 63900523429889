// import {useContext, useEffect, useState} from 'react'
// import {Link, useLocation, useParams} from 'react-router-dom'
// import {useToasts} from 'react-toast-notifications'
// import {deleteResource, getAnswersByIdentifier} from '../../../../../api'
// import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
// import {PageTitle} from '../../../../../_metronic/layout/core'
// import {QuestionContext} from '../../../../context/QuestionContext'
// import {ProfileHeader} from '../../../company/components/ProfileHeader'
import DataCollectionMethods from '../../../dashboard/components/DataCollectionMethods'
import ExpectedDurationDistribution from '../../../dashboard/components/ExpectedDurationDistribution'
import FundingByImpact from '../../../dashboard/components/FundingByImpact'
import IMPReportSummary from '../../../dashboard/components/IMPReportSummary'
import PeopleEnvironment from '../../../dashboard/components/PeopleEnvironment'
import RiskLevels from '../../../dashboard/components/RiskLevels'
import RiskProfileDistribution from '../../../dashboard/components/RiskProfileDistribution'
import SdgPerformance from '../../../dashboard/components/SdgPerformance'
import Stakeholders from '../../../dashboard/components/Stakeholders'
import {ImpactFocusCharts} from '../../../impact-focus/components/ImpactFocusCharts'
// import {parseJsonString} from '../../../shared/Helpers'
// import LoadingSpinner from '../../../shared/LoadingSpinner'
// import {Section} from '../../../shared/Section'

const CompanyDashboard = () => {
  return (
    <>
      <div className='row'>
        <div className='col-12 mb-10'>
          <ImpactFocusCharts />
        </div>

        <div className='col-6'>
          <SdgPerformance />
          <DataCollectionMethods />
          <Stakeholders />
          <ExpectedDurationDistribution />
        </div>

        <div className='col-6'>
          <FundingByImpact />
          <PeopleEnvironment />
          <IMPReportSummary />
          <RiskProfileDistribution />
          <RiskLevels />
        </div>
      </div>
    </>
  )
}

export default CompanyDashboard
